<template>
  <v-menu bottom min-width="250px" max-height="400px" rounded offset-y>
    <!-- Pictures -->
    <template v-slot:activator="{ on }">
      <div class="d-flex align-center pointer" v-on="on">
        <div>
          <v-img
              max-width="26"
              width="26"
              src="../../assets/profile.svg"
              alt="IMPRIMAX"
              class="pointer mr-1"
              @click="goToRoute('/home')"
              contain
          >
          </v-img>
        </div>

        <div class="">
          <span class="span-avatar hidden-sm-and-down">
              {{ currentUser.name ? `Olá, ${currentUser.name.substring(0, 18)}` : "" }}
          </span>

          <span class="span-avatar hidden-md-and-up">
              {{ currentUser.name ? currentUser.name.substring(0, 10) + "..." : "" }}
          </span>
          <br v-if="currentUser && currentUser.name"/>
          <strong class="text_acessar_conta">
              Acessar conta
          </strong>
        </div>
      </div>
    </template>

    <!-- Card -->
    <v-row
        style="
        background: #ffffff !important;
        box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
      "
    >
      <v-list-item-content class="justify-center align-center">
        <div class="mx-auto text-center">
          <v-avatar class="mt-0">
            <v-icon size="50" color="normalred"> mdi-account-outline</v-icon>
          </v-avatar>

          <h3 class="avatar-text mt-3">{{ currentUser.name }}</h3>
          <p class="avatar-textcaption mt-1">
            {{ currentUser.email }}
          </p>

          <v-divider class="mx-2"></v-divider>

          <div v-if="currentUser.name != null" class="d-flex flex-column">
            <v-btn
                rounded
                text
                class="avatar-text"
                @click="goToRoute('/profile')"
            >
              Perfil
            </v-btn>
          </div>

          <div v-if="currentUser.name == null" class="d-flex flex-column">
            <v-btn
                rounded
                text
                class="avatar-text"
                @click="goToRoute('/login')"
            >
              Login
            </v-btn>
          </div>

          <div v-if="currentUser.name == null" class="d-flex flex-column">
            <v-btn
                rounded
                text
                class="avatar-text"
                @click="goToRoute('/register')"
            >
              Cadastre-se
            </v-btn>
          </div>

          <v-divider class="mx-2"></v-divider>

          <div v-if="currentUser.name != null" class="d-flex flex-column">
            <v-btn rounded text class="avatar-text" @click="logout()">
              <v-icon>mdi-logout</v-icon>
              Sair
            </v-btn>
          </div>
        </div>
      </v-list-item-content>
    </v-row>
  </v-menu>
</template>
<style scoped>
.text_acessar_conta{
  font-size: 1rem !important;
  color: #4F4F4F;
  font-weight: 600;
}
.avatar-text {
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1rem !important;
  line-height: 28px !important;
  letter-spacing: 0.15px !important;
  color: #bc0000 !important;
}

.span-avatar {
  font-size: 0.938rem !important;
  color: #4F4F4F;
  font-weight: 400;
}
@media screen and (max-width: 960px) {
  .span-avatar, .text_acessar_conta {
    display: none;
  }
}
</style>
<script>
export default {
  data: () => ({}),

  props: {
    user: Object,
  },

  methods: {
    async logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("expires");
      localStorage.removeItem("user");
      localStorage.removeItem("cart");
      await this.clearCart();
      await this.goToRoute("/home");

      window.location.reload();
    },
    async clearCart() {
      localStorage.removeItem("cartUser");
      localStorage.setItem("cartBadge", 0);
      this.componentKey += 1;
    },

    async goToRoute(route) {
      if (this.$route.path != route) {
        await this.$router.push(route);
      }
    },
  },

  created() {
    this.currentUser = this.user;
    if (this.currentUser == null) {
      this.currentUser = {};
    }
  },

  computed: {
    // image() {
    //   var env_url = process.env.VUE_APP_BASE_URL;
    //   return env_url + this.currentUser.image;
    // },

    name() {
      return this.currentUser.name.substring(0, 15);
    },
  },
};
</script>
