<template>
  <main>
    <v-dialog
        transition="dialog-bottom-transition"
        v-model="ratingDialog"
        max-width="800px"
        :key="dialogKey"
    >
      <RatingModal
          :currentOrder="orderRating"
          @close="close"
      />
    </v-dialog>

    <v-card class="card ma-0 pa-0 sombra" tile :key="keyId">
      <v-row class="pa-0 ma-0 py-0">
        <v-col
            class="cabeca"
            cols="12"
            md="12"
        >
          <p v-if="list" class="span-order">Pedido:{{ order.orderCode }}</p>
          <div v-else class="span-order">{{ parseDateComplete(order) }}</div>

          <div v-if="showOptionRateOrder">
            <v-col class="ma-0 pa-0 pb-2 px-3" cols="12" sm="12" md="12">
              <div v-if="!order.review" @click="setOrder()" style="text-align: center">
                <strong style="text-decoration: underline;font-size: 16px;" class="ma-0"> Avaliar pedido</strong>
              </div>
            </v-col>
          </div>
        </v-col>
      </v-row>

      <div v-if="dropdown" @click="gotoOrderDetail(order)">
        <v-row cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
               class="pa-3 pb-0 ma-0 py-0 my-0"
               v-for="(item, idw) in order.items"
               :key="idw"
        >
          <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
            <v-img class="img-product" :src="item.product.productPic"></v-img>
          </v-col>

          <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
                 style="display: flex; justify-content: space-between; align-items: center">
            <div class="ma-0 pa-0" style="text-align: start">
              <div>
                <span class="card-title pa-0 ma-0">{{ item.product.productName }}</span>
                <br/>
                <span class="pa-0 ma-0"> Cód: {{ item.product.productCode }}</span>
              </div>

              <v-row v-for="(attribute, attributesId) in item.productSku.attributes" :key="attributesId"
                     class=" text--primary pa-0 ma-0 card-inside">
                <div class="atributos">
                  <p class="span-btnCard">{{ attribute.name }}</p>
                </div>
              </v-row>

              <div class="py-3">
                <span v-if="item.product.calculator" style="color: #bc0000; font-weight: bolder">
                  {{ item.qty }} Metros(s)
                </span>

                <span v-else style="color: #bc0000; font-weight: bolder">
                  Quantidade: {{ item.qty }}
                </span>
              </div>
            </div>

            <div class="d-flex-column pa-0 ma-0 px-0 pt-1"
                 style="display: grid; text-align-last: right; align-content: space-between; height: 100%">
              <p class="card-subprice"> {{ parseDate(order) }}</p>
              <p class="card-price ">{{
                  (item.price * item.qty).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }}</p>
            </div>
          </v-col>
        </v-row>

        <v-row class=""  cols="12" md="12" sm="12" lg="12" v-if="list">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="">
            <h2 class="ml-6 span-order">Total pago</h2>
            <div class="d-flex justify-space-between mx-6 mt-2">
              <span class="order-summary-items">Subtotal</span>
              <span v-if="order.items" class="order-summary-items">{{calculateSubtotal(order).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}}</span>
            </div>
            <v-divider class="mx-6"></v-divider>
            <div class="d-flex justify-space-between mx-6 mt-2">
              <span class="order-summary-items">Desconto</span>
              <span v-if="order.items" class="order-summary-items">{{ order.discount.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}</span>
            </div>
            <v-divider class="mx-6"></v-divider>
            <div class="d-flex justify-space-between mx-6 mt-2">
              <span class="order-summary-items">Frete</span>
              <span v-if="order.items" class="order-summary-items">{{ order.fretePrice.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}</span>
            </div>
            <v-divider class="mx-6"></v-divider>
            <div class="d-flex justify-space-between mx-6 mt-2">
              <span class="order-summary-items">Total</span>
              <span v-if="order.items" class="order-summary-items">{{ (order.totalPrice + order.fretePrice).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}</span>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </main>
</template>

<style scoped>
.cabeca {
  border-start-end-radius: inherit;
  border-start-start-radius: inherit;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
}
.cabeca:hover {
}
.sombra {
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
}

.img-product {
  width: 100% !important;
  height: 120px !important;
  border-radius: 3px !important;
}

.previousOrder {
  display: flex !important;
  max-width: unset !important;
  padding-top: 0px !important;
  text-align: -webkit-center !important;
}

.cabeca {
  border-start-end-radius: inherit;
  border-start-start-radius: inherit;
  background-color: #f5f5f5;
  display: flex;
  padding-top: 20px !important;
  padding-left: 20px !important;
  justify-content: space-between;
}

.span-details {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #2d3038;
}

.span-details:hover {
  font-weight: bold;
  cursor: pointer;
}

.span-order {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  color: #bc0000;
}
.order-summary-items {
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.card {
  border-radius: 8px !important;
}

.card:hover {
  background-color: #ddd;
  cursor: pointer !important;
  transition: 0.2s !important;
}

.excluir {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.15px;

  color: #bc0000;
}

.card-title {
  font-size: 1.4rem;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: 0.15px;
  text-align: start;
  color: #000000;
}

.card-subprice {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;

  color: #000000;
}

.card-price {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 27px;

  letter-spacing: 0.15px;

  color: #000000;
}

.card-inside {
  display: inline-block;
  padding: 10px;
}

.atributos {
  background: #2D3038;
  margin-right: 10px;
  border-radius: 3px;
}

.span-btnCard {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: #dadada;
  padding: 5px;

}
</style>

<script>
import ApiService from "../../../services/ApiService";
import Utils from "../../../Utils/FormatDate";
import RatingModal from "../components/RatingModal.vue";

export default {
  name: "PreviousOrders",

  components: {
    RatingModal,
  },
  props: {
    keyId: Number,
    order: Object,
    list: Boolean,
  },

  data: () => ({
    ratingDialog: false,
    orderRating: {},
    dropdown: true,
    dialogKey: false,
    componentKey: 1,
    cartProducts: [],
    env_url: process.env.VUE_APP_BASE_URL,
    loading: false,
    apiService: new ApiService(),
  }),
  async created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.$route.query.id) {
      this.dropdown = true;
    }
  },
  async mounted(){

  },
  computed: {
    calculatePrice() {
      return this.orders[0].totalprice;
    },
    showOptionRateOrder(){
      return (this.order.orderStatus === 3 || this.order.orderStatus === 4 || this.order.orderStatus === 6 || this.order.orderStatus === 8 || this.order.orderStatus === 10)
    }
  },

  methods: {
    close(){
      this.ratingDialog = false;
      this.$emit('loadOrders')
      this.$emit('loadOrder')
    },
    setOrder(){
      this.orderRating = this.order
      this.ratingDialog = true
    },
    calculateSubtotal(order) {
      return order.items.reduce((total, currentValue) => total + (currentValue.price * currentValue.qty), 0)
    },
    parseDateComplete(order) {
      return Utils.moment(order.created_at);
    },

    parseDate(order) {
      return Utils.momentDate(order.created_at);
    },

    async gotoOrderDetail(order) {
      if (this.$route.path !== "/profile/order") {
        await this.$router.push({
          path: "/profile/order?id=" + order.id,
          params: order.id,
        });
      }
    },

    activeDropdown() {
      this.dropdown = !this.dropdown;
    },
  },
};
</script>
