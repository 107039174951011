<template>
  <v-card class="px-3 ma-0">
    <v-row style="text-align-last: left">
      <v-col class="px-10 pb-0">
        <span class="main-title"
          >Selecione o endereço aonde quer receber sua encomenda
        </span>
      </v-col>
    </v-row>
    <div v-if="addresses.length == 0 ? true : false">
      <v-progress-circular
        v-if="addresses[0] ? true : false"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div class="py-10 my-10">
        <span class="empty-message pa-0 ma-0">Você não tem endereços cadastrados.</span
        ><br />
        <span class="empty-label pa-0 ma-0">Cadastre novos endereços abaixo.</span>
      </div>
    </div>
    <v-row class="px-4">
      <v-col class="py-0 ma-0" :key="componentKey + 1">
        <v-radio-group hide-details v-model="radios" mandatory>
          <v-radio
            v-for="(itemx, i) in addresses"
            active-class="radio-pressed"
            class="radio py-1"
            color="black"
            :value="itemx"
            @change="selectAddress(itemx)"
            :key="i"
          >
            <template v-slot:label>
              <v-row class="d-flex radio-align py-0">
                <v-col class="py-0" cols="12" sm="6">
                  <div>
                    <strong class="secondary--text">{{
                      itemx.addressNickName.toUpperCase()
                    }}</strong>
                  </div>
                  <div>
                    {{
                      itemx.addressName +
                      ", nº:" +
                      itemx.addressNumber +
                      ", " +
                      itemx.neighborhood +
                      " - " +
                      itemx.city +
                      " - " +
                      itemx.state +
                      " - " +
                      itemx.country +
                      " - CEP: " +
                      itemx.postalCode
                    }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row
      v-if="addresses.length != 0 ? true : false"
      class="pa-0 ma-0 py-1"
      style="text-align-last: right"
    >
      <v-col class="pa-0 ma-0">
        <v-btn width="190px" class="primary-button py-0" @click="selectFinish()">
          Selecionar endereço
        </v-btn>
      </v-col>
      <hr />
    </v-row>

    <v-row class="px-7 py-0 ma-0" style="place-item: center; justify-content: right">
      <v-col class="py-0 ma-0" style="text-align: left" cols="12" md="12" sm="12">
        <span class="main-title"> Cadastrar novo endereço </span>
      </v-col>
    </v-row>

    <v-row class="px-7 py-0" style="place-item: center; justify-content: right">
      <v-col class="pa-1 ma-0" style="text-align: left" cols="12" md="12" sm="12">
        <a
          href="https://buscacepinter.correios.com.br/app/endereco/index.php"
          target="_blank"
          class="pa-1"
          style="align-self: center; color: #bc0000"
          ><b><u> Não sei meu CEP</u></b></a
        ></v-col
      >
    </v-row>

    <v-row class="px-7 py-0" style="place-item: center; justify-content: right">
      <v-col class="d-flex" style="align-items: center" cols="6" md="6" sm="6">
        <v-text-field
          type="text"
          class="inputCep primary-input-b2c cepLandscape py-0"
          outlined
          dense
          placeholder="00000-000"
          v-mask="'#####-###'"
          v-model="postalCode"
          hide-details
          required
          v-on:keyup.enter="newAddressCEP()"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-btn class="secondary-button px-2 mx-2 py-0" @click="newAddressCEP()"
          >Adicionar</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ApiService from "../../../services/ApiService";

import Vue from "vue";

export default {
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  components: {},

  data: () => ({
    itemx: false,

    selected: {},
    addresses: [],
    postalCode: "",
    currentClient: {},
    isMobile: false,
    radios: null,
    componentKey: 1,
    endereco: "",
    cart: [],
    user: [],
    frete: [],
    valor: "",
    isMobile: false,
    badge: "",
    clearDialog: false,
    dialogKey: false,
    loading: false,

    apiService: new ApiService(),

    rules: {
      postalCodeRules: (v) =>
        (v && v.length <= 10) || "CEP deve ter no máximo 10 caracteres",
      required: (value) => !!value || "Campo requerido.",
    },
  }),
  computed: {
    TotalPrice() {
      let result = 0;
      this.cart.forEach((e) => {
        result = result + e.price * e.qty;
      });
      if (this.valor) {
        return parseFloat(result + parseFloat(this.valor));
      }

      return parseFloat(result);
    },
  },
  watch: {
    cart() {
      this.$root.$on("changeCart", (data) => {
        this.cart = data;
        this.componentKey++;
      });
    },
    badge() {
      this.$root.$on("changeBadge", (data) => {
        this.badge = data;
      });
    },
  },

  async created() {
    this.cart = JSON.parse(localStorage.getItem("cartUser") || "[]");
    this.badge = JSON.parse(localStorage.getItem("cartBadge"));
    this.user = JSON.parse(localStorage.getItem("user"));

    this.getAddresses(this.user);
  },
  methods: {
    selectAddress(itemx) {
      this.selected = itemx;
    },

    async selectFinish() {
      localStorage.setItem("deliveryAddress", JSON.stringify(this.selected));
      this.$emit("close");
      window.location.reload();
    },

    async getAddresses(user) {
      this.loading = true;
      if (user) {
        await this.apiService
          .get(`client/get-addresses?id=` + user.id)
          .then((response) => {
            this.addresses = response;
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Não foi possível encontrar o endereço cadastrado",
              message: err.body.message,
              trace: null,
            });
          });
      } else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Sem usuário ou id",
          message: err.body.message,
          trace: null,
        });
      }

      this.loading = false;
    },

    async newAddressCEP() {
      await this.$router.push({
        path: "/address?cep=" + this.postalCode,
        params: this.postalCode,
      });
    },

    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
  },
};
</script>

<style scoped>
.radio {
  border-bottom: ridge;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #979797;
}

.radio:hover {
  background-color: #ddd;
}
.radio-pressed {
  background-color: #ddd;
  /* border-style: dashed; */
}

.radio-align {
  left: 0px !important;
  right: auto !important;
  position: relative !important;
  flex-flow: column !important;
  align-items: start !important;
}

.price-frete {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: #979797;
}
.frete {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: #979797;
}
.frete:hover {
  cursor: pointer;
}
.span-red {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: #bc0000;
}
.span-red:hover {
  cursor: pointer;
}
.empty-message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 3.2rem;

  color: #97999d;
}
.empty-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;

  color: #97999d;
}

.main-title {
  font-size: 1.1rem;
}

.card-cart {
  width: 100%;
  height: fit-content !important;
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
  border-radius: 6px;
}
@media (min-width: 600px) {
  .card-cart {
    width: 70%;
    background: #ffffff;
    box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
    border-radius: 6px;
  }
}

.v-card__text {
  padding: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
}
.containerList {
  justify-content: center !important;
  flex-direction: column;
}
</style>
