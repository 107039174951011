<template>
  <v-card
    class="imageCard d-flex flex-column justify-space-around rounded-xl pa-4"
    width="100%"
    min-height="240"
    height="min-content"
    @click.prevent="action()"
  >
    <div class="card-header align-center">
      <v-img
        :src="item.image != null && item.image != '' ? item.image : require('../../../assets/imprimax_logo.png')"
        height="120"
        contain
        alt="imagem de aplicador"
        class="image"
      ></v-img>

      <div class="px-2" style="width: 100%">
        <h3 class="title-content">{{ item.name }}</h3>
        <div
          class="text-content d-flex flex-wrap justify-space-between"
          style="width: 80%"
          @click.stop
        >
          <div>
            <v-btn
              text
              style="text-decoration: none; padding: 0px !important"
              v-if="item.phoneNumber"
              height="30"
              class="ma-0 pa-0 text-content d-flex"
              block
            >
              <div class="d-flex" style="width: 100%">
                <v-icon>mdi-map-marker-outline</v-icon>
                {{ item.city }} - {{ item.state }}
              </div>
            </v-btn>
            <v-btn
              text
              style="text-decoration: none; padding: 0px !important"
              v-if="item.phoneNumber"
              class="ma-0 pa-0 text-content d-flex"
              height="30"
              block
            >
              <div class="d-flex" style="width: 100%">
                <v-icon>mdi-phone-outline</v-icon> {{ item.phoneNumber }}
              </div>
            </v-btn>
            <v-btn
              text
              style="text-decoration: none; padding: 0px !important"
              v-if="item.phoneNumber2"
              height="30"
              class="ma-0 pa-0 text-content d-flex"
              block
            >
              <div class="d-flex" style="width: 100%">
                <v-icon>mdi-phone-outline</v-icon> {{ item.phoneNumber2 }}
              </div>
            </v-btn>
          </div>
          <div>
            <v-btn
              text
              style="text-decoration: none; padding: 0px !important"
              v-if="item.whatsApp"
              height="30"
              :href="`https://api.whatsapp.com/send?phone=${item.whatsApp}&text=Olá`"
              target="_blank"
              class="ma-0 pa-0 text-content d-flex"
              block
            >
              <div class="d-flex" style="width: 100%">
                <v-icon>mdi-whatsapp</v-icon> {{ item.whatsApp }}
              </div>
            </v-btn>
            <v-btn
              text
              style="text-decoration: none; padding: 0px !important"
              v-if="item.instagram"
              :href="`https://instagram.com/${item.instagram.replace('@', '')}`"
              height="30"
              target="_blank"
              class="ma-0 pa-0 text-content d-flex"
              block
            >
              <div class="d-flex" style="width: 100%">
                <v-icon class="mr-1">mdi-instagram</v-icon>
                {{ item.instagram }}
              </div>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-end" @click.stop>
      <v-btn
        class="mt-2"
        @click="show = !show"
        small
        text
        style="text-transform: none"
      >
        {{ show ? "Ler menos" : "Ler mais" }}
      </v-btn>
    </div>

    <v-expand-transition>
      <div class="d-flex flex-wrap mt-2" v-if="show">
        <v-chip
          color="rgba(188, 0, 0, 0.1)"
          style="margin: 3px"
          v-for="specialty in item.specialties"
          :key="specialty.id"
        >
          <span class="chip-text">
            {{ specialty.specialties.name }}
          </span>
        </v-chip>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<style scoped>
.card-header {
  display: flex;
}

.chip-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 147%;
  /* identical to box height, or 18px */

  letter-spacing: 0.15px;

  color: rgba(188, 0, 0, 0.9);
}
.title-content {
  word-wrap: break-word;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 122.5%;
  /* or 20px */

  letter-spacing: 0.15px;

  color: #000000;
}
.text-content {
  word-wrap: break-word;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.15px;

  color: #7d7d7d;
}
.image {
  border-radius: 10px;
  object-fit: cover;
  max-width: 120px;
  max-height: 120px;
}

@media (max-width: 800px) {
  .image {
    max-width: 100%;
  }
  .card-header {
    display: block;
  }
}

.imageCard {
  box-shadow: 0px 2px 4px rgba(14, 31, 53, 0.06),
    0px 6px 12px rgba(14, 31, 53, 0.08), 0px 12px 20px rgba(14, 31, 53, 0.06) !important;
}
</style>

<script>
import ApiService from "../../../services/ApiService";

export default {
  name: "ImageCardInstaller",

  props: {
    item: Object,
    textMessage: String,
    cancelBtn: String,
    okbtn: String,
    env_url: String,
  },

  data: () => ({
    show: false,
    hoverImage: false,
    loading: false,
    apiService: new ApiService(),
  }),

  methods: {
    descriptionSku(sku) {
      if (!sku.attributes) {
        return ``;
      }
      const atributoName = sku.attributes.find(
        (x) => x.attribute.type === "Texto"
      );
      if (atributoName) {
        return `${atributoName.name} - `;
      }
      const atributoCor = sku.attributes.find(
        (x) => x.attribute.type === "Cor"
      );
      if (atributoCor) {
        return `${atributoCor.name} - `;
      }
      return "";
    },
    close() {
      this.$emit("close");
    },

    action() {
      this.$emit("action");
    },
  },
};
</script>
