<template>
  <div class="my-3 mb-6" v-if="description">
    <p
        v-html="description"
        v-if="description.toString().length <= 400"
    ></p>

    <section v-else>
      <p v-html="readMore ? description : description.slice(0, 400)"></p>

      <a class="btn-readMore" @click="readMore = !readMore">
        {{
          readMore ? "(-) Ver menos... " : "(+) Ver mais..."
        }}
      </a>
    </section>
  </div>
</template>

<style scoped>
.active {
  color: #BC0000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.inactive {
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
</style>

<script>

export default {
  name: "Description",

  props: {
    description: {default: null, type: String, required: false},
  },

  data: () => ({
    readMore: false
  }),
  components: {},
  async created() {
  },

  computed: {},

  methods: {},
};
</script>

<style scoped>
.btn-readMore{
  color: #bc0000!important;
  font-weight: 500;
  font-size: 16px;
}
</style>