<template>
  <div>
    <!-- NEW specialties  -->
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="newSpecialtyDialog"
      max-width="500px"
    >
      <NewSpecialties
        :specialties="{}"
        v-if="newSpecialtyDialog"
        @action="newSpecialtyDialog = true"
        @close="(newSpecialtyDialog = false), listSpecialties()"
        @closeNoLoading="closeNoLoading"
      />
    </v-dialog>

    <v-file-input
      id="imageInput"
      accept="image/png, image/jpeg,image/jpg"
      style="display: none"
      @change="setImage"
    ></v-file-input>

    <v-container style="max-width: unset" class="d-flex-column pa-6 pt-2">
      <v-card class="mx-auto" max-width="980px">
        <v-row cols="12">
          <v-col cols="3" >
            <v-img
                src="../../../assets/imprimaxTex.jpg"
                max-width="95%"
                max-height="95%"
                contain
                class="px-5"
            >

            </v-img>
          </v-col>
          <v-col cols="9">
            <h2 style="color: #bc0000">Seja um Aplicador Parceiro Imprimax</h2><br/>

            <strong>
              Um aplicador parceiro Imprimax tem como vantagens:
            </strong> <br/>
            <span>
              Possibilidade de ter um aumento de renda, através de indicação no marketplace Imprimax.
              Reconhecimento e visibilidade entre os principais profissionais do mercado.
              Participação nos principais eventos realizados pela Imprimax.
            </span><br/><br/>

            <p>
              É muito importante saber que para ser indicado como um Aplicador Parceiro Imprimax na Loja Online, será necessário passar por algumas etapas de treinamentos e
              avaliação rigorosa com o centro técnico <strong>ImprimaxTec</strong>. Dúvidas entre em contato através do e-mail marketplace@imprimax.com.br</p>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mx-auto" max-width="980px">
        <div style="width: 100%">
          <v-card-title class="card-title">
            <v-icon
              class="d-flex-row justify-center pl-2 pt-3"
              large
              color="normalred"
            >
              mdi-clipboard-outline
            </v-icon>
            <span style="color: #bc0000"> Cadastro de Aplicadores </span>
          </v-card-title>

          <v-card-text class="card-text pl-7"
            >Cadastre os dados do aplicador neste formulário.
          </v-card-text>
        </div>
      </v-card>

      <!--Informação do Aplicador -->

      <v-card v-if="true" class="mx-auto" max-width="980px">
        <v-icon
          class="d-flex-row justify-center pl-10 pt-3"
          large
          color="normalred"
          >mdi-account-outline</v-icon
        >
        <span class="text-span" style="v-cardAdd"
          >Informações do Aplicador</span
        >

        <span class="pl-12 pb-2" style="display: block">
          Informações pessoais para cadastro.</span
        >

        <v-form
          class="form"
          :key="clear"
          ref="firstForm"
          v-model="firstValid"
          lazy-validation
        >
          <v-row
            cols="12"
            class="d-flex flex-column align-center justify-center"
          >
            <div class="d-flex flex-column align-center justify-center">
              <v-hover v-slot="{ hover }" v-if="currentInstaller.image">
                <v-img
                  :src="currentInstaller.image"
                  style="
                    border-radius: 5px !important;
                    opacity: 0.8;
                    cursor: pointer;
                  "
                  max-height="166"
                  max-width="221"
                  :class="hover ? 'elevation-5 mx-2' : 'mx-2'"
                >
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex flex-column justify-center align-center"
                      style="height: 100%; background: rgba(188, 0, 0, 0.6)"
                      @click="openPicker"
                    >
                      <v-icon size="26" color="white">mdi-pen</v-icon>
                      <span class="edit-photo">Alterar foto</span>
                    </div>
                  </v-expand-transition>
                </v-img>
              </v-hover>
              <span
                class="delete-photo"
                v-if="currentInstaller.image"
                @click="currentInstaller.image"
              >
                Excluir
              </span>

              <div
                class="d-flex flex-column align-center"
                style="max-width: 150px"
                v-if="!currentInstaller.image"
              >
                <v-hover v-slot="{ hover }">
                  <div class="image-container mx-2" @click="openPicker">
                    <v-img
                      src="../../../assets/imp.svg"
                      contain
                      v-if="!hover"
                      max-width="157"
                    ></v-img>

                    <div class="d-flex flex-column align-center">
                      <v-img
                        src="../../../assets/add_photo.svg"
                        contain
                        max-width="46"
                      ></v-img>
                      <span class="photo-field" v-if="hover"
                        >Adicionar Foto</span
                      >
                    </div>
                  </div>
                </v-hover>
              </div>
            </div>
          </v-row>
          <v-row class="pa-6 pb-0 ma-0">
            <v-col cols="12" sm="6" md="6" lg="4">
              <v-text-field
                clearable
                outlined
                maxlength="45"
                v-model="currentInstaller.name"
                :rules="[rules.nameRules]"
                :label="'Nome'"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <v-text-field
                clearable
                outlined
                maxlength="45"
                :rules="[rules.nameRules]"
                v-model="currentInstaller.nameFantasy"
                :label="'Nome Fantasia'"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <v-text-field
                clearable
                outlined
                hide-details
                v-model="currentInstaller.cnpj"
                :rules="[rules.required]"
                v-mask="
                  this.isEditing
                    ? null
                    : currentInstaller.cnpj.length <= 14
                    ? '###.###.###-##'
                    : '##.###.###/####-##'
                "
                :label="'CNPJ/CPF'"
                rounded
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <v-text-field
                outlined
                hide-details
                :rules="[rules.required]"
                v-model="currentInstaller.phoneNumber"
                v-mask="
                  this.isEditing
                    ? null
                    : currentInstaller.phoneNumber.length <= 14
                    ? '(##) ####-####'
                    : '(##) #####-####'
                "
                label="Telefone"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <v-text-field
                outlined
                hide-details
                v-model="currentInstaller.phoneNumber2"
                v-mask="
                  this.isEditing
                    ? null
                    : currentInstaller.phoneNumber2.length <= 14
                    ? '(##) ####-####'
                    : '(##) #####-####'
                "
                label="Celular (Opcional)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <v-text-field
                clearable
                outlined
                maxlength="45"
                v-mask="'(##)#####-####'"
                v-model="currentInstaller.whatsApp"
                :rules="[(v) => !!v || 'Digite o WhatsApp']"
                :label="'WhatsApp'"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <v-text-field
                clearable
                outlined
                maxlength="45"
                v-model="currentInstaller.instagram"
                :rules="[(v) => !!v || 'Digite o nome de usuário no instagram']"
                :label="'Instagram'"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="flex-end pa-6 pb-3 pt-1 ma-0">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-textarea
                outlined
                hide-details
                label="Observações"
                v-model="currentInstaller.textObservation"
                rows="1"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <!--Endereço -->
      <v-card v-if="true" class="mx-auto" max-width="980px">
        <v-icon
          class="d-flex-row justify-center pl-10 pt-3"
          large
          color="normalred"
          >mdi-google-maps
        </v-icon>
        <span class="text-span" style="mx-auto">Endereço</span>

        <span class="pl-12 pb-2" style="display: block">
          Endereço do Aplicador.</span
        >

        <v-form
          class="form"
          :key="clear"
          ref="secondForm"
          v-model="secondValid"
          lazy-validation
        >
          <v-row class="pa-6 pb-0 ma-0">
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                outlined
                label="CEP"
                :rules="[rules.postalCodeRules]"
                v-model="currentInstaller.postalCode"
                v-on:keyup="searchCEP"
                v-mask="this.isEditing ? cepMask : '#####-###'"
                class="inputCep"
              ></v-text-field>
              <p
                v-show="naoLocalizado"
                style="display: none; background-color: #f1948a"
              >
                <strong>CEP não localizado.</strong> Por gentileza entre
                manualmente.
              </p>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6">
              <a
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
                >Não sei meu CEP</a
              >
            </v-col>
          </v-row>
          <v-row class="pa-6 pt-0 pb-3 ma-0">
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-select
                outlined
                hide-details
                :rules="[rules.required]"
                :items="estados"
                label="Estado"
                v-model="currentInstaller.state"
                color="secondary"
                id="mb-8"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-text-field
                outlined
                hide-details
                :rules="[rules.required]"
                label="Cidade"
                v-model="currentInstaller.city"
                color="secondary"
                id="inputCidade"
                type="text"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <v-text-field
                hide-details
                outlined
                :rules="[rules.required]"
                label="Bairro"
                v-model="currentInstaller.neighborhood"
                id="inputBairro"
                color="secondary"
                type="text"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pa-6 pt-0 pb-3 ma-0">
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-text-field
                hide-details
                outlined
                :rules="[rules.required]"
                label="Logradouro"
                color="secondary"
                v-model="currentInstaller.addressName"
                id="inputLogradouro"
                type="text"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <v-text-field
                hide-details
                outlined
                label="Número"
                v-model="currentInstaller.addressNumber"
                color="secondary"
                id="AddressNumber"
                type="number"
                :required="true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <v-checkbox
                hide-details
                outlined
                class="black--text"
                v-model="currentInstaller.addressNumber"
                label="Sem número"
                value="Sem número"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row class="pa-6 pt-0 pb-3 ma-0 space-between">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                hide-details
                outlined
                label="Complemento"
                v-model="currentInstaller.addressComplement"
                id="inputComplemento"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <!--Bancários -->
      <!-- <v-card v-if="true" class="mx-auto" max-width="980px">
          <v-icon
            class="d-flex-row justify-center pl-10 pt-3"
            large
            color="normalred"
            >mdi-cash-multiple
          </v-icon>
          <span class="text-span" style="mx-auto">Informações Bancarias</span>

          <span class="pl-12 pb-2" style="display: block">
            Suas informações bancárias para receber seus pagamentos de comissões.
            Preencha todos os dados com atenção pois não será possível alterar
            depois, comprometendo seu pagamento.</span
          >
          <v-form
            class="form"
            :key="clear"
            ref="thirdForm"
            v-model="thirdValid"
            lazy-validation
          >
            <v-row class="pa-6 pb-3 ma-0">
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-autocomplete
                  outlined
                  hide-details
                  :items="banks"
                  v-model="currentInstaller.accountBank"
                  :label="'Banco'"
                  :rules="[rules.required]"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-text-field
                  outlined
                  hide-details
                  maxlength="8"
                  v-model="currentInstaller.agencyNumber"
                  :label="'Agência'"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="flex-space-between pa-6 pt-0 pb-3 ma-0 flex-end">
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-text-field
                  outlined
                  hide-details
                  maxlength="8"
                  v-model="currentInstaller.accountNumber"
                  :label="'Conta'"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="4" lg="4">
                <v-select
                  outlined
                  hide-details
                  v-model="currentInstaller.accountType"
                  :items="['Conta Corrente', 'Conta Poupança']"
                  :label="'Tipo de Conta'"
                  :rules="[rules.required]"
                  required
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="4" lg="4"> </v-col>
            </v-row>
          </v-form>
        </v-card> -->

      <!--Especialidades -->
      <v-card v-if="true" class="mx-auto" max-width="980px">
        <v-icon
          class="d-flex-row justify-center pl-10 pt-3"
          large
          color="normalred"
          >mdi-brain</v-icon
        >
        <span class="text-span" style="mx-auto;    padding-right: 15px;"
          >Especialidades</span
        >

        <v-btn
          fab
          tile
          small
          style="
            height: 27px;
            border-radius: 6px;
            box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
          "
          color="normalred"
          elevation="2"
          @click="openSpecialties()"
        >
          <v-icon>mdi-plus</v-icon></v-btn
        >
        <span class="pl-12 pb-2" style="display: block">
          Selecione a(s) especialidade(s) de aplicação dos adesivos, elas serão
          importantes para indicarmos aos clientes.</span
        >
        <v-form
          class="form"
          :key="clear"
          ref="fourthForm"
          v-model="fourthValid"
          lazy-validation
        >
          <v-row class="px-7" align="center" cols="12" sm="6" md="3" lg="3">
            <v-col
              v-for="item in especialidades"
              :key="item.name"
              class="checkbox"
            >
              <v-checkbox
                type="checkbox"
                color="deep-purple"
                class="ma-0 pb-0 pt-0 black--text"
                v-model="item.checked"
                @change="updateBoxes"
                :value="item.checked"
                :checked="item.checked"
                :disabled="item.disabled"
              >
                >
                <template :for="item.name" v-slot:label>
                  {{ item.name }}
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <!--Login -->
      <v-card v-if="true" class="mx-auto" max-width="980px">
        <v-icon
          class="d-flex-row justify-center pl-10 pt-3"
          large
          color="normalred"
          >mdi-lock-outline</v-icon
        >
        <span class="text-span" style="mx-auto">Informações de Login</span>

        <span class="pl-12 pb-2" style="display: block">
          Seu e-mail para login na plataforma de afiliado, e a sua senha.</span
        >

        <v-form
          class="form"
          :key="clear"
          ref="fifthForm"
          v-model="fifthValid"
          lazy-validation
        >
          <v-row class="pa-6 pb-3 ma-0">
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                outlined
                v-model="currentInstaller.email"
                :rules="[rules.emailRules]"
                :label="'E-mail'"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                outlined
                v-model="currentInstaller.email2"
                oncopy="return false"
                onpaste="return false"
                :rules="[
                  rules.required,
                  rules.emailRules,
                  this.currentInstaller.email ===
                    this.currentInstaller.email2 ||
                    'Os e-mails devem ser iguais.',
                ]"
                :label="'Confirmação de e-mail'"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                outlined
                v-model="currentInstaller.password"
                :rules="[(v) => !!v || 'Senha obrigatória']"
                :label="'Senha'"
                :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                outlined
                :append-icon="show2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
                oncopy="return false"
                onpaste="return false"
                :rules="[
                  (v) =>
                    v == currentInstaller.password || 'Senhas não coincidem',
                ]"
                :label="'Confirmação de senha'"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-checkbox
                v-model="agreement"
                class="px-9 pb-3 pt-0 black--text"
                :rules="[(v) => !!v || 'Você deve concordar para prosseguir!']"
                color="deep-purple"
              >
                <template v-slot:label>
                  Li e concordo com os &nbsp;
                  <a href="#" @click.stop.prevent="dialog = true">
                    Termos de Uso
                  </a>
                  <!-- &nbsp; e a &nbsp;
                    <a
                      href="#"
                      @click.stop.prevent="dialog = true"
                    > Politica de Privacidade</a>* -->
                </template>
              </v-checkbox>
              <v-dialog v-model="dialog" absolute max-width="400" persistent>
                <v-card>
                  <v-card-title class="text-h3 grey lighten-3">
                    Termos e Condições de Uso
                  </v-card-title>
                  <v-card-text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn text @click="(agreement = false), (dialog = false)">
                      Não
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="white--text"
                      color="normalred accent-4"
                      @click="(agreement = true), (dialog = false)"
                    >
                      Sim, Aceito
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" sm="4" md="4" lg="4">
              <v-btn class="pb-3 ma-2" depressed @click="voltar()">
                Voltar
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
              <v-btn
                large
                class="d-flex justify-content-center"
                style="
                  border-radius: 8px !important;
                  width: 100%;
                  font-weight: bold;
                  font-size: 18px;
                "
                color="normalred"
                @click="submit(true)"
                >Cadastrar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <v-dialog v-model="sucessDialog" max-width="600px">
        <ConfirmModalAdd
          :title="isEditing ? 'Alteração Efetuada' : 'Cadastro Efetuado'"
          :textMessage="
            isEditing ? 'Você tem certeza?' : 'Ativação de cadastro'
          "
          :okbtn="isEditing ? 'Sim, Confirmar' : 'Ativar agora'"
          :cancelBtn="isEditing ? 'Não' : 'Ativar depois'"
          :textObservation1="
            isEditing
              ? 'Após confirmar a alteração, os dados serão alterados.'
              : 'Ative ou desative o aplicador cadastrada.'
          "
          :textObservation2="
            isEditing
              ? ''
              : 'Clique para ativar depois para ativa-lo ou ative-o agora'
          "
          :icone="isEditing ? '' : 'mdi-restore-alert'"
          @close="(sucessDialog = false), updateTable()"
          @actionOK="!isEditing ? submit(true) : submit(false)"
          @actionCancel="!isEditing ? submit(false) : (sucessDialog = false)"
          :loading="true"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<style scoped>
.edit-photo {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.photo-field {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 221px;
  height: 166px;
  background: #dadada;
  cursor: pointer;
  transition: 0.1s;
  box-sizing: border-box;
  border-radius: 10px !important;
}
.delete-photo {
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #bc0000;
}
input[type="checkbox"] + label {
  color: #000;
}

input[type="checkbox"]:checked + label {
  font-weight: bold;
}

input[type="checkbox"]:disabled + label {
  color: #999;
}

.text-span {
  color: #000000;
  font-weight: bold;
  font-size: large;
}

.header-text {
  color: red !important;
}
.flex-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}
.black--text /deep/ label {
  color: black;
  font-size: 15px;
}

.item {
  background: #424242 !important;
}

.item span {
  font-size: 1.05rem;
  color: #fff;
}

.back {
  background: #e9e9e9 !important;
  max-width: unset;
}

.filterAutocomplete {
  box-sizing: border-box !important;
  border-radius: 8px !important;
  width: 100% !important;
}

.table-button {
  font-weight: bold !important;
  transition: 300ms !important;
}

.table-button:hover {
  background: #2d3038 !important ;
  color: white !important;
}

.table-button:focus {
  background: #444854;
  color: white !important;
}

.mytable .v-table tbody tr {
  border-left: 5px solid red !important;
}

@media (max-width: 330px) {
  .v-menu__content {
    left: 15px !important;
    max-width: unset;
  }
}

@media (max-width: 860px) {
  .table-header {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .theme--light.v-data-table thead tr:last-child th,
  .theme--light.v-data-table tbody tr:not(:last-child) td:last-child,
  .theme--light.v-data-table tbody tr td,
  .theme--light.v-data-table
    tbody
    tr:not(:last-child)
    td:not(.v-data-table__mobile-row) {
    border-bottom: medium solid black !important;
  }
}
</style>

<script>
import ApiService from "../../../services/ApiService";
import Vue from "vue";
import ConfirmModalAdd from "../components/ConfirmModalAdd.vue";
import bancosBrasil from "../../../assets/bancosBrasil.json";
import NewSpecialties from "../components/newSpecialties.vue";
import axios from "axios";

export default {
  name: "Register",

  props: {
    installer: Object,
  },

  components: {
    ConfirmModalAdd,
    NewSpecialties,
  },

  async created() {
    await this.listSpecialties();

    this.bancosBrasil();

    this.loading = true;

    await this.apiService
      .get(`installer/get-one?id=${this.$route.query.id}`)
      .then((response) => {
        if (response.body != "") {
          this.isEditing = true;
          this.currentInstaller = response;
          this.especialidades.forEach((item) => {
            for (let i = 0; i < this.currentInstaller.specialties.length; i++) {
              if (
                item.id === this.currentInstaller.specialties[i].specialtiesId
              ) {
                item.checked = true;
              }
            }
          });
        }

        this.loading = false;
      });
  },

  data: () => ({
    show: false,
    show2: false,
    isEditing: false,
    banks: [],
    tickLimit: 3,
    ticks: [],
    newSpecialtyDialog: false,
    loading: false,
    cepMask: "",
    step: 1,
    clear: false,
    dialog: false,
    sucessDialog: false,
    postalCode: "",
    naoLocalizado: false,
    agreement: false,
    firstValid: true,
    secondValid: true,
    thirdValid: true,
    fourthValid: true,
    fifthValid: true,
    page: 1,
    totalPages: 1,
    pageSize: 15,
    filter: {
      page: 1,
      pageSize: 15,
    },
    especialidades: [
      // { label: "Móveis", value: 0, checked: false, disabled: false },
      // { label: "Vidros", value: 1, checked: false, disabled: false },
    ],
    closeNoLoading: false,
    apiService: new ApiService(),

    regions: [
      "Nacional - Todas as Regiões",
      "Internacional",
      "Sul - Região 5",
      "Sudeste - Região 4",
      "Centro-Oeste - Região 3",
      "Nordeste - Região 2",
      "Norte - Região 1",
    ],

    estados: [
      "AC",
      "AL",
      "AM",
      "AP",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RO",
      "RS",
      "RR",
      "SC",
      "SE",
      "SP",
      "TO",
    ],
    location: {},
    currentInstaller: {
      installerName: "",
      agreement: false,
      cnpj: "",
      inscrEstadual: "",
      phoneNumber: "",
      phoneNumber2: "",
      birthDate: "",
      postalCode: "",
      state: "",
      city: "",
      neighborhood: "",
      addressName: "",
      addressNumber: "",
      country: "",
      addressComplement: "",
      installerRegion: "",
      installerCategory: "",
      password: "",
      password2: "",
      email: "",
      email2: "",
      receiveEmail: "",
      receiveSms: "",
      textObservation: "",
      Latitude: 0.0,
      Longitude: 0.0,
      image: "",
    },

    rules: {
      nameRules: (v) =>
        (v && v.length < 45) || "Nome deve ter no máximo 45 caracteres",

      emailRules: (v) => /.+@.+\..+/.test(v) || "E-mail inválido",

      postalCodeRules: (v) =>
        (v && v.length <= 10) || "CEP deve ter no máximo 10 caracteres",

      required: (value) => !!value || "Campo requerido.",
    },
  }),

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },

  methods: {
    setImage(image) {
      if (image.size < 2097152) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = () => {
          this.currentInstaller.image = reader.result;
        };
      } else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "warning",
          title: "Erro",
          message: "Arquivo excede o limite de 2mb",
          trace: null,
        });
      }
    },
    showPhoto(image) {
      if (!!image && image.includes("Uploads/")) {
        const env_url = process.env.VUE_APP_BASE_URL;
        return env_url + image;
      } else {
        return image;
      }
    },
    openPicker() {
      document.querySelector("#imageInput").click();
    },
    async getLocation() {
      let address = [
        this.currentInstaller.addressNumber,
        this.currentInstaller.addressName,
        this.currentInstaller.state,
        this.currentInstaller.city,
      ].join(" ");
      await axios
        .get("https://maps.googleapis.com/maps/api/geocode/json", {
          params: {
            address: address,
            key: process.env.VUE_APP_APIKEY,
          },
        })
        .then((response) => {
          let result = response.data.results[0].geometry.location;

          this.location = {
            latitude: parseFloat(result.lat),
            longitude: parseFloat(result.lng),
            place_id: response.data.results[0].place_id,
          };
          this.currentInstaller.Latitude = this.location.latitude;
          this.currentInstaller.Longitude = this.location.longitude;
        })
        .catch((erro) => {});
    },

    openSubmit() {
      if (
        this.$refs.firstForm.validate() &&
        this.$refs.secondForm.validate() &&
        // this.$refs.thirdForm.validate() &&
        this.$refs.fourthForm.validate() &&
        this.$refs.fifthForm.validate()
      ) {
        this.sucessDialog = true;
      } else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Erro",
          message: "Formulário não validado",
          trace: null,
        });
      }
    },

    openSpecialties() {
      this.newSpecialtyDialog = true;
    },

    updateBoxes() {
      // update the number of ticks...
      this.ticks = this.especialidades.filter((item) => item.checked);
      this.currentInstaller.specialties = this.especialidades.filter(
        (item) => item.checked != false
      );
    },

    async validate() {
      this.loading = true;

      const userValid = {
        Cnpj: this.currentInstaller.cnpj,
        Email: this.currentInstaller.email,
        Name: this.currentInstaller.installerName,
        PhoneNumber: this.currentInstaller.phoneNumber,
        PhoneNumber2: this.currentInstaller.phoneNumber2,
      };

      await this.apiService
        .post("installer/validate", userValid)
        .then(() => {
          if (this.true) {
            this.sucessDialog = true;
          } else if (this.step != 3) {
            this.step += 1;
          }

          this.loading = false;
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: err.body.message,
            message: err.body.message,
            trace: null,
          });

          this.loading = false;
        });
    },

    async searchCEP() {
      this.cepMask = "#####-###";
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.currentInstaller.postalCode)) {
        var self = this;
        self.naoLocalizado = false;
        self.location = {};
        var client = new ApiService();

        await client
          .get(`user/postalcode?cep=${this.currentInstaller.postalCode}`)
          .then((response) => {
            const location = JSON.parse(response);

            if (location.erro) {
              self.location = {};
              this.naoLocalizado = true;
              this.currentInstaller.neighborhood = "";
              this.currentInstaller.address = "";
              this.currentInstaller.city = "";
              this.currentInstaller.state = "";
              this.currentInstaller.addressNumber = "";
              this.currentInstaller.country = "Brasil";
              this.currentInstaller.addressName = "";
              return;
            }

            this.currentInstaller.neighborhood = location.bairro;
            this.currentInstaller.addressName = location.logradouro;
            this.currentInstaller.city = location.localidade;
            this.currentInstaller.state = location.uf;
            this.currentInstaller.country = "Brasil";
            document.getElementById("AddressNumber").focus();

            return;
          });
      }
    },

    generatePassword() {
      return Math.random().toString(36).slice(-8);
    },

    close() {
      this.$emit("close");
    },

    bancosBrasil() {
      var bancos = [];

      bancosBrasil.forEach((bank) => {
        bancos.push(bank.COMPE + "-" + bank.ShortName);
      });

      this.banks = bancos;

      return this.banks;
    },

    voltar(rota) {
      this.$router.push("/installers");
    },

    async listSpecialties() {
      this.loading = true;
      this.isLoading = true;
      await this.apiService
        .post(`specialties/list`, this.filter)
        .then((response) => {
          this.totalPages = response.pager.totalPages;
          this.specialties = response.specialties;
          this.tickLimit = response.specialties.length - 1;
          this.loading = false;
          this.isLoading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.deleteDialog = false;
        });

      this.especialidades = this.specialties;
    },

    async submit(active) {
      await this.getLocation();
      this.loading = true;

      const model = {
        Specialties: [],
        Id: this.currentInstaller.id,
        Name: this.currentInstaller.name,
        Password: this.currentInstaller.password,
        Cnpj: this.currentInstaller.cnpj,
        InscrEstadual: this.currentInstaller.inscrEstadual,
        NameFantasy: this.currentInstaller.nameFantasy,
        PhoneNumber: this.currentInstaller.phoneNumber,
        PhoneNumber2: this.currentInstaller.phoneNumber2,
        Email: this.currentInstaller.email,
        Country: this.currentInstaller.country,
        BirthDate: this.currentInstaller.birthDate
          ? this.currentInstaller.birthDate
          : null,
        AddressNumber: parseInt(this.currentInstaller.addressNumber),
        PostalCode: this.currentInstaller.postalCode,
        Neighborhood: this.currentInstaller.neighborhood,
        AddressName: this.currentInstaller.addressName,
        State: this.currentInstaller.state,
        AddressComplement: this.currentInstaller.addressComplement,
        City: this.currentInstaller.city,
        ReceiveSms: false,
        Region: this.currentInstaller.installerRegion,
        Category: this.currentInstaller.installerCategory,
        Observations: this.currentInstaller.textObservation,
        Latitude: this.currentInstaller.Latitude,
        Longitude: this.currentInstaller.Longitude,
        ReceiveEmail: true,
        AccountNumber: this.currentInstaller.accountNumber,
        AgencyNumber: this.currentInstaller.agencyNumber,
        AccountBank: this.currentInstaller.accountBank,
        AccountType: this.currentInstaller.accountType,
        Image: this.currentInstaller.image,
        whatsApp: this.currentInstaller.whatsApp,
        instagram: this.currentInstaller.instagram,
        Status: this.currentInstaller.status
          ? this.currentInstaller.status
          : active
          ? 1
          : 0,
      };

      this.especialidades.forEach((item) => {
        if (item.checked == true) {
          model.Specialties.push({
            SpecialtiesId: item.id,
          });
        }
      });

      let url = "installer/add";
      if (this.isEditing) {
        url = "installer/edit";
      }

      await this.apiService
        .post(url, model)
        .then((resp) => {
          /*let email = {
              Url: process.env.VUE_APP_BASE_URL_FRONT,
              Name: model.Name,
              To: model.Email,
              Subject: "Senha provisória IMPRIMAX Autoadesivos",
              Message: `${model.Password}`,
              Type: "aplicador",
            };
            if (this.isEditing) {
               email = {
                Url: process.env.VUE_APP_BASE_URL_FRONT,
                Name: model.Name,
                To: model.Email,
                Subject: "IMPRIMAX - Alteração de cadastro",
                Message: `Olá, seu cadastro foi alterado recentemente.
                  .Para acessar a alteração de dados, vá até a pagina de configuracões, que se encontra no seu menu de usuario no canto superior direito!`,
              };
            }
            this.sendEmailInstaller(email);*/
          if (this.isEditing) {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "success",
              title: "Cadastro atalizado com sucesso!",
              message: "Seus dados foram atualizados",
              trace: null,
            });
          } else {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "success",
              title: "Cadastro efetuado com sucesso!",
              message: "Bem vindo à IMPRIMAX!",
              trace: null,
            });
          }
          let token = localStorage.getItem("token");
          if (token) {
            this.$router.push("/installers");
          } else {
            this.$router.push("/auth");
          }
        })
        .catch((err) => {
          if (err.status != 403) {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Não foi concluir a ação",
              message: "Confira se o e-mail já está cadastrado",
              trace: null,
            });
            this.sucessDialog = false;
          } else if (err.status == 403) {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Este usuário não tem autorização para concluir a ação.",
              message: "Somente usuários com permissão podem deletar/editar",
              trace: null,
            });
            this.$router.push("/installers");
            this.close();
          }
        });

      this.loading = false;
    },

    async sendEmailInstaller(email) {
      await this.apiService
        .post("email/sendClient", email)
        .then((resp) => {
          if (this.isEditing) {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "info",
              title: "Cadastro atalizado com sucesso!",
              message: "Seus dados foram atualizados",
              trace: null,
            });
          } else {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "success",
              title: "Cadastro efetuado com sucesso!",
              message: "Bem vindo à IMPRIMAX!",
              trace: null,
            });
          }
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Erro",
            message: "E-mail inválido",
            trace: null,
          });
          this.sucessDialog = false;
        });
    },
  },
};
</script>
