import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{staticClass:"mainProduct"},[_c('div',{attrs:{"id":"product"}},[_c(VBreadcrumbs,{staticStyle:{"margin-left":"-15px","margin-bottom":"-20px"},attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c(VIcon,{staticStyle:{"margin":"0 -15px 0 -12px !important"}},[_vm._v("mdi-chevron-right")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VBreadcrumbsItem,{attrs:{"to":item.href,"disabled":item.disabled}},[_c('span',{staticClass:"item-breadcrumbs",style:(item.href ? 'color: #BC0000; font-weight: 500;' : '')},[_vm._v(" "+_vm._s(item.text))])])]}}])})],1),(_vm.isMobile && _vm.product)?_c('div',[_c('Details2ProductMobile',{key:_vm.dialogKey,attrs:{"productId":_vm.product.productId},on:{"close":function($event){_vm.detailsDialog = false},"go-to":_vm.goTo}})],1):_vm._e(),(!_vm.isMobile && _vm.product)?_c('div',[_c('Details2ProductDesktop',{key:_vm.dialogKey,attrs:{"productId":_vm.product.productId},on:{"close":function($event){_vm.detailsDialog = false},"go-to":_vm.goTo}})],1):_vm._e(),(false)?_c(VBtn,{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],staticStyle:{"z-index":"10","right":"4%"},attrs:{"fab":"","depressed":"","fixed":"","bottom":""},on:{"click":function($event){return _vm.$vuetify.goTo(0)}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-chevron-up")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }