<template>
  <div class="ma-auto px-3" style="max-width: 1785px; height: 100vh">
    <v-card>
      <v-row style="width: 100%" class="ma-0">
        <v-col cols="12" sm="2" md="2" class="d-flex align-center">
          <v-img
            width="200px"
            :src="
              installer.image != null && installer.image != ''
                ? installer.image
                : require('../../../assets/imprimax_logo.png')
            "
          ></v-img>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <div>
            <b style="font-size: 16px"> Nome: </b>
            {{ installer.name }}
          </div>
          <div>
            <b style="font-size: 16px"> Numero 1: </b>
            {{ installer.phoneNumber }}
          </div>
          <div>
            <b style="font-size: 16px"> Numero 2: </b>
            {{ installer.phoneNumber2 }}
          </div>
        </v-col>
        <v-col
          ><div>
            <span style="font-size: 16px">
              <b style="font-size: 16px">Cidade:</b> {{ installer.city }},
              {{ installer.state }}
            </span>
          </div>

          <b style="font-size: 16px"> Especialidades</b>

          <div v-for="(item, i) in installer.specialties" :key="i">
            {{ item.specialties.name }}
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import ApiService from "../../../services/ApiService";

export default {
  components: {},
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.$vuetify.goTo(0);
  },

  data: () => ({
    id: null,
    addressIdSelect: "",
    componentKey: 1,
    valor: "",
    isMobile: false,
    badge: "",
    clearDialog: false,
    dialogKey: false,
    loading: false,
    installer: {},
    filter: {
      Page: 1,
      PageSize: 15,
    },
    env_url: process.env.VUE_APP_BASE_URL,
    apiService: new ApiService(),
  }),

  computed: {},
  watch: {},

  async created() {
    if (this.$route.query != null) {
      await this.getInstaller(this.$route.query.id);
    }
  },

  methods: {
    async getInstaller(i) {
      await this.apiService
        .get(`installer/get-one?id=${i}`)
        .then((res) => {
          this.installer = res;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.list-product-content {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(280px, auto));
}
.divInstallers:hover {
  box-shadow: 10px 10px;
}
.image-card-hover:hover {
  box-shadow: 10px 11px 13px rgba(0, 0, 0, 0.25) !important;
}
.card-subprice {
  font-family: Poppins;
  font-style: normal;
  font-size: 0.9rem;
  line-height: 24px;

  letter-spacing: 0.15px;

  color: #979797;
}
.price-total {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: black;
}
.label-cart {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1.45rem;
  line-height: 36px;
  letter-spacing: 0.15px;

  color: #000000;
}
.price-frete {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.55rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: #979797;
}
.frete {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: #979797;
}
.frete:hover {
  cursor: pointer;
}
.span-red {
  align-self: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: #bc0000;
}
.span-red:hover {
  cursor: pointer;
}
.empty-message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 3.2rem;

  color: #97999d;
}
.empty-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;

  color: #97999d;
}

@media (min-width: 600px) {
  .card-cart {
    width: 70%;
    background: #ffffff;
    box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
    border-radius: 6px;
  }
}

.v-card__text {
  padding: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
}
.containerList {
  justify-content: center !important;
  flex-direction: column;
}
</style>
