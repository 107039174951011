import Layout from "../../internal/Layout.vue";
import CartPage from "./Cart.vue";
import AddressPage from "./Address.vue";
import ListAddressPage from "./ListAddress.vue";


const CartRoutes = {
  component: Layout,
  children: [
    {
      path: "/cart",
      name: "Cart",
      component: CartPage,
      meta: {},
    },
    {
      path: "/address",
      name: "Address",
      component: AddressPage,
      meta: {},
    },
    {
      path: "/list-address",
      name: "ListAddress",
      component: ListAddressPage,
      meta: {},
    },
    
  ],
};

export default CartRoutes;
