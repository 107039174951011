import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"card pa-6 mx-auto",attrs:{"elevation":"1"}},[_c('div',{staticClass:"px-2 d-flex align-center"},[(_vm.icone)?_c(VAvatar,{staticClass:"subheading flex-center white--text mr-11"},[_c(VIcon,{attrs:{"large":""}},[_vm._v(_vm._s(_vm.icone))])],1):_vm._e(),_c('h2',{staticClass:"justify-center"},[_vm._v(_vm._s(_vm.title))])],1),_c('div',{staticClass:"my-4 mx-2 px-1 d-flex flex-column justify-center align-center"},[_c('span',{staticClass:"label py-2"},[_vm._v(" "+_vm._s(_vm.textMessage)+" ")]),(_vm.quantidade > 0)?_c('span',{staticClass:"label observation"},[_vm._v(" Produtos Cadastrados: ")]):_vm._e(),(_vm.quantidade > 0)?_c('span',{staticClass:"label observation py-2"},[_vm._v(" "+_vm._s(_vm.quantidade)+" "+_vm._s(_vm.textObservation)+" ")]):_vm._e()]),_c('div',[_c(VRow,{staticClass:"d-flex ma-0 pa-0"},[_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"cancel-button black--text mr-4",attrs:{"elevation":"0","large":"","loading":_vm.loading,"outlined":""},on:{"click":_vm.close}},[_c('span',[_vm._v(_vm._s(_vm.cancelBtn))])]),_c(VBtn,{staticClass:"cadaster-button white--text",attrs:{"elevation":"0","large":"","color":"normalred","loading":_vm.loading},on:{"click":function($event){return _vm.action()}}},[_c('span',[_vm._v(_vm._s(_vm.okbtn))])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }