<template>
  <v-container>
    <div style="color: hsl(0, 0%, 31%)" v-if="order">
      <div class="d-flex align-center mb-4">
        <div class="mr-2">
          <v-img
            src="../../../../assets/check.svg"
            max-height="60"
            contain
          ></v-img>
        </div>

        <div>
          <h3 class="payed-title">
            Pedido
            <span style="color: #008000">n° {{ order.orderCode }} </span>
            realizado com sucesso!
          </h3>
          <span style="font-size: 12px">{{
            order.orderStatus === pedidoAguardando
              ? "Aguardando confirmação de pagamento."
              : "Seu pagamento foi confirmado"
          }}</span>
        </div>
      </div>

      <div class="d-flex">
        <span style="font-size: 12px">Data do pedido: {{ date(order.created_at) }}</span>

        <v-divider vertical class="mx-2"> </v-divider>

        <span style="font-size: 12px">Realizado às {{ hours(order.created_at) }}</span>
      </div>

      <div class="mt-4">
        <v-card v-if="order.paymentMethod == 2" class="itens-card mt-0 pa-3">
          <div class="d-flex align-center mb-4">
            <div class="mr-2">
              <v-img
                src="../../../../assets/pix-red.svg"
                contain
                max-height="36"
              ></v-img>
            </div>
            <strong class="delivery-text">Pagamento</strong>
          </div>

          <span class="section-title">Forma de pagamento</span>
          <v-card outlined class="pa-3 mt-2">
            <div class="d-flex align-center">
              <div class="mr-2">
                <v-img
                  src="../../../../assets/pix.svg"
                  contain
                  max-height="24"
                ></v-img>
              </div>
              <strong class="strong-title">Pix</strong>
            </div>

            <div class="d-flex mt-3" v-if="order.orderStatus === pedidoAprovado">
              <div class="mr-2">
                <v-img
                    src="../../../../assets/check.svg"
                    height="20"
                    width="20"
                ></v-img>

              </div>
              <p>Seu pagamento foi confirmado</p>
            </div>
          </v-card>

          <div v-if="order.orderStatus !== pedidoAprovado">
            <div class="d-flex align-center mb-4 ml-4" v-if="expiresInQrCode(order)">
              <div class="mr-2">
                <v-icon size="30" color="#bc0000">mdi-lock-clock</v-icon>
              </div>
              <h4 class="red--text">Qr Code expirado</h4>
            </div>

            <div v-else>
              <span class="section-title">Instruções para pagamento</span>

              <v-card outlined class="pa-3 mt-2">
              <span class="section-title">Instruções para pagamento do pix</span>
              <ul class="section-title">
                <li>Acesse seu Internet Banking ou aplicativo de pagamentos.</li>
                <li>Na opção, escolha ler QR Code ou copie o código abaixo.</li>
                <li>Revise as informações e confirme o pagamento.</li>
              </ul>

              <h4 class="mt-4 strong-title">Código copia e cola</h4>
              <v-btn
                color="normalred"
                block
                @click="(showCode = true), startCountdown()"
                v-if="!showCode"
              >
                <strong> Gerar código copia e cola </strong>
              </v-btn>

              <v-expand-transition>
                <div v-if="showCode">
                  <div class="d-flex align-center">
                    <div
                      style="
                        background: #f2f2f2;
                        border-radius: 6px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        width: 100%;
                        text-align: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: calc(100% - 10px);
                      "
                      class="pa-3 my-2"
                    >
                      {{ order.qrCodePix }}
                    </div>

                    <v-btn
                      outlined
                      color="normalred"
                      style="text-transform: none"
                      max-width="130"
                      @click="copyQrcode"
                    >
                      <div class="d-flex align-center">
                        <v-icon small>mdi-content-copy</v-icon>
                        <strong>Copiar código</strong>
                      </div>
                    </v-btn>
                  </div>
                  <h4 class="mt-4 strong-title">Tempo até expirar o pagamento</h4>
                  <strong style="color: #bc0000">{{ time }}</strong>
                </div>
              </v-expand-transition>
            </v-card>
            </div>
          </div>
        </v-card>

        <v-card
          v-if="order.paymentMethod == 1"
          class="itens-card mt-0 d-flex flex-column pa-3"
          height="100%"
        >
          <div class="d-flex align-center mb-4">
            <div class="mr-2">
              <v-img
                src="../../../../assets/boleto-red.svg"
                contain
                max-height="36"
              ></v-img>
            </div>
            <strong class="delivery-text">Pagamento</strong>
          </div>

          <span class="section-title">Forma de pagamento</span>
          <v-card outlined class="pa-3 mt-2">
            <div class="d-flex align-center">
              <div class="mr-2">
                <v-img
                  src="../../../../assets/boleto-icon.svg"
                  contain
                  max-height="24"
                ></v-img>
              </div>
              <strong class="strong-title">Boleto Bancário</strong>
            </div>
          </v-card>

          <span class="section-title">Informações Importantes</span>

          <v-card outlined class="pa-2 mt-2 section-title">
            <p>
              Após o pagamento, há um prazo para compensação do boleto de 2 a 3
              dias úteis.
            </p>

            <p>
              Este processo ocorre automaticamente e iremos sinalizá-lo através
              de seu e-mail cadastrado quando identificarmos o pagamento.
            </p>

            <p>
              Caso o boleto não seja pago até o dia do vencimento, seu pedido
              será cancelado automaticamente.
            </p>
          </v-card>

          <v-spacer></v-spacer>

          <strong style="font-size: 12px; color: #6d7989"
            >Acesse o boleto clicando no botão abaixo:</strong
          >
          <v-btn
            block
            color="normalred"
            depressed
            max-height="50"
            :href="order.urlBoleto"
            target="_blank"
          >
            <div class="d-flex align-center" style="text-transform: none">
              <v-icon class="mr-2"> mdi-printer </v-icon>
              <strong>Imprimir boleto</strong>
            </div>
          </v-btn>
        </v-card>

        <div
          v-if="order.paymentMethod == 0"
          class="d-flex flex-column fill-height"
        >
          <v-card class="itens-card mt-0 pa-3">
            <div class="d-flex align-center mb-4">
              <v-icon class="mr-2" size="30" color="normalred">
                mdi-credit-card-outline
              </v-icon>
              <strong class="delivery-text">Pagamento</strong>
            </div>

            <span class="section-title">Forma de pagamento</span>
            <v-card outlined class="pa-3 mt-2">
              <div class="d-flex align-center">
                <v-icon class="mr-2" size="30" color="#4F4F4F">
                  mdi-credit-card-outline
                </v-icon>
                <strong class="strong-title">Cartão de crédito</strong>

                <v-spacer></v-spacer>

                <strong style="font-size: 12px">
                  {{ brandCard }}
                </strong>
              </div>
            </v-card>

            <span class="section-title">Número de parcelas</span>
            <div
              style="
                background: #f2f2f2;
                border-radius: 6px;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                width: 100%;
                text-align: center;

                font-family: 'Roboto';
                font-weight: 400;
                font-size: 13px;
                color: #6d7989;
              "
              class="pa-3 my-2"
            >
              Em {{ order.creditInstallments }}x de
              {{
                (
                    ((totalPrice + fretePrice + installmentsInterest)) /
                  order.creditInstallments
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
              {{ order.creditInstallments > 3 ? "" : "sem juros" }}
            </div>
          </v-card>

          <v-spacer></v-spacer>
        </div>
      </div>

      <v-card class="itens-card pa-3">
        <div class="d-flex align-center">
          <v-icon size="33" color="normalred" class="mr-2">
            mdi-file-document-multiple-outline
          </v-icon>
          <strong class="delivery-text">Resumo do pedido</strong>
        </div>

        <v-row class="ma-0">
          <v-col cols="12" sm="7">
            <span class="section-title">Itens do pedido</span>

            <div class="itens">
              <div class="d-flex my-2" v-for="(item, idx) in itens" :key="idx">
                <v-img
                  class="mr-2"
                  :src="item.product.productPic"
                  max-width="90"
                  contain
                ></v-img>

                <div>
                  <span>
                    <strong v-if="item.type === 'produto'">
                      {{ item.qty }}X
                    </strong>
                    {{ item.product.productName }}
                  </span>

                  <br />
                  <br />

                  <div class="d-flex justify-space-between align-end">
                    <div>
                      Variação:
                      <br />
                      {{ item.productSku.attributes[0].name }}
                    </div>
                    <h3>
                      {{
                        (item.price * item.qty).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col>
            <span class="section-title">Total para pagamento</span>

            <div
              class="mt-3 pa-5"
              style="background: #f2f2f2; border-radius: 3px"
            >
              <div class="d-flex">
                <strong class="item-text">Produtos</strong>
                <v-spacer></v-spacer>
                <strong class="item-text">
                  {{
                    totalPrice.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </strong>
              </div>

              <br />

              <div class="d-flex" v-if="order.fretePrice">
                <strong class="item-text">Frete </strong>
                <v-spacer></v-spacer>
                <strong class="item-text">
                  {{
                    fretePrice.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </strong>
              </div>

              <br v-if="installmentsInterest" />

              <div class="d-flex" v-if="installmentsInterest">
                <strong class="item-text">Juros </strong>
                <v-spacer></v-spacer>
                <strong class="item-text" >
                  {{
                    installmentsInterest.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </strong>
              </div>

              <br v-if="order.discount" />

              <div class="d-flex" v-if="order.discount">
                <strong class="item-text">Desconto </strong>
                <v-spacer></v-spacer>
                <strong class="item-text" style="color: #008000">
                  -{{
                    order.discount.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </strong>
              </div>

              <div class="d-flex mt-4">
                <strong
                  class="item-text"
                  style="color: #bc0000 !important; font-weight: 700"
                >
                  Total
                </strong>
                <v-spacer></v-spacer>
                <div class="d-flex flex-column justify-end align-end">
                  <strong
                    class="item-text"
                    style="color: #bc0000 !important; font-weight: 700"
                  >
                    {{
                      ((totalPrice + fretePrice + installmentsInterest )).toLocaleString(
                        "pt-br",
                        { style: "currency", currency: "BRL" }
                      )
                    }}
                  </strong>
                  <strong style="font-size: 12px" class="item-text">
                    Em {{ order.creditInstallments }}x de
                    {{
                      (
                          ((totalPrice + fretePrice + installmentsInterest)) /
                        order.creditInstallments
                      ).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}
                    {{ order.creditInstallments > 3 ? "" : "sem juros" }}
                  </strong>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="itens-card pa-3">
        <div class="d-flex align-center">
          <v-icon color="normalred" size="33" class="mr-2">
            mdi-truck-delivery-outline
          </v-icon>
          <strong class="delivery-text">Endereço de entrega</strong>
        </div>

        <v-row class="ma-0" dense>
          <v-col cols="12" sm="8">
            <v-card outlined class="pa-3 my-1">
              <strong class="strong-title">{{ order.addressName }}</strong>
              <p class="mb-0">
                {{ order.address }}
              </p>
            </v-card>
          </v-col>
          <v-col>
            <span class="section-title">Frete</span>
            <v-card outlined class="pa-3 my-1 d-flex">
              <strong class="strong-title">Frete PAC</strong>
              <v-spacer></v-spacer>
              <span>Prazo de {{ order.prazoEntrega }} dias úteis</span>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <div v-if="order.orderStatus === pedidoAprovado">
        <v-btn
          block
          outlined
          color="normalred"
          style="text-transform: none; background: white"
          max-height="50"
          to="/profile"
        >
          <strong>Acessar os meus pedidos</strong>
        </v-btn>

        <v-btn
          class="mt-4"
          block
          color="normalred"
          style="text-transform: none"
          max-height="50"
          to="/"
        >
          <div class="d-flex align-center">
            <v-icon class="mr-2"> mdi-cart </v-icon>
            <strong>Continuar comprando</strong>
          </div>
        </v-btn>
      </div>
    </div>

    <v-dialog
      transition="dialog-bottom-transition"
      v-model="suggestDialog"
      max-width="475px"
    >
      <SuggestModal
        textMessage="Deseja localizar um aplicador?"
        textObservation1="Você pode visualizar aplicadores em sua região, para auxilio na  melhor forma da aplicação dos seus produtos."
        cancelBtn="Cancelar"
        okbtn="Confirmar"
        @action="goToRoute('/select-installer')"
        @close="suggestDialog = false"
        @generate="suggestDialog = false"
        @closeNoLoading="closeNoLoading"
        :key="dialogKey"
      />
    </v-dialog>
  </v-container>
</template>

<style>
.v-data-table__wrapper::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background: #d9d9d9;
  border-radius: 4px;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #a7a7a7;
  border-radius: 4px;
}
</style>

<style scoped>
.strong-title {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #4f4f4f;
}
.section-title {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
}
.active-card {
  border: 1px solid #bc0000 !important;
  box-shadow: 0px 12px 20px rgba(14, 31, 53, 0.12) !important;
}
.item-text {
  font-weight: 500;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.7);
}

.itens {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 5px;
}

.itens::-webkit-scrollbar {
  width: 7px;
  background: #d9d9d9;
  border-radius: 4px;
}

.itens::-webkit-scrollbar-thumb {
  background: #a7a7a7;
  border-radius: 4px;
}
.delivery-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #2d3038;
}

.itens-card {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding: 24px;
}

.payed-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #2d3038;
}
</style>

<style scoped>
li::marker {
  font-weight: bold;
}
.sombra {
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
  border-radius: 10px !important;
}
.image-brand-card {
  margin-right: 10px;
  margin-top: -15px;
}
.dados-pagamento {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #000000;
  margin-top: 17px;
  text-align: left;
}
.image-placeholder {
  background: rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 46%,
    rgba(255, 255, 255, 0.8) 53%,
    rgba(255, 255, 255, 0) 65%,
    rgba(255, 255, 255, 0) 100%
  );
  background-position-x: -100vw;
  background-repeat: no-repeat;
  animation: shiny 1.5s infinite;
}

@keyframes shiny {
  0% {
    background-position-x: -100vw;
  }
  10% {
    background-position-x: -100vw;
  }
  75% {
    background-position-x: 100vw;
  }
  100% {
    background-position-x: 100vw;
  }
}

.image-placeholder:hover {
  color: #ffffff !important;
  background: #bc0000;
  box-sizing: border-box;
  border-radius: 10px;
}
.image-placeholder:hover .icon {
  color: #ffffff !important;
}
.text {
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 0px;

  letter-spacing: 0.15px;

  color: #2d3038;
}
.cards {
  height: auto;
}
.cards-small {
  height: auto;
}

.label-right {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #e30011;
}

.span-title {
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;

  color: rgba(0, 0, 0, 0.5);
}
.mainCheckout {
  padding-top: 0px !important;
  max-width: unset !important;
  text-align: -webkit-center;
  background-color: #f5f5f5;
  width: 90% !important;
  margin: 0 auto;
}
#mainCheckout {
  font-family: Roboto, serif !important;
}
.content_copy_qrcode {
  margin-top: 10px;
  text-align: left;
  cursor: pointer;
  margin-left: 25px !important;
}
#qrCodePix {
  display: none;
}
.copy_qrcode {
  margin-left: 5px !important;
  margin-top: -3px;
}
@media (max-width: 600px) {
  .text {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 0.2px;

    letter-spacing: 0.15px;

    color: #2d3038;
  }
}
</style>

<script>
import ApiService from "../../../../services/ApiService";
import Utils from "../../../../Utils/FormatDate";
import SuggestModal from "../../components/ConfirmModal.vue";
import moment from 'moment';
import Vue from "vue";

export default {
  name: "OrderStatus",

  components: {
    SuggestModal,
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
    clearInterval(this.interval);
  },

  async mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, {passive: true});
    await this.startTimer();
  },

  data: () => ({
    pedidoAguardando: 1,
    pedidoAprovado: 3,
    showCode: false,
    env: process.env.VUE_APP_BASE_URL,
    bankSlip: 1,
    pix: 2,
    orders: [],
    ordersCode: [],
    closeNoLoading: false,
    suggestDialog: false,
    dialogKey: false,
    creditInstallments: "",
    componentKey: "",
    valorFrete: "",
    total: "",
    orderCode: "",
    pedido: [],
    apiService: new ApiService(),
    endereco: [],
    frete: [],
    itens: [],
    order: null,
    brandCard: "",
    brandCardUrlImg: "",
    numberCardFlags: "",
    isMobile: false,
    src: "",
    isBoleto: false,
    loading: false,
    deniedPaymentDialog: false,
    approvePaymentDialog: false,
    confirmPaymentDialog: false,
    selectAddressDialog: false,
    currentClient: [],
    interval: null,
    countdownDate: moment(60 * 30 * 1000),
  }),

  computed: {
    time: function () {
      return `${this.countdownDate.minutes()} minutos e ${this.countdownDate.seconds()} segundos`;
    },
    totalPrice(){
      return this.itens.reduce((total, item) => total + (item.price * item.qty), 0)
    },
    fretePrice(){
      return this.orders.reduce((total, item) => total + item.fretePrice, 0)
    },
    discount(){
      return this.orders.reduce((total, item) => total + item.discount, 0)
    },
    installmentsInterest(){
      return this.orders.reduce((total, item) => total + item.installmentsInterest, 0)
    }
  },

  async created() {
    this.badge = JSON.parse(localStorage.getItem("cartBadge"));
    this.user = JSON.parse(localStorage.getItem("user"));
    this.addressIdSelect = localStorage.getItem("addressIdSelect");

    this.currentUser = this.user;
    const { ordersCode } = this.$route.query
    this.ordersCode = ordersCode.split(',').map((x) => {
      return {
        Codes: x
      }
    })
    await this.getOrdersbyCode();
    await this.getAddress(this.currentUser, this.addressIdSelect);
  },

  methods: {
    date(date) {
      return moment(date).format("DD/MM/yyyy");
    },

    expiresInQrCode(order) {
      return moment(new Date(order.currentDate)).isAfter(new Date(order.expiresInQrCodeUrlPix));
    },

    hours(date) {
      return moment(date).format("HH:mm:ss");
    },
    startCountdown() {
      this.interval = setInterval(() => {
        this.countdownDate = moment(this.countdownDate).add(-1, "seconds");
      }, 1000);
    },
    async startTimer(){
      this.interval = setInterval(async() => {
        if (this.orders.find((x) => x.orderStatus !== this.pedidoAprovado)) {
          this.showLoading = false
          await this.getOrdersbyCode()
        }
      },10000)
    },
    async copyQrcode() {
      await navigator.clipboard.writeText(this.order.qrCodePix);
      this.$toast.success("QR Code copiado!");
    },
    async getOrdersbyCode() {
      this.loading = true;
      await this.apiService
        .post(`order/by-codes`, this.ordersCode, this.showLoading, this.showLoading, this.showLoading)
        .then((response) => {
          this.orders = response;
          this.order = this.orders[0];
          this.componentKey += 1;
          this.loading = false;
          this.itens = [];
          this.orders.forEach((order) => {
            order.items.forEach((item) => {
              this.itens.push(item)
            })
          })
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    formatDate(date) {
      return Utils.moment(date);
    },

    async getAddress(user) {
      if (user) {
        this.loading = true;
        await this.apiService
          .get(`client/get-addresses?id=` + user.id)
          .then((response) => {
            this.addresses = response;
            if (this.addresses.length != 0) {
              if (this.addressIdSelect) {
                this.endereco = this.addresses.find(
                  (e) => e.id === this.addressIdSelect
                );
              } else {
                this.endereco = this.addresses.find((e) => e.addressType === 0);
              }
            } else {
              this.goToRoute("/address");
            }

            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });

        this.loading = false;
      } else {
        this.loading = false;
        this.goToRoute("/login");
      }
    },

    async goToRoute(route) {
      if (this.$route.path != route) {
        await this.$router.push(route);
      }
    },

    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
  },
};
</script>
