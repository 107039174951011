import Layout from "../../internal/Layout.vue";
import InstallerPage from "./Installers.vue";
import SelectInstallerPage from "./SelectInstaller.vue";
import AddInstallerPage from "./AddInstaller.vue";
import DetailInstallerPage from "./DetailInstaller.vue";

const InstallerRoutes = {
  component: Layout,
  children: [
    {
      path: "/installers",
      name: "InstallerPage",
      component: InstallerPage,
      meta: {},
    },
    {
      path: "/select-installer",
      name: "SelectInstallerPage",
      component: SelectInstallerPage,
      meta: {},
    },
    {
      path: "/detail-installer",
      name: "DetailInstallerPage",
      component: DetailInstallerPage,
      meta: {},
    },
    {
      path: "/add-installer",
      name: "AddInstallerPage",
      component: AddInstallerPage,
      meta: {},
    },
  ],
};

export default InstallerRoutes;
