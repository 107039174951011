import Layout from "../../internal/Layout.vue";
import ProfilePage from "./Profile.vue";
import OrderPage from "./Order.vue";

const ProfileRoutes = {
  component: Layout,
  children: [
    {
      path: "/profile",
      name: "Profile",
      component: ProfilePage,
      meta: {},
    },
    {
      path: "/profile/order",
      name: "Order",
      component: OrderPage,
      meta: {},
    },
    
  ],
};

export default ProfileRoutes;
