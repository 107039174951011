import Layout from "../../internal/Layout.vue";
const Login = () => import("./Login/Login.vue");
const Register = () => import("./Register/Register.vue");
const ForgotPassword = () => import("./Forgotpassword/Forgotpassword.vue");

const LoginRoutes = {
  component: Layout,
  children: [
    {
      path: "",
      name: "LoginClient",
      component: Login,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    
    {
      path: "/forgotpassword",
      name: "ForgotPassword",
      component: ForgotPassword,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
      },
    },
    
    { path: "*", redirect: "/auth" },
  ],
};

export default LoginRoutes;
