<template>
  <v-main>
    <div class="max_width_container">
      <breadcrumbs class="my-2" :items="items"></breadcrumbs>
      <v-row cols="12" class="d-flex">
        <v-col class="filtes-content" cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
          <filters
              @product="searchForProductsByName"
              @selected-sizes="setSelectedSizes"
              @selected-subcategories="setSubcategories"
              @filter-by="setFilterBy"
              :title="title"
          >

          </filters>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" lg="9" xl="9">
          <description
              :description="subCategory.descriptionHtml"
          >
          </description>

          <product-pagination
              :page="page"
              :totalPages="totalPages"
              :totalItems="totalItems"
              :quantityProducts="products.length"
              @selected-page="setPage"
              @filter-by="setFilterBy"
          >
          </product-pagination>

          <div class="list-product-content" v-if="products && products.length > 0">
            <div v-for="item in products" class="ma-1" :key="item.productId">
              <ImageCard
                  class="image-card-hover"
                  :key="item.productId"
                  :item="item"
                  :env_url="env_url"
                  @action="goTo(item)"
              />
            </div>
          </div>

          <div v-else class="my-3">
            <h4>Nenhum resultado para subcategoria: {{this.title}}</h4>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-main>
</template>

<style scoped>
.image-card-hover:hover {
  box-shadow: 10px 11px 13px rgba(0, 0, 0, 0.25) !important;
}

.list-product-content {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(200px, auto));
}

.max_width_container {
  width: 80% !important;
  margin: 0 10% 0 10%;
}
@media (max-width: 1264px) {
  .max_width_container {
    width: 100%!important;
    margin: 0;
  }
}
</style>

<script>
import ApiService from "../../../services/ApiService";
import FormatParamsUrl from "../../../Utils/FormatParamsUrl";
import ImageCard from "../components/ImageCard.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Filters from "../components/Filters.vue";
import ProductPagination from "../components/ProductPagination.vue";

import Vue from "vue";
import Description from "../components/Description.vue";
import FormatText from "../../../Utils/FormatText";

export default {
  name: "Category",
  metaInfo() {
    return {
      meta: this.meta,
      title: this.title,
    }
  },
  components: {
    Description,
    ImageCard,
    Breadcrumbs,
    Filters,
    ProductPagination,
  },

  async mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, {passive: true});
    this.env_url = process.env.VUE_APP_BASE_URL;

    await this.updatePageView();
    this.$root.$on("rechargeSubcategory", async () => {
      await this.updatePageView();
    });
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, {passive: true});
    }
    this.$root.$off("rechargeSubcategory")
  },

  data: () => ({
    page: 1,
    totalPages: 15,
    totalItems: 0,
    componentKey: 1,
    isMobile: false,
    initalRange: 0,
    finalRange: 0,
    products: [],
    subCategory: {},
    selected: null,
    env_url: "",
    apiService: new ApiService(),
    title: "",
    meta: [],
    filter: {
      page: 1,
      pageSize: 20,
      productSubCategory: null,
      CategoryName: null,
      productIndicator: null,
      rangePrice: [0, 200],
      product: "",
      selectedSizes: [],
      Subcategories: [],
      orderByPrice: 0,
    },
    items: []
  }),


  computed: {},

  async created() {
    await this.getSubCategory()
    this.title = FormatText.capitalizeFirst(this.subCategory.name)
    this.items = [
      {
        text: "Página Inicial",
        disabled: true,
        href: "/home",
      },
      {
        text: "Subcategoria",
        disabled: true,
        href: "",
      },
      {
        text: this.title,
        disabled: false,
        href: "",
      },

    ]
    this.meta = [
      {
        vmid: 'description',
        name: 'description',
        content: this.subCategory.description,
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: this.subCategory.keyWords,
      },
    ]
  },

  watch: {
    pageSize() {
      this.filter.pageSize = this.pageSize;
      this.updatePageView();
    },

    page() {
      this.filter.page = this.page;
      this.updatePageView();
    },
    $route: {
      deep: true,
      async handler(to, from) {
        await this.getSubCategory()
        this.title = FormatText.capitalizeFirst(this.subCategory.name)
        this.items = [
          {
            text: "Página Inicial",
            disabled: true,
            href: "/home",
          },
          {
            text: "Subcategoria",
            disabled: true,
            href: "",
          },
          {
            text: this.title,
            disabled: false,
            href: "",
          },

        ]
        this.meta = [
          {
            vmid: 'description',
            name: 'description',
            content: this.subCategory.description,
          },
          {
            vmid: 'keywords',
            name: 'keywords',
            content: this.subCategory.keyWords,
          },
        ]
      },
    },
  },
  methods: {
    async getSubCategory() {
      const { name } = this.$route.params
      const subCategory = FormatParamsUrl.formatText(name);
      await this.apiService
          .get(`subcategory/by-name?name=${subCategory}`)
          .then((response) => {
            this.subCategory = response
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Erro listar as categorias",
              message: "Erro listar as categorias",
              trace: null,
            });

            this.loading = false;
          });
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    setPage(page) {
      this.page = page
      this.filter.page = this.page;
      this.updatePageView();
    },

    setFilterBy(filterBy) {
      this.filter.orderByPrice = filterBy;
      this.filter.page = this.page;
      this.updatePageView();
    },

    async searchForProductsByName(product) {
      this.filter.product = product
      this.filter.page = 1;
      this.page = 1
      await this.updatePageView();
    },

    async setSelectedSizes(selectedSizes) {
      this.filter.selectedSizes = selectedSizes
      this.filter.page = 1;
      this.page = 1
      await this.updatePageView();
    },
    async setSubcategories(selectedSubcategories) {
      this.filter.Subcategories = selectedSubcategories
      this.filter.page = 1;
      this.page = 1
      await this.updatePageView();
    },

    async updatePageView() {
      this.filter.productSubCategory = this.$route.params.name;
      await this.apiService
          .post(`product/listActiveB2C`, this.filter)
          .then((response) => {
            this.totalPages = response.pager.totalPages;
            this.totalItems = response.pager.totalItems;
            this.products = response.products;
          });
    },

    async resetFilter() {
      this.filter = {
        page: 1,
        pageSize: 15,
        productSubCategory: null,
        productIndicator: null,
        rangePrice: [0, 200],
      };
      await this.updatePageView();
    },

    async goTo(item) {
      const param = FormatParamsUrl.format(item.productName);
      await this.$router.push({
        path: `/produtos/${param}`,
        params: item,
      });
    },
  },
};
</script>
