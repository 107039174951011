<template>
  <v-card class="pa-4">
    <div class="d-flex justify-center mb-3">
      <h2 class="main-title">{{ title }}</h2>
    </div>

    <p class="main-description">
      {{ message }}
    </p>

    <v-row justify="space-around">
      <v-col cols="6" class="d-flex justify-center">
        <v-btn width="198" height="60" outlined @click="cancel" class="cancel-button"
          >Cancelar</v-btn
        >
      </v-col>

      <v-col cols="6" class="d-flex justify-center">
        <v-btn
          elevation="0"
          width="198"
          height="60"
          dark
          class="action-button"
          @click="action"
          :loading="loading"
          >{{ buttonText }}</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped>
.cancel-button {
  border-radius: 7.5px;
  transition: 0.3s;
}

.cancel-button:hover {
  color: white !important;
  background: #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.action-button {
  border-radius: 7.5px;
  transition: 0.3s;
  background: #bc0000 !important;
}

.action-button:hover {
  color: white !important;
  background: #e8161e !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.main-title {
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.15px;

  color: #232021;
}

.main-description {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */

  text-align: center;
  letter-spacing: 0.15px;

  color: #232021;
}
</style>

<script>
export default {
  name: "ConfirmExit",

  props: {
    title: String,
    loading: Boolean,
    message: String,
    buttonText: String,
  },

  data: () => ({}),

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    action() {
      this.$emit("action");
    },
  },
};
</script>
