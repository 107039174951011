<template>
  <div class="mt-2 mb-3">
    <div class="d-flex flex-wrap">
      <button @click="selectPreviousPage" v-if="page > 1 && totalPages > 1" class="next-page">
         <span class="desktop">
           Página anterior
         </span>
        <v-icon class="mobile" color="#2D3038" size="30">
          mdi-chevron-left
        </v-icon>
      </button>

      <div class="pages mx-2">
        <div v-for="item in 5" :key="item">
          <button
              :class="page === (indexPage + item) ? 'itens-pages-selected' : 'itens-pages'"
              v-if="item <= totalPages"
              @click="selectPages(indexPage + item)"
          >
            <a class="item"> {{ indexPage + item }}</a>
          </button>
        </div>
      </div>

      <button @click="selectNextPage" v-if="page < totalPages  && totalPages > 1" class="next-page">
         <span class="desktop">
           Próxima página
         </span>
        <v-icon class="mobile" color="#2D3038" size="30">
          mdi-chevron-right
        </v-icon>
      </button>
    </div>

    <div class="ml-1 my-6 d-flex justify-space-between align-center">
      <div class="itens-page">
        <p>
          Mostrando {{ (startPagination + 1).toString().padStart(2, '0') }}
          - {{ startPagination + quantityProducts }}
          de {{ totalItems }} itens
        </p>
      </div>
      <div class="itens-page desktop">
        <p>
          Filtrar por
        </p>
        <div class="ml-2">
          <v-select
              @change="setFilterBy()"
              v-model="filterBy"
              style="border-radius: 4px;
              border: 1px solid #D9D9D9;
              background: #FFF;
              height: 42px;
              max-width: 200px;
              margin-top: -10px;"
              :items="items"
              outlined
              dense
              item-text="text"
              item-value="value"
              placeholder="Filtrar por"
          >
          </v-select>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pages {
  display: flex !important;
}

.itens-page {
  color: #2D3038 !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.itens-pages {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background: #F2F2F2;
  margin: 5px;
  display: flex;
  width: 50px;
  height: 35px;
  align-items: center;
  justify-content: center;
}

.itens-pages:hover {
  border: 1px solid #BC0000;
  background: #BC0000;
}

.item {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #4F4F4F !important;
}

.itens-pages:hover > .item {
  color: #FFFFFF !important;
}

.next-page {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  background: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #4F4F4F;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  height: 45px;
}

.next-page:hover {
  border: 1px solid #BC0000;
  background: #BC0000;
  color: #FFF;
}

.itens-pages-selected {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #BC0000;
  background: #BC0000;
  margin: 5px;
  display: flex;
  width: 50px;
  height: 35px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.itens-pages-selected > .item {
  color: #FFF !important;
}

.inactive {
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.desktop {
  display: flex;
}

.mobile {
  display: none;
}

@media (max-width: 1264px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }
}
</style>

<script>

export default {
  name: "ProductPagination",
  components: {},

  props: {
    page: {default: 1, type: Number},
    totalPages: {default: 1, type: Number},
    totalItems: {default: 0, type: Number},
    quantityProducts: {default: 0, type: Number},
  },

  data: () => ({
    indexPage: 0,
    filterBy: null,
    items: [
      {
        text: 'Preço (ascend)',
        value: 1,
      },
      {
        text: 'Preço (descend..)',
        value: 2,
      },
    ],
  }),

  computed: {
    startPagination() {
      return Math.ceil((this.page - 1) * this.quantityProducts)
    }
  },

  async created() {
  },

  methods: {
    pages() {

    },
    selectPreviousPage() {
      if (this.page > 0 && this.indexPage > 0) {
        this.indexPage--
      }
      this.selectPages(this.page - 1)

    },
    selectNextPage() {
      if ((5 + this.indexPage) < this.totalPages) {
        this.indexPage++
      }
      this.selectPages(this.page + 1)
    },

    selectPages(page) {
      this.$emit('selected-page', page)
    },
    setFilterBy() {
      this.$emit('filter-by', this.filterBy)
    },
  },
};
</script>
