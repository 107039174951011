import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{staticClass:"mainCheckout",staticStyle:{"padding":"15px","text-align":"-webkit-center"},attrs:{"id":"mainCheckout"}},[_c(VCard,{staticClass:"cards ma-0 pa-0 py-9 my-9"},[_c(VIcon,{staticClass:"py-2",attrs:{"x-large":"","color":"normalred"}},[_vm._v(" mdi-alert-circle")]),_c('div',{staticClass:"d-flex justify-center py-2"},[_c('h2',{staticClass:"main-title"},[_vm._v("Pagamento recusado")])]),_c('div',{staticClass:"main-description py-3 px-5"},[_vm._v(" Seu pagamento foi recusado, ajuste sua compra para alterar a forma de pagamento. ")]),_c(VRow,{attrs:{"justify":"space-around"}},[_c(VCol,{staticClass:"d-flex justify-center"},[_c(VBtn,{staticClass:"primary-button",on:{"click":function($event){return _vm.goToRoute('/checkout')}}},[_vm._v("Alterar forma de pagamento")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }