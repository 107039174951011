import Layout from "../../internal/Layout.vue";
import CommonQuestions from "./CommonQuestions.vue";

const CommonQuestionsRoutes = {
  component: Layout,
  children: [
    {
      path: "/perguntas-frequentes",
      name: "CommonQuestions",
      component: CommonQuestions,
      meta: {},
    },
  ],
};

export default CommonQuestionsRoutes;
