import Vue from "vue";
import moment from "moment";
import Router from "vue-router";
import AuthRoutes from "./views/pages/Auth/routes";
import HomeRoutes from "./views/pages/Home/routes";
import ProductRoutes from "./views/pages/Product/routes";
import ProfileRoutes from "./views/pages/Profile/routes";
import CartRoutes from "./views/pages/Cart/routes";
import CheckoutRoutes from "./views/pages/Checkout/routes";
import InstallerRoutes from "./views/pages/Installer/routes";
import CommonQuestionsRoutes from "./views/pages/CommonQuestions/routes";
import PlatformPolicies from "./views/pages/PlatformPolicies/routes";
import SubCategoryRoutes from "./views/pages/SubCategory/routes";
import CategoryRoutes from "./views/pages/Category/routes";

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",

  mode: "history",
  routes: [
    {
      path: "/home",
      ...HomeRoutes,
    },
    {
      path: "/auth",
      ...AuthRoutes,
    },
    {
      path: "/product",
      ...ProductRoutes,
    },
    {
      path: "/subcategorias/:name",
      ...SubCategoryRoutes,
    },
    {
      path: "/categorias/:name",
      ...CategoryRoutes,
    },
    {
      path: "/cart",
      ...CartRoutes,
    },
    {
      path: "/profile",
      ...ProfileRoutes,
    },
    {
      path: "/checkout",
      ...CheckoutRoutes,
    },
    {
      path: "/installer",
      ...InstallerRoutes,
    },
    {
      path: "/perguntas-frequentes",
      ...CommonQuestionsRoutes,
    },
    {
      path: "/politicas/:name",
      ...PlatformPolicies,
    },
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "*",
      component: () => import("@/views/pages/Index"),
      children: [
        {
          name: "404 Error",
          path: "",
          component: () => import("@/views/pages/Error"),
        },
      ],
    },
  ],
});

// router.beforeEach(async (to, from, next) => {
//   let token = localStorage.getItem("token");
//   let expiresToken = localStorage.getItem("expires");
//   let user = JSON.parse(localStorage.getItem("user"));

//   if (to.meta.requiresAuth) {
//     if (token) {
//       if (moment().isBefore(moment(expiresToken))) {
//         if (
//           (user.type == 0 && to.meta.requiresAdmin) ||
//           !to.meta.requiresAdmin
//         ) {
//           next();
//         }
//       } else {
//         next("/auth");
//       }
//     } else {
//       next("/auth");
//     }
//   } else {
//     if (to.path === "/") {
//       next("/auth");
//     } else {
//       next();
//     }
//   }
// });

export default router;
