import Layout from "../../internal/Layout.vue";
import PlatformPolicies from "./PlatformPolicies.vue";

const PlatformPoliciesRoutes = {
  component: Layout,
  children: [
    {
      path: "/politicas/:name",
      name: "PlatformPolicies",
      component: PlatformPolicies,
      meta: {},
    },
  ],
};

export default PlatformPoliciesRoutes;
