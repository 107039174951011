import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-auto px-3",staticStyle:{"max-width":"1785px","height":"100vh"}},[_c(VCard,[_c(VRow,{staticClass:"ma-0",staticStyle:{"width":"100%"}},[_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"2","md":"2"}},[_c(VImg,{attrs:{"width":"200px","src":_vm.installer.image != null && _vm.installer.image != ''
              ? _vm.installer.image
              : require('../../../assets/imprimax_logo.png')}})],1),_c(VCol,{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('div',[_c('b',{staticStyle:{"font-size":"16px"}},[_vm._v(" Nome: ")]),_vm._v(" "+_vm._s(_vm.installer.name)+" ")]),_c('div',[_c('b',{staticStyle:{"font-size":"16px"}},[_vm._v(" Numero 1: ")]),_vm._v(" "+_vm._s(_vm.installer.phoneNumber)+" ")]),_c('div',[_c('b',{staticStyle:{"font-size":"16px"}},[_vm._v(" Numero 2: ")]),_vm._v(" "+_vm._s(_vm.installer.phoneNumber2)+" ")])]),_c(VCol,[_c('div',[_c('span',{staticStyle:{"font-size":"16px"}},[_c('b',{staticStyle:{"font-size":"16px"}},[_vm._v("Cidade:")]),_vm._v(" "+_vm._s(_vm.installer.city)+", "+_vm._s(_vm.installer.state)+" ")])]),_c('b',{staticStyle:{"font-size":"16px"}},[_vm._v(" Especialidades")]),_vm._l((_vm.installer.specialties),function(item,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(item.specialties.name)+" ")])})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }