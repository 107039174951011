<template>
  <main class="d-flex justify-center">
    <v-card class="mx-1 pa-5" width="90%">
      <v-row cols="12" class="pt-3 mx-1">
        <v-col
            xs="12"
            sm="12"
            md="8"
            lg="8"
            xl="8"
            class="pa-0 ma-0 pr-2"
        >
          <v-text-field
              outlined
              placeholder="Pesquisar perguntar frequentes"
              v-model="filter.search"
              :loading="loading"
              dense
              height="40"
          >
          </v-text-field>
        </v-col>

        <v-col
            xs="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
            class="pa-0 ma-0 d-flex justify-space-between"
        >
          <v-btn
              outlined
              tile
              width="49%"
              style="border-radius: 4px; font-size: 14px"
              small
              height="40"

              @click="list()"
          >
            <div class="d-flex align-content-start justify-space-between">
              <v-icon color="#bc0000" size="25">mdi-magnify</v-icon>
              <p class="mt-3 ml-5">Pesquisar</p>
            </div>
          </v-btn>

          <v-btn
              outlined
              tile
              style="border-radius: 6px; font-size: 14px"
              small
              height="40"
              width="49%"
              @click="reset()"
          >
            <div class="d-flex align-content-center justify-space-between">
              <v-icon color="#bc0000" size="25">mdi-refresh</v-icon>
              <p class="mt-3 ml-5">Limpar</p>
            </div>
          </v-btn>
        </v-col>
      </v-row>

      <v-row  cols="12">
        <v-col  cols="12">
          <v-expansion-panels
              class="elevation-0"
              v-model="panel"
              multiple
          >
            <v-expansion-panel
                v-for="(item,i) in commonQuestions"
                :key="i"
                class="mb-1"
            >
              <v-expansion-panel-header
                  class="question"
                  color="#bc0000"
              >
                {{ item.question }}
                <template v-slot:actions>
                  <v-icon
                      color="#FFFFFF"
                      size="25"
                  >
                    mdi-chevron-up
                  </v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content color="pt-2">
                {{ item.answer }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row cols="12" >
        <v-col cols="12" v-if="totalItems > commonQuestions.length">
          <v-pagination
              class="mt-2"
              color="black"
              v-model="filter.page"
              :length="filter.totalPages"
              :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </main>
</template>

<script lang="js">
import ApiService from '../../../services/ApiService';
import Vue from "vue";

export default {
  name: "CommonQuestions",
  components: {},
  created() {

  },
  async mounted() {
    try {
      await this.list()
    } catch (e) {

    }
  },
  data() {
    return {
      loading: false,
      panel: [],
      apiService: new ApiService(),
      filter: {
        search: '',
        pageSize: 15,
        page: 1,
        totalPages: 1,
      },
      commonQuestions: [],
      totalItems: 0,
      currentCommonQuestions: null,
      deleteDialog: false,
      status: [
        {label: "Inativo", value: false},
        {label: "Ativo", value: true},
      ],
    }
  },

  methods: {
    async goToRoute() {
      await this.$router.go(-1);
    },
    async reset() {
      this.filter.search = '';
      this.filter.pageSize = 15;
      this.filter.page = 1;
      this.filter.totalPages = 1;
      await this.list()
    },

    async list() {
      this.loading = true;
      this.apiService.post('commonQuestions/all', this.filter).then((response) => {
        const {content} = response
        this.commonQuestions = content.commonQuestions
        this.filter.totalPages = content.pager.totalPages
        this.totalItems = content.pager.totalItems
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
  },

  watch: {
    "filter.page": {
      deep: true,
      async handler() {
        await this.list()
      }
    },
  }
};
</script>

<style scoped>
.question {
  color: #FFFFFF;
  font-size: 16px;
}

</style>
