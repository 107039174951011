<template>
  <div>
    <div class="hidden-sm-and-down">
      <v-card class="card pa-5 mx-auto" elevation="2">
        <div class="d-flex align-center">
          <v-img height="40px" contain src="../../../assets/logo-imprimax.svg"></v-img>
        </div>
        <div class="py-5 my-5 mx-2 px-1 d-flex flex-column justify-center align-center">
          <span class="label py-2" style="text-align: -webkit-center">
            {{ textMessage }}
          </span>
          <span class="label observation py-6" style="text-align: -webkit-center">
            {{ textObservation1 }}
          </span>
          <span class="label observation py-">
            {{ textObservation2 }}
          </span>
        </div>

        <div>
          <v-row class="d-flex ma-0 pa-0">
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                large
                class="cancelBtn black--text"
                @click="close"
                :loading="loading"
              >
                <span>{{ cancelBtn }}</span>
              </v-btn>

              <v-btn
                elevation="4"
                large
                class="okBtn white--text"
                @click="action"
                :loading="loading"
              >
                <span>{{ okbtn }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>

    <div class="hidden-md-and-up">
      <v-card class="card pa-0 mx-auto" elevation="2">
        <div class="py-6 d-flex align-center">
          <v-img height="20px" contain src="../../../assets/logo-imprimax.svg"></v-img>
        </div>
        <div class="py-6 my-3 mx-2 px-1 d-flex flex-column justify-center align-center">
          <span class="label py-2" style="font-size: 1.5rem">
            {{ textMessage }}
          </span>
          <span class="label observation py-3" style="font-size: 1.1rem">
            {{ textObservation1 }}
          </span>
          <span class="label observation py-1">
            {{ textObservation2 }}
          </span>
        </div>

        <div>
          <v-row class="d-flex ma-0 pa-0">
            <v-col cols="12" class="justify-center">
              <v-btn
                elevation="0"
                large
                block
                class="cancelBtn black--text"
                @click="close"
                :loading="loading"
              >
                <span>{{ cancelBtn }}</span>
              </v-btn>

              <v-btn
                elevation="0"
                block
                large
                class="okBtn white--text"
                @click="action"
                :loading="loading"
              >
                <span>{{ okbtn }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.card {
  background: #ffffff !important;
  border: 1px solid #dadada;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 7px !important;
}

.okBtn {
  transition: 0.3s;
  font-weight: bold !important;
  box-sizing: border-box !important;
  background: #bc0000 !important;
  width: 200px;

  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #ffffff;
  border-radius: 10px !important;
}

.okBtn:hover {
  color: #fff !important;
  background: #e8161e !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2) !important;
}

.cancelBtn {
  transition: 0.3s;
  font-weight: bold !important;
  background-color: #ffffff !important;
  border: 1.5px solid #2d3038 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

.cancelBtn:hover {
  color: #fff !important;
  background: #000000 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 29px;
  text-align: justify;
  letter-spacing: 0.15px;

  color: #bc0000;
}

.observation {
  font-family: Roboto;
  font-style: normal;
  color: grey;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  text-align: justify;
  letter-spacing: 0.15px;

  color: #232021;
}

@media (max-width: 600px) {
  .select-type {
    width: 50% !important;
  }
}

@media (max-width: 600px) {
  .form {
    flex-direction: column;
  }
}
</style>

<script>
export default {
  name: "ConfirmModal",

  props: {
    cancelBtn: String,
    filter: Object,
    title: String,
    textMessage: String,
    okbtn: String,
    textObservation1: String,
    textObservation2: String,
  },

  data: () => ({
    loading: false,
    excelDialog: "",
    resale: Object,
  }),

  methods: {
    close() {
      this.$emit("close");
    },

    action() {
      this.$emit("action");
    },
  },
};
</script>
