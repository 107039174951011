<template>
  <div class="mainAdress" style="text-align: -webkit-center; ">
    <v-dialog v-model="confirmExitDialog" max-width="480">
      <ConfirmExit
          title="Atenção"
          message="Você pode perder suas alterações, tem certeza de que deseja voltar?"
          buttonText="Confirmar"
          @cancel="confirmExitDialog = false"
          @action="$router.go(-1)"
      />
    </v-dialog>

    <v-card class="pa-8 pt-4"
            style="width: max-content; text-align: start; box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1)!important;">
      <div style="display: inline-grid">
        <span class="main-title pb-2">Endereço </span>

        <span class="label">
          Cadastre seu endereço corretamente para que consiga receber suas encomendas.
        </span>
      </div>

      <!-- Campo de CEP -->
      <v-form class="form" ref="firstForm" v-model="firstValid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <span><b>CEP</b></span>

            <span style="color: #bc0000">*</span>
            <span class="label"> (informe seu CEP para preenchimento automático ou </span>

            <span style="color: #bc0000" @click="typeManually = true">
              <strong class="type-manually"> Digite Manualmente </strong>
              )
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mx-0" cols="6" sm="3" md="3" lg="3">
            <v-text-field
                placeholder="00000-000"
                outlined
                hide-details
                class="inputCep"
                :rules="[rules.postalCodeRules]"
                v-model="currentClient.postalCode"
                :key="componentKey"
                v-on:keyup="searchCEP()"
                v-mask="this.isEditing ? cepMask : '#####-###'"
            ></v-text-field>
          </v-col>

          <a
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
              class="pl-2"
              style="align-self: center; color: #bc0000"
          >
            <strong> Não sei meu CEP </strong>
          </a>

          <p v-show="naoLocalizado" style="display: none; background-color: #f1948a">
            <strong>CEP não localizado.</strong> Por gentileza entre manualmente.
          </p>
        </v-row>
        <v-row>
          <v-col cols="4" sm="6" md="4">
            <span><b>Estado*</b></span>
            <v-select
                outlined
                :disabled="!typeManually"
                :key="componentKey"
                class="primary-inputs"
                hide-details
                :rules="[rules.required]"
                :items="estados"
                v-model="currentClient.state"
                color="secondary"
                id="mb-8"
                @change="getCities()"
                required
            ></v-select>
          </v-col>

          <v-col cols="8" sm="6" md="4">
            <span><b>Cidade*</b></span>
            <v-select
                outlined
                :disabled="!typeManually"
                :key="componentKey"
                class="primary-inputs"
                hide-details
                :rules="[rules.required]"
                :items="cities"
                v-model="currentClient.city"
                color="secondary"
                id="inputCidade"
                type="text"
                required
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <span><b>Bairro*</b></span>
            <v-text-field
                hide-details
                :key="componentKey"
                outlined
                class="primary-inputs"
                :rules="[rules.required]"
                v-model="currentClient.neighborhood"
                id="inputBairro"
                color="secondary"
                type="text"
                required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="4">
            <span><b>Logradouro*</b></span>
            <v-text-field
                hide-details
                outlined
                :key="componentKey"
                class="primary-inputs"
                :rules="[rules.required]"
                color="secondary"
                v-model="currentClient.addressName"
                id="inputLogradouro"
                type="text"
                required
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="6" md="4">
            <span><b>Número*</b></span>
            <v-text-field
                hide-details
                :disabled="noNumber"
                outlined
                v-model="currentClient.addressNumber"
                class="primary-inputs"
                color="secondary"
                id="AddressNumber"
                type="number"
                required
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="6" md="4">
            <v-checkbox
                dense
                outlined
                class="black--text"
                v-model="currentClient.addressNumber"
                @click="noNumber = !noNumber"
                value="Sem número"
            ></v-checkbox>
            <span class="label mt-4"> Sem número </span>
          </v-col>
          <v-col cols="12" sm="12">
            <span><b>Complemento</b></span>
            <v-text-field
                placeholder="Vila,condomínio,apt,fundos,..."
                hide-details
                outlined
                class="primary-inputs"
                :rules="[rules.required]"
                v-model="currentClient.addressComplement"
                type="text"
                required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <span><b>Nome do endereço*</b></span>
            <v-text-field
                placeholder="Casa, trabalho,...."
                hide-details
                outlined
                class="primary-inputs"
                :rules="[rules.required]"
                v-model="currentClient.addressNickName"
                type="text"
                @keydown.enter="submit()"
                required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <v-row justify="center" class="py-4" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <v-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5">
          <v-btn block :loading="loading" class="secondary-button" @click="back()">
            Voltar
          </v-btn>
        </v-col>

        <v-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5">
          <v-btn block :loading="loading" class="primary-button" @click="submit()">
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import ApiService from "../../../services/ApiService";
import ConfirmExit from "../../pages/components/ConfirmExit.vue";
import estadosBrasil from "../../../assets/estadosBrasil.json";
import axios from "axios";
import Vue from "vue";

export default {
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, {passive: true});
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, {passive: true});
  },

  data: () => ({
    currentClient: [],
    uf: "",
    estados: [],
    cities: [],
    noNumber: false,
    typeManually: false,
    confirmExitDialog: false,
    selected: false,
    isMobile: false,
    firstValid: true,
    cepMask: "",
    componentKey: 1,
    loading: false,
    postalCode: "",
    isEditing: false,
    naoLocalizado: false,
    apiService: new ApiService(),
    rules: {
      postalCodeRules: (v) =>
          (v && v.length <= 10) || "CEP deve ter no máximo 10 caracteres",
      required: (value) => !!value || "Campo requerido.",
    },
  }),

  components: {ConfirmExit},

  computed: {},
  async created() {
    this.lastProductId = this.$route.query.productId;

    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.$route.query.id) {
      this.isEditing = true;
      await this.getOne(this.$route.query.id);
    }
    if (this.$route.query.cep) {
      this.currentClient.postalCode = this.$route.query.cep;
      await this.searchCEP();
    }

    this.getEstados();
  },

  methods: {
    async getLocation() {
      let address = [
        this.currentClient.addressNumber,
        this.currentClient.addressName,
        this.currentClient.state,
        this.currentClient.city,
      ].join(" ");
      await axios
          .get("https://maps.googleapis.com/maps/api/geocode/json", {
            params: {
              address: address,
              key: process.env.VUE_APP_APIKEY,
            },
          })
          .then((response) => {
            let result = response.data.results[0].geometry.location;

            this.location = {
              latitude: parseFloat(result.lat),
              longitude: parseFloat(result.lng),
              place_id: response.data.results[0].place_id,
            };
            this.currentClient.latitude = this.location.latitude;
            this.currentClient.longitude = this.location.longitude;
          })
          .catch((erro) => {
          });
    },

    async getCities() {
      if (this.currentClient.state) {
        this.uf = estadosBrasil.find((e) => e.sigla === this.currentClient.state).id;
      }
      var client = new ApiService();
      await client
          .get(`user/get-cities?uf=${this.uf}`)
          .then((response) => {
            var model = JSON.parse(response);
            this.cities = [];
            model.forEach((cidade) => {
              this.cities.push(cidade.nome);
            });

            model.sort((a, b) => a.localeCompare(b));
          })
          .catch((err) => {
          });
    },

    getEstados() {
      var estados = [];

      estadosBrasil.forEach((estado) => {
        estados.push(estado.sigla);
      });

      this.estados = estados;

      return this.estados;
    },
    back() {
      this.confirmExitDialog = true;
    },

    async getOne(arg) {
      await this.apiService.get(`client/get-one/` + arg).then((response) => {
        this.currentClient = response[0];
        this.getCities();
      });
    },

    goToRoute(route) {
      if (this.$route.path != route) {
        this.$router.push(route);
      }
    },

    async submit(tipo) {
      this.loading = true;

      await this.getLocation();

      if (this.$refs.firstForm.validate()) {
        const client = {
          ClientId: this.user.id,
          Id: this.$route.query.id ? this.$route.query.id : null,
          PostalCode: this.currentClient.postalCode,
          AddressName: this.currentClient.addressName,
          Country: this.currentClient.country,
          State: this.currentClient.state,
          City: this.currentClient.city,
          Neighborhood: this.currentClient.neighborhood,
          AddressNumber: parseInt(this.currentClient.addressNumber),
          AddressComplement: this.currentClient.addressComplement,
          AddressNickName: this.currentClient.addressNickName,
          Latitude: this.currentClient.latitude,
          Longitude: this.currentClient.longitude,
        };

        let url = "client/add-address";
        let titleSucess = "Endereço cadastrado com sucesso! ";
        let titleError = "Erro ao cadastrar este endereço.";
        if (this.$route.query.id) {
          url = "client/edit-address";
          titleSucess = "Endereço alterado com sucesso!";
          titleError = "Erro ao alterar este endereço. ";
        }

        const api = new ApiService();
        await api
            .post(url, client)
            .then((resp) => {
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "success",
                title: titleSucess,
                message: titleSucess,
                trace: null,
              });
              this.$router.go(-1);
              this.loading = false;
            })
            .catch((err) => {
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "error",
                title: titleError,
                message: titleError,
                trace: null,
              });
              this.loading = false;
            });
      } else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Não validado",
          message: "Preencha os campos corretamente.",
          trace: null,
        });
        this.loading = false;
      }

      this.loading = false;
    },


    async searchCEP() {
      this.cepMask = "#####-###";

      if (/^[0-9]{5}-[0-9]{3}$/.test(this.currentClient.postalCode)) {
        this.naoLocalizado = false;
        this.location = {};
        var client = new ApiService();
        await client
            .get(`user/postalcode?cep=${this.currentClient.postalCode}`)
            .then((response) => {
              const location = JSON.parse(response);
              if (location.erro) {
                this.location = {};
                this.naoLocalizado = true;
                this.currentClient.neighborhood = "";
                this.currentClient.address = "";
                this.currentClient.city = "";
                this.currentClient.state = "";
                this.currentClient.addressNumber = "";
                this.currentClient.country = "Brasil";
                this.currentClient.addressName = "";
                this.componentKey++;
              }

              this.currentClient.neighborhood = location.bairro;
              this.currentClient.addressName = location.logradouro;
              this.currentClient.city = location.localidade;
              this.currentClient.state = location.uf;
              this.currentClient.country = "Brasil";
              this.componentKey++;

              if (document.getElementById("AddressNumber")) {
                this.currentClient.addressNumber = "";
                document.getElementById("AddressNumber").focus();
              }
              this.getCities();
              return;
            });
      }
    },

    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
  },
};
</script>

<style scoped>
.type-manually {
  cursor: pointer;
  font-weight: bold;
  color: #bc0000;
  border-bottom: 1px solid #bc0000;
}

.inputCep {
  border-radius: var(--default-input-border-radius) !important;
}

.primary-inputs {
  color: var(--primary-input-font-color) !important;
  border-radius: var(--default-input-border-radius) !important;
  align-self: center !important;
}

.v-card__title {
  padding: 0px;
  font-family: Poppins;
  width: max-content;
  color: #bc0000;
  font-style: normal;
  font-weight: bold;
  line-height: 27px;
}

.v-card__text {
  padding: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
}

.containerList {
  justify-content: center !important;
  flex-direction: column;
}

.container {
  display: flex;
  overflow-x: auto;
}
</style>
