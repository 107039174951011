import Layout from "../../internal/Layout.vue";
import HomePage from "./Home.vue";

const HomeRoutes = {
  component: Layout,
  children: [
    {
      path: "/home",
      name: "Home",
      component: HomePage,
      meta: {},
    },
  ],
};

export default HomeRoutes;
