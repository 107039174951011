import Vue from "vue";
import VueResource from "vue-resource";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import "./plugins/vue-world-map";
import vuetify from "./plugins/vuetify";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib"; //Globally import VTextField
import i18n from "./i18n";
import { VueMaskDirective } from "v-mask";
//import CxltToastr from "cxlt-vue2-toastr";
//import "cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css";
import FlashMessage from "@smartweb/vue-flash-message";
import css from "./styles/variables.json";
import "./styles/styles.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Meta from 'vue-meta'
Vue.config.productionTip = false;

Vue.prototype.$customStyles = css;

Vue.directive("mask", VueMaskDirective);
Vue.use(FlashMessage);
Vue.use(vuetify);
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

Vue.$globalEvent = new Vue();
const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  icon: true,
};

Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
  locale: "pt-BR",
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});

Vue.use(Toast, options);
/*const toastrConfigs = {
  position: "top right",
  showDuration: 1000,
  timeOut: 6000,
  closeButton: true,
  progressBar: true,
};
Vue.use(CxltToastr, toastrConfigs);*/

Vue.use(VueResource);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  mounted() {
    Vue.$globalEvent.$on("onFlashMessage", (item) => {
      switch (item.type) {
        case "success":
          this.$toast.success(item.message);
          break;
        case "error":
          this.$toast.error(item.message);
          break;
        case "warning":
          this.$toast.warning(item.message);
          break;
        case "info":
          this.$toast.info(item.message);
          break;
      }
    });

    Vue.$globalEvent.$on("httpError", (error) => {
      if (error) {
        if (error.status) {
          if (error.showException && error.status === 401) {
            localStorage.clear();
            if (error.body && error.body.detail) {
              this.flashMessage.error({ title: error.body.detail });
            } else {
              this.flashMessage.error({ title: this.$t("unauthorized") });
            }
            //router.push({ name: "/auth" });
          } else if (error.showException && error.status === 403) {
            this.flashMessage.error({ title: this.$t("unauthorized") });
            router.push({ name: "/auth" });
          } else if (
            error.showException &&
            error.status === 412 &&
            error.body &&
            error.body.detail &&
            error.body.detail.toLowerCase().indexOf("expired") > -1
          ) {
            this.flashMessage.error({ title: this.t("session_expired") });
            router.push({ name: "/auth" });
          } else if (error.showException && error.status >= 500) {
            this.flashMessage.error({
              title: this.$t("error"),
              message: error.message,
            });
          }
        } else {
          if (error.status === 0) {
            this.flashMessage.error({
              title: this.$t("error"),
              message: error,
            });
            return;
          } else {
            this.flashMessage.error({
              title: this.$t("error"),
              message: error,
            });
          }
        }
      }
    });
  },
  render: (h) => h(App),
}).$mount("#app");
