<template>
  <v-main class="mainProduct">
    <div id="product">
      <v-breadcrumbs style="margin-left: -15px; margin-bottom: -20px" :items="items">
        <template v-slot:divider>
          <v-icon style="margin: 0 -15px 0 -12px !important">mdi-chevron-right</v-icon >
        </template>

        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            <span
              class="item-breadcrumbs"
              :style="item.href ? 'color: #BC0000; font-weight: 500;' : ''"
            >
              {{ item.text }}</span
            >
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <!-- Mobile Version CardComponent -->
    <div v-if="isMobile && product">
      <Details2ProductMobile
        :productId="product.productId"
        @close="detailsDialog = false"
        @go-to="goTo"
        :key="dialogKey"
      />
    </div>

    <!-- Desktop Version CardComponent -->
    <div v-if="!isMobile && product">
      <Details2ProductDesktop
        :productId="product.productId"
        @close="detailsDialog = false"
        :key="dialogKey"
         @go-to="goTo"
      />
    </div>

    <v-btn
      v-if="false"
      v-scroll="onScroll"
      v-show="fab"
      fab
      depressed
      fixed
      bottom
      style="z-index: 10; right: 4%"
      @click="$vuetify.goTo(0)"
    >
      <v-icon large>mdi-chevron-up</v-icon>
    </v-btn>
  </v-main>
</template>

<script>
import ApiService from "../../../services/ApiService";
import Details2ProductDesktop from "../components/Details2ProductDesktop.vue";
import Details2ProductMobile from "../components/Details2ProductMobile.vue";
import Vue from "vue";
import FormatParamsUrl from "../../../Utils/FormatParamsUrl";
import FormatText from "../../../Utils/FormatText";

export default {
  name: "Products",

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.$vuetify.goTo(0);
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  data: () => ({
    cart: [],
    user: {},
    dialogKey: false,
    currentProduct: {},
    isMobile: false,
    products: [],
    apiService: new ApiService(),
    product: null,
    items: [],
    fab: false,
  }),

  components: {
    Details2ProductDesktop,
    Details2ProductMobile,
  },

  computed: {},
  watch: {},

  async created() {
    this.user = JSON.parse(localStorage.getItem("user"));

    // pick product by id
    await this.getProduct();
    // update localstorage cart
    this.viewCart();

    // localStorage.removeItem("cartUser");
    // localStorage.removeItem("cartBadge");
  },

  methods: {
    async goTo(productName) {
      await this.$router.push({
        path: `/produtos/${productName}`,
      });
      await this.getProduct();
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },

    viewCart() {
      const cartFromLocalStorage = JSON.parse(
        localStorage.getItem("cartUser") || "[]"
      );
      if (cartFromLocalStorage) {
        this.cart = cartFromLocalStorage;
        this.badge = this.cart.reduce((total, item) => {
          return total + item.qty;
        }, 0);

        this.totalprice = this.cart.reduce((total, item) => {
          return total + item.qty * item.price;
        }, 0);
      }
      //Option1- Evento Global

      this.$root.$emit("changeBadge", this.badge);
      //Option2- Coloca o badge no localhost para o navigation pegar
      localStorage.setItem("cartBadge", JSON.stringify(this.badge));
    },

    onResize() {
      this.isMobile = window.innerWidth < 1000;
    },

    async getProduct() {
      this.loading = true;

      await this.apiService
        .get(`product/get-one-by-name?productName=${this.$route.params.name}`)
        .then((response) => {
          this.product = response;
          (this.items = [
            {
              text: "Home",
              disabled: false,
              href: "/home",
            },
            {
              text: "produtos",
              disabled: false,
              href: "/produtos",
            },
            {
              text: FormatText.capitalizeFirst(this.product.productName),
              disabled: false,
              href: "",
            },
          ]),
            (this.loading = false);
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Erro ao buscar produtos",
            message: err.body.message,
            trace: null,
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.isDesktop {
  display: block;
}
.isMobile {
  display: none;
}
#product{
  width: 80%!important;
  margin: 0 auto!important;
}
@media (max-width: 1000px) {
  .isDesktop {
    display: none;
  }
  .isMobile {
    display: block;
  }
  #product{
    width: 95%!important;
    margin: 0 auto!important;
  }
}

.item-breadcrumbs {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: #000000;
  margin-left: -8px;
}
.v-main__wrap {
  height: fit-content !important;
}
.mainProduct {
  padding-top: 0px !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.v-card__title {
  padding: 0px;
  font-family: Poppins;
  width: max-content;
  color: #bc0000;
  font-style: normal;
  font-weight: bold;
  line-height: 27px;
}

.v-card__text {
  padding: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
}
.containerList {
  justify-content: center !important;
  flex-direction: column;
}
.container {
  display: flex;
  overflow-x: auto;
}

.v-carousel {
  height: 25% !important;
  width: 100% !important;
}

.banner-text {
  text-align: center;
  color: #ffffff;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.15px;
}
.banner-text-destac {
  color: #fede04;
}
</style>
