import Layout from "../../internal/Layout.vue";
import CategoryPage from "./SubCategory.vue";

const SubCategoryRoutes = {
  component: Layout,
  children: [
    {
      path: "/subcategorias/:name",
      name: "SubCategory",
      component: CategoryPage,
      meta: {},
    },
  ],
};

export default SubCategoryRoutes;
