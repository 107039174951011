<template>
  <div>
    <v-dialog v-model="filterMobile">
      <v-card color="#F5F5F5" min-height="200" class="pa-5">
        <v-card-title class="pa-0 ma-0"><strong>Selecionar filtros</strong></v-card-title>
        <v-divider></v-divider>

        <div class="filter mt-2">
          <div>
            <div class="pa-0 ma-0 d-flex align-center justify-space-between pointer"
                 @click="showFiltersItemsBySize = !showFiltersItemsBySize">
              <p class="name-filter ml-1">Tamanho</p>
              <div class="mb-5">
                <v-icon v-if="showFiltersItemsBySize" color="#2D3038" size="30">
                  mdi-minus
                </v-icon>

                <v-icon v-else color="#2D3038" size="30">
                  mdi-plus
                </v-icon>
              </div>
            </div>

            <div v-if="showFiltersItemsBySize" class="pa-0 ma-0 d-flex flex-column align-start ml-1">
              <v-checkbox
                  v-for="item in attributeGroups" :key="item.name"
                  class="ma-0 pa-0 "
                  v-model="selectedSizes"
                  color="#BC0000"
                  :value="item.name"
                  @change="setSelectedSizes()"
              >
                <template v-slot:label>
                  <span id="checkboxLabelMobile">{{ item.name }}</span>
                </template>
              </v-checkbox>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="mt-3">
            <div class="pa-0 ma-0 d-flex align-center justify-space-between pointer"
                 @click="showFiltersItemsByColor = !showFiltersItemsByColor">
              <p class="name-filter ml-1">Cor</p>
              <div class="mb-5">
                <v-icon v-if="showFiltersItemsByColor" color="#2D3038" size="30">
                  mdi-minus
                </v-icon>

                <v-icon v-else color="#2D3038" size="30">
                  mdi-plus
                </v-icon>
              </div>
            </div>

            <div v-if="showFiltersItemsByColor" class="pa-0 ma-0 d-flex flex-column align-start ml-1">
              <v-checkbox
                  v-for="item in subcategory" :key="item.subCategoryId"
                  class="ma-0 pa-0"
                  v-model="selectedSubcategories"
                  color="#BC0000"
                  :value="item.subCategoryId"
                  @change="setSubcategories()"
              >
                <template v-slot:label>
                  <span id="checkboxLabelCorMobile">{{ item.subCategory.name }}</span>
                </template>
              </v-checkbox>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="mr-5">
          <v-btn
              depressed
              color="error"
              @click="filterMobile = false"
              width="50%"
          >
            Fechar
          </v-btn>
          <v-btn
              depressed
              color="primary"
              @click="filterMobile = false"
              width="50%"
          >
            Aplicar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="filterByMobile" max-height="50">
      <v-card color="#F5F5F5" min-height="200" class="pa-5">
        <v-card-title class="pa-0 ma-0"><strong>Filtrar por</strong></v-card-title>
        <v-divider></v-divider>
        <v-select
            @change="setFilterBy()"
            v-model="filterBy"
            style="border-radius: 4px;
              border: 1px solid #D9D9D9;
              background: #FFF;
              height: 42px;"
            :items="items"
            outlined
            dense
            item-text="text"
            item-value="value"
            placeholder="Filtrar por"
        >
        </v-select>
      </v-card>
    </v-dialog>

    <div class="ma-0 pa-0">
      <p class="title-filter">{{ title }}</p>
    </div>

    <div class="content-mobile-filter">
      <v-combobox
          outlined
          background-color="#FFF"
          hide-details
          dense
          v-model="product"
          class="header_desktop_search mobile"
          item-text="productName"
          ref="myComboBox"
          placeholder="Buscar produto"
          style="height: 45px!important;border-radius: 6px;"
          v-on:keyup.enter="searchForProductsByName"
          :search-input.sync="search"
      >
        <template v-slot:append>
          <v-icon icon color="normalred" size="50" @click="searchForProductsByName">mdi-magnify</v-icon>
        </template>
      </v-combobox>

      <div class="mx-5 text-center" @click="filterMobile = true">
        <p class="text-search-filter  ma-0 pa-0">Filtrar</p>
        <v-icon icon color="#FFFFFF" size="40">mdi-filter-outline</v-icon>
      </div>

      <div class="text-center" @click="filterByMobile = true">
        <p class="text-search-filter  ma-0 pa-0">Ordenar</p>
        <v-img
            max-width="35"
            src="../../../assets/sort.svg"
            alt="Ordenar"
            contain
        ></v-img>
      </div>
    </div>

    <div>
      <v-combobox
          outlined
          style="width: 100%!important;"
          dense
          background-color="#FFF"
          hide-details
          v-model="product"
          class="header_desktop_search desktop"
          item-text="productName"
          height="44"
          ref="myComboBox"
          placeholder="Buscar produto"
          v-on:keyup.enter="searchForProductsByName"
          :search-input.sync="search"
      >
        <template v-slot:append>
          <v-icon icon color="normalred" size="27" @click="searchForProductsByName">mdi-magnify</v-icon>
        </template>
      </v-combobox>

      <div class="filters desktop mt-2">
        <div>
          <div class="pa-0 ma-0 d-flex align-center justify-space-between pointer"
               @click="showFiltersItemsBySize = !showFiltersItemsBySize">
            <p class="name-filter ml-1">Tamanho</p>
            <div class="mb-5">
              <v-icon v-if="showFiltersItemsBySize" color="#2D3038" size="30">
                mdi-minus
              </v-icon>

              <v-icon v-else color="#2D3038" size="30">
                mdi-plus
              </v-icon>
            </div>
          </div>

          <div v-if="showFiltersItemsBySize" class="pa-0 ma-0 d-flex flex-column align-start ml-1">
            <v-checkbox
                v-for="item in attributeGroups" :key="item.name"
                class="ma-0 pa-0 "
                v-model="selectedSizes"
                color="#BC0000"
                :value="item.name"
                @change="setSelectedSizes()"
            >
              <template v-slot:label>
                <span id="checkboxLabel">{{ item.name }}</span>
              </template>
            </v-checkbox>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="mt-3">
          <div class="pa-0 ma-0 d-flex align-center justify-space-between pointer"
               @click="showFiltersItemsByColor = !showFiltersItemsByColor">
            <p class="name-filter ml-1">Cor</p>
            <div class="mb-5">
              <v-icon v-if="showFiltersItemsByColor" color="#2D3038" size="30">
                mdi-minus
              </v-icon>

              <v-icon v-else color="#2D3038" size="30">
                mdi-plus
              </v-icon>
            </div>
          </div>

          <div v-if="showFiltersItemsByColor" class="pa-0 ma-0 d-flex flex-column align-start ml-1">
            <v-checkbox
                v-for="item in subcategory" :key="item.subCategoryId"
                class="ma-0 pa-0"
                v-model="selectedSubcategories"
                color="#BC0000"
                :value="item.subCategoryId"
                @change="setSubcategories()"
            >
              <template v-slot:label>
                <span id="checkboxLabelCor">{{ item.subCategory.name }}</span>
              </template>
            </v-checkbox>
          </div>
        </div>
        <v-divider></v-divider>
      </div>
    </div>
  </div>
</template>

<style scoped>


.pointer {
  cursor: pointer;
}

.title-filter {
  color: #BC0000;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}

.name-filter {
  color: #2D3038;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.filters-size {
  border-bottom: 1px solid #4F4F4F !important;
}


#checkboxLabel, #checkboxLabelCor, #checkboxLabelMobile, #checkboxLabelCorMobile {
  color: #4F4F4F;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

}

.v-input--checkbox::v-deep {

.v-label, .v-icon {
  color: #4F4F4F;
  font-size: 28px;
}

}
.text-search-filter {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.content-mobile-filter {
  display: none;
}

@media (max-width: 1264px) {
  .desktop {
    display: none;
  }

  .content-mobile-filter {
    width: 100%;
    background: #2D3038 !important;
    display: flex;
    justify-content: space-evenly !important;
    padding: 20px;
  }

}

</style>

<style>
/*.v-expansion-panel::before {
  box-shadow: none;
}*/
</style>

<script>

import Vue from "vue";
import ApiService from "../../../services/ApiService";

export default {
  name: "Filters",

  props: {
    title: {default: null, type: String},
  },

  data: () => ({
    filterMobile: false,
    filterByMobile: false,
    filterBy: null,
    showFiltersItemsBySize: false,
    showFiltersItemsByColor: true,
    apiService: new ApiService(),
    subcategory: [],
    selected: [],
    attributeGroups: [],
    search: "",
    product: "",
    selectedSizes: [],
    selectedSubcategories: [],
    items: [
      {
        text: 'Preço (ascendente)',
        value: 1,
      },
      {
        text: 'Preço (descendente)',
        value: 2,
      },
    ],
  }),
  components: {},
  async created() {
    await this.getSubCategories()
    await this.getAttributesGroup()
  },

  computed: {},

  methods: {
    setFilterBy() {
      this.filterByMobile = false
      this.$emit('filter-by', this.filterBy)
    },
    async getSubCategories() {
      await this.apiService
          .get(`category/categoryByName?name=cores`)
          .then((response) => {
            this.subcategory = []
            if (response.subCategoriesToCategory && response.subCategoriesToCategory.length > 0) {
              this.subcategory = response.subCategoriesToCategory
            }
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Erro listar as categorias",
              message: "Erro listar as categorias",
              trace: null,
            });

            this.loading = false;
          });
    },

    async getAttributesGroup() {
      const regex = /\d/;
      await this.apiService
          .get(`attributesgroup/listGroupItems`)
          .then((response) => {
            this.attributeGroups = [...new Map(response.map(item => [item['name'], item])).values()].filter((x) => (regex.test(x.name)));
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "",
              message: "Não foi possível lista os tamanhos!",
              trace: null,
            });
            this.loading = false;
          });
    },

    searchForProductsByName() {
      this.$emit("product", this.search)
    },
    setSelectedSizes() {
      this.$emit("selected-sizes", this.selectedSizes)
    },
    setSubcategories() {
      this.$emit("selected-subcategories", this.selectedSubcategories)
    }
  },
};
</script>
