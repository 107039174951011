<template>
  <main class="d-flex justify-center">
    <v-card class="mx-1 pa-5" width="90%" elevation="0">
      <v-row cols="12" class="pt-1 mx-1" v-if="platformSettings && platformSettings.id">
        <v-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="pa-0 ma-0 pr-2 mb-5"
        >
          <section>
            <a @click="goToRoute()" class="d-flex align-center">
              <v-img
                  max-width="25"
                  src="../../../assets/voltar.svg"
                  alt="Voltar"
                  contain
              ></v-img>

              <h2 class="ml-3 " style="color: #bc0000">Voltar</h2>
            </a>
          </section>
        </v-col>

        <v-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="pa-0 ma-0 pr-2"
        >
          <p v-html="platformSettings.exchangeAndReturnsPolicy" v-if="policy === 'troca-devolucoes'"></p>
          <p v-html="platformSettings.privacyPolicy" v-if="policy === 'politica-privacidade'"></p>
          <p v-html="platformSettings.termsAndConditions" v-if="policy === 'termos-condicoes'"></p>
          <p v-html="platformSettings.aboutStore" v-if="policy === 'quem-somos'"></p>
        </v-col>
      </v-row>
    </v-card>
  </main>
</template>

<script lang="js">
import ApiService from '../../../services/ApiService';
import Vue from "vue";

export default {
  name: "PlatformPolicies",
  components: {},
  created() {

  },
  async mounted() {
    try {
      await this.getPlatformSettings()
      this.$root.$on("platform-policies", async () => {
        await this.getPlatformSettings();
      });
    } catch (e) {

    }
  },
  beforeDestroy() {
    this.$root.$off("platform-policies")
  },
  data() {
    return {
      platformSettings: {},
      policy: null,
      apiService: new ApiService(),
    }
  },

  methods: {
    async goToRoute() {
      await this.$router.go(-1);
    },
    async getPlatformSettings() {
      const {name} = this.$route.params
      this.policy = name

      this.loading = true;
      this.apiService.get('platformsettings/get').then((response) => {
        this.platformSettings = response
      }).catch(() => {
        this.loading = false;
      })
    },
  },

  watch: {}
};
</script>

<style scoped>
</style>
