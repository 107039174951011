<template>
  <div class="mainCart" style="text-align: -webkit-center">
    <v-card class="card-cart mx-auto">
      <v-row style="text-align-last: left">
        <v-col class="px-10">
          <span class="main-title" :key="componentKey"
            >Carrinho({{ this.cart != null ? cart.items.length : "0" }})</span
          >
        </v-col>
      </v-row>

      <div v-if="cart.items && cart.items.length === 0">
        <div class="py-10 my-10">
          <span class="empty-message pa-0 ma-0">O seu carrinho está vazio</span
          ><br />
          <span class="empty-label pa-0 ma-0"
            >Acesse o menu acima e encontre seu adesivo perfeito.</span
          >
        </div>
      </div>

      <v-row
        :v-if="cart.items && cart.items.length !== 0"
        class="px-4"
        v-for="(item, i) in cart.items"
        :key="item.id"
        draggable="true"
      >
        <v-col class="py-0 ma-0" :key="item.id + componentKey + 1">
          <v-hover v-slot="{ hover }">
            <ProductCartDesktop
              :elevation="hover ? 1 : 0"
              :class="{ 'on-hover': hover }"
              :item="item"
              :keyId="i"
              :key="item.id"
              @remove="getCart"
              @quoteShipping="getAdress(user)"
              @changeclearDialog="clearDialog = true"
            />
          </v-hover>
        </v-col>
      </v-row>

      <v-row
        v-if="cart.items && cart.items.length != 0 ? true : false"
        class="px-6"
        style="place-item: center; justify-content: right"
        :key="componentKey + 2"
      >
        <!-- Se estiver logado e tiver endereço -->

        <v-col
          v-if="user && endereco != null"
          class="d-flex"
          cols="12"
          sm="12"
          md="7"
          style="text-align: left !important"
        >
          <span class="span-red" @click="goToRoute('/list-address')">{{
            "Alterar Endereço"
          }}</span>
          <span>
            <b>{{ endereco.addressNickName }}:</b>
            {{
              endereco.addressName +
              ", " +
              " N° " +
              endereco.addressNumber +
              ", " +
              endereco.city +
              ", " +
              endereco.state +
              ", " +
              endereco.country +
              ", " +
              endereco.postalCode +
              " - "
            }}
            <b>Complemento: </b>{{ endereco.addressComplement }}
          </span>
        </v-col>

        <!-- Se estiver logado e não tem endereço -->
        <div v-if="user && endereco == null" class="d-flex">
          <span class="span-red px-3" @click="newAddress('model=new')"
            >{{ "Cadastrar um endereço" }}
          </span>
          <div v-if="user && endereco != null" class="px-2">
            <b>Complemento: </b>{{ endereco.addressComplement }}
          </div>
        </div>

        <!-- Se não estiver logado-->
        <div v-if="!user" class="d-flex">
          <span class="span-red px-3" @click="goToRoute('/register')"
            >{{ "Cadastrar-se" }}
          </span>
        </div>

        <v-col class="px-0" style="text-align-last: end" cols="7" sm="5" md="5">
          <span class="span-red" color="red" @click="dialogClear()">
            Esvaziar carrinho
            <v-icon color="normalred">mdi-trash-can-outline</v-icon>
          </span>
        </v-col>
        <v-col  cols="12" sm="6" md="4">
          <div class="d-flex px-0 price-frete" style="justify-content: right">
            <span class="frete px-2">Frete:</span>
            {{
              this.valor.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </div>
        </v-col>
      </v-row>

      <v-row
        v-if="
          answerShippingCalculationModel.length > 0 &&
          cart.items &&
          cart.items.length > 0
        "
      >
        <v-col class="px-10" style="text-align-last: left">
          <span class="text-selecione-frete">Selecione o frete</span>
        </v-col>
      </v-row>
      <v-col v-if="freight.length > 1" cols="12" class="text-left">
        <div
            class="text-center"
            style="background: #bc0000; border-radius: 5px"
        >
          <p style="color: #FFFFFF; font-size: 18px; font-weight: 500">
            O frete e o prazo de entrega deste pedido podem variar, pois a entrega será
            dividida entre diferentes revendedores devido à disponibilidade dos produtos.
          </p>
        </div>
      </v-col>
      <v-row
        class="px-4"
        v-if="freight.length > 0 && cart.items && cart.items.length > 0"
      >
        <v-col cols="12" v-for="(item, i) in freight" :key="i">
          <hr/>

          <v-col v-if="freight.length > 1" cols="12" class="text-left">
            <h3 >Entrega {{(i + 1)}} / {{freight.length}}</h3>
          </v-col>

          <v-col cols="12" v-for="(sku, y) in item.productsSku" :key="y" class="d-flex justify-space-between">
            <v-col cols="6">
              <div class="container-produto-img-name-cod-variacao">
                <div class="container-produto-img">
                  <v-img
                      class="ma-0 pa-0"
                      width="100"
                      height="100"
                      :src="`${sku.product && sku.product.productPic ? sku.product.productPic : ''}`"
                  >
                  </v-img>
                </div>

                <div class="ma-0 pa-0" style="text-align: start">
                  <div>
                    <span class="card-title pa-0 ma-0">
                      {{ sku.product.productName }}
                    </span>

                    <br />
                    <span class="product-cod">
                      Cód: {{ sku.product.productCode }}
                    </span>
                  </div>

                  <v-row
                      class="variacoes text--primary pa-0 ma-0"
                      v-for="(attribute, attributesId) in sku.attributes"
                      :key="attributesId"
                  >
                    <div class="atributos">
                      <p class="span-btnCard px-1">{{ attribute.name }}</p>
                    </div>
                  </v-row>
                </div>
              </div>

            </v-col>

            <v-col cols="6" align="end">
              <div class="">
                <span class="card-price px-0 mx-0">
                    {{
                        calculatePrice(sku).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                    }}
                  </span>
              </div>

            </v-col>
          </v-col>

          <v-col v-if="item.melhorEnvio" cols="12" class="text-left">
            <h3>Selecione o frete</h3>
          </v-col>

          <v-col cols="12" v-if="item.melhorEnvio">
            <v-radio-group
                v-if="item.answerShippingCalculationModel.length > 0"
                hide-details
                v-model="item.selectedShipping"
                mandatory
            >
              <v-radio
                  v-for="(itemx, a) in item.answerShippingCalculationModel"
                  active-class="radio-pressed"
                  class="radio py-2"
                  color="red"
                  :value="itemx"
                  @change="selectAddress(item, itemx)"
                  :key="a"
              >
                <template v-slot:label>
                  <v-row class="d-flex radio-align py-0">
                    <v-col class="" cols="12">
                      <div>
                        <strong class="secondary&#45;&#45;text">
                          {{
                            itemx.name.toUpperCase() +
                            ", " +
                            itemx.company.name +
                            "            Valor " +
                            itemx.price.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            }) +
                            ", Prazo " +
                            itemx.custom_delivery_range.min +
                            " a " +
                            itemx.custom_delivery_range.max +
                            " dias"
                          }}
                        </strong>
                      </div>
                    </v-col>
                    <hr />
                  </v-row>
                </template>
              </v-radio>
            </v-radio-group>

            <div v-else class="d-flex price-frete top-left">
              <span class="frete">Não entregamos nessa região</span>
            </div>
          </v-col>

          <v-col cols="12" v-else class="text-left">
            <h3>
             Frete:  {{
                item.price.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}</h3>
          </v-col>
        </v-col>
      </v-row>

      <hr/>

      <v-row
        v-if="cart.items && cart.items.length !== 0"
        class="px-3 py-0"
        style="justify-content: right !important"
        :key="componentKey + 3"
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <v-col
          class="d-flex align-content-end pt-0 px-6"
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
        </v-col>

        <v-col
          class="d-flex pt-5 px-7 justify-end"
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <div class="text-right">
            <div class="price-total">
              <span v-if="this.freight.length > 0" class="label-cart px-0">
                Total com frete
              </span>

              <span v-else class="label-cart px-0">
                Total sem frete
              </span>

              {{
                TotalPrice.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </div>
            <span class="card-subprice"> em até 3x sem juros </span>
          </div>
        </v-col>
      </v-row>

      <div
          class="ma-2 pa-2"
          style="background: #e0dcce; font-size: 18px; border-radius: 5px"
      >
        O prazo de envio escolhido será estabelecido a partir do despacho
        realizado por nosso parceiro, que
        <br />
        poderá ocorrer em <strong>até 48h ou 2 dias</strong> úteis após a
        confirmação do pagamento.
      </div>

      <v-row
        v-if="cart.items && cart.items.length > 0"
        class="px-5 ma-0 px-0 py-2"
        style="place-content: end"
      >
        <div class="btn-cart px-1 ma-0 py-1">
          <v-btn
            small
            width="100%"
            class="secondary-button"
            @click="goToRoute('/home')"
          >
            Continuar comprando
          </v-btn>
        </div>

        <div class="btn-cart px-1 ma-0 py-1">
          <v-btn
            width="100%"
            class="primary-button"
            :disabled="desbilitarComprarAgora"
            @click="buyCartNow('/checkout')"
            :loading="loading"
          >
            Comprar agora
          </v-btn>
        </div>
      </v-row>
    </v-card>

    <!-- Dialog ESVAZIAR  -->
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="clearDialog"
      max-width="400px"
    >
      <DeleteModal
        title="Esvaziar carrinho"
        textMessage="Tem certeza que deseja esvaziar seu carrinho de compras?"
        cancelBtn="Cancelar"
        okbtn="Sim"
        textObservation=""
        icone="mdi-trash-can-outline"
        @close="clearDialog = false"
        @action="(clearDialog = false), clearCart()"
        :key="dialogKey"
      />
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "../../../services/ApiService";
import ProductCartDesktop from "../components/ProductCartDesktop.vue";
import DeleteModal from "../components/DeleteModal.vue";
// import ImageCard from "../components/ImageCard.vue";
import Vue from "vue";
import axios from "axios";
import moment from "moment";
import Utils from "../../../Utils/ValidateCPFCNPJ";

export default {
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.$root.$on("changeCart", (data) => {
      this.cart = data;
      this.componentKey++;
      if (this.cart.items.length > 0) {
        this.calcularFrete();
      }
    });
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  data: () => ({
    addressIdSelect: "",
    componentKey: 1,
    endereco: null,
    addresses: [],
    radios: null,
    cepLoading: false,
    user: {},
    frete: [],
    percentage: 0,
    freteSelecionado: null,
    answerShippingCalculationModel: [],
    env_url: process.env.VUE_APP_BASE_URL,
    isMobile: false,
    desbilitarComprarAgora: true,
    badge: "",
    valor: 0,
    clearDialog: false,
    dialogKey: false,
    loading: false,
    sentMehorEnvio: false,
    freight: [],
    cep: "",
    apiService: new ApiService(),
    cart: { items: [] },
    percentual: 1,
  }),

  components: {
    ProductCartDesktop,
    DeleteModal,
  },

  computed: {
    TotalPrice() {
      let result = 0;

      this.cart.items.forEach((e) => {
        let discont = 0;

        if (e.sku.product.percentage > 0) {
          discont = (e.sku.product.percentage / 100) * e.sku.differentValue
        } else if (e.promotion) {
          discont = e.promotion.discountType  === this.percentual ?  (e.promotion.discount / 100) * e.sku.differentValue : discont = e.promotion.discount
        }
        result += (e.sku.differentValue - discont) * e.quantity;
      });

      if (this.valor) {
        return parseFloat(result + this.valor);
      }

      return parseFloat(result);
    },

  },

  watch: {
    badge() {
      this.$root.$on("changeBadge", (data) => {
        this.badge = data;
      });
    },
  },

  async created() {
    this.user = JSON.parse(localStorage.getItem("user"));

    if (this.user) {
      await this.getCart();
    } else {
      this.cart = JSON.parse(localStorage.getItem("cart"));
    }

    await this.getAdress(this.user);
  },

  methods: {
    calculatePrice(sku) {
      let percentage = 0
      if (sku.product.promotionStart && sku.product.promotionEnd) {
          if (
            moment().isAfter(moment(sku.product.promotionStart)) &&
            moment().isBefore(moment(sku.product.promotionEnd))
          ) {
            percentage = sku.product.percentage;
          }
        } else {
          if (sku.product.percentage) {
            percentage = sku.product.percentage;
          }
        }
      return (sku.quantity * (sku.differentValue - (percentage / 100) * sku.differentValue));
    },
    calculateShippingValue(){
      this.freight.forEach((x) => {
        x.productsSku.forEach((y) => {
          y.quantity = this.cart.items.find((c) => c.skuId === y.id).quantity
        })
      })
      const freightValueMelhorEnvio = this.freight.filter((x) => x.melhorEnvio && x.selectedShipping && x.selectedShipping.price).reduce((total, item) =>  Number(total) + Number(item.selectedShipping.price), 0)
      const standardShippingValue = this.freight.filter((x) => !x.melhorEnvio).reduce((total, item) =>  Number(total) + Number(item.price), 0)
      this.valor = Number((standardShippingValue + freightValueMelhorEnvio).toFixed(2))
      this.desbilitarComprarAgora = !!this.freight.find((x) => x.melhorEnvio && x.answerShippingCalculationModel.length <= 0)

      const freightQuote = {
        quote: JSON.stringify(this.freight),
        UserId: this.user.id
      }
      this.addFreightQuote(freightQuote)
    },
    async getCart() {
      await this.apiService.get("cart").then((response) => {
        this.cart = response.content;
      });
    },

    async addFreightQuote(freightQuote) {
      await this.apiService
          .post(`freightQuote/add`, freightQuote)
          .then(async (response) => {
      }).catch((error) => {

      })
    },
    async removeFreightQuote() {
      await this.apiService
          .delete(`freightQuote`)
          .then(async (response) => {
          this.freight = []
      }).catch((error) => {

      })
    },

    selectAddress(freight, item) {
      freight.selectedShipping = item
      this.calculateShippingValue();
    },

    async getLocation() {
      let address = [
        this.endereco.addressNumber,
        this.endereco.addressName,
        this.endereco.state,
        this.endereco.city,
      ].join(" ");

      await axios
        .get("https://maps.googleapis.com/maps/api/geocode/json", {
          params: {
            address: address,
            key: process.env.VUE_APP_APIKEY,
          },
        })
        .then((response) => {
          let result = response.data.results[0].geometry.location;

          this.location = {
            latitude: parseFloat(result.lat),
            longitude: parseFloat(result.lng),
            place_id: response.data.results[0].place_id,
          };
          this.endereco.latitude = this.location.latitude;
          this.endereco.longitude = this.location.longitude;
        })
        .catch((erro) => {});
    },

    async searchPostal() {
      this.cepLoading = true;
      const client = new ApiService();
      await client
        .get(`user/postalcode?cep=${this.cep}`)
        .then((resp) => {
          const response = JSON.parse(resp);

          if (response.logradouro != null) {
            this.endereco = {
              id: "",
              clientId: this.user.id,
              addressType: 0,
              addressNickName: "",
              postalCode: response.cep,
              country: "",
              state: response.uf,
              city: response.localidade,
              neighborhood: response.bairro,
              addressName: response.logradouro,
              addressNumber: "",
              addressComplement: "",
            };
            this.calcularFrete();
            this.isFrete = true;
            this.cepLoading = false;
          } else {
            this.isFrete = false;
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "CEP não encontrado",
              message: "CEP não encontrado",
              trace: null,
            });
            this.cepLoading = false;
          }
        })
        .catch((erro) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Erro ao procurar CEP",
            message: err.body.message,
            trace: null,
          });

          this.isFrete = false;
          this.loading = false;
          this.cepLoading = false;
        });
    },

    async buyCartNow(route) {
      if (this.endereco) {
        localStorage.setItem("deliveryAddress", JSON.stringify(this.endereco));
        this.goToRoute(route);
      } else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Cadastre um endereço para entrega",
          message: "Cadastre um endereço para entrega",
          trace: null,
        });
      }
    },

    dialogClear() {
      this.clearDialog = true;
    },

    updateCart() {
      const cartFromLocalStorage = JSON.parse(
        localStorage.getItem("cartUser") || "[]"
      );
      if (cartFromLocalStorage) {
        this.cart = cartFromLocalStorage;
        this.badge = this.cart.reduce((total, item) => {
          return total + item.qty;
        }, 0);

        this.totalprice = this.cart.reduce((total, item) => {
          return total + item.qty * item.price;
        }, 0);
      }
      //Option1- Evento Global

      this.$root.$emit("changeBadge", this.badge);
      //Option2- Coloca o badge no localhost para o navigation pegar

      localStorage.setItem("cartBadge", JSON.stringify(this.badge));
      this.loading = false;
    },

    async getAdress(user) {
      if (user) {
        const { name, cnpj, phoneNumber } = user;
        if (
          name &&
          name.toString().length > 0 &&
          (Utils.CPF(cnpj) || Utils.CNPJ(cnpj)) &&
          phoneNumber &&
          phoneNumber.toString().length > 14
        ) {
          this.loading = true;
          await this.apiService
            .get(`client/getAddressDeliveryAddress?idClient=` + user.id)
            .then((response) => {
              const { content } = response;
              if (content) {
                this.endereco = content;
                if (
                  this.cart.items &&
                  this.cart.items.length > 0 &&
                  this.endereco
                ) {
                  this.calcularFrete();
                }
              } else {
                this.goToRoute(`/address`);
              }
              this.loading = false;
            })
            .catch((err) => {});
          this.loading = false;
        } else {
          localStorage.setItem("step", JSON.stringify(0));
          this.goToRoute({ path: "/profile", query: { cart: true } });
        }
      } else {
        this.goToRoute("/login");
      }
    },

    async calcularFrete() {
      await this.getLocation();
      let itens = [];
      this.cart.items.forEach((x) => {
        let discont = 0;
        if (x.sku.product.percentage > 0) {
          discont = (x.sku.product.percentage / 100) * x.sku.differentValue
        } else if (x.promotion) {
          /*if (x.promotion.discountType === this.percentual){
            discont = (x.promotion.discount / 100) * x.sku.differentValue
          } else {
            discont = x.promotion.discount
          }*/
          discont = x.promotion.discountType === this.percentual ? (x.promotion.discount / 100) * x.sku.differentValue : x.promotion.discount
        }
        itens.push({
          ProductId: x.sku.productId,
          SkuId: x.skuId,
          Price: parseFloat(x.sku.differentValue - discont).toFixed(2),
          Qty: x.quantity,
          promotion: x.promotion
        });
      });

      const dados = {
        Address: this.endereco,
        Itens: itens,
      };
      const client = new ApiService();
      await client
        .post(`client/frete`, dados)
        .then((response) => {
          const res = response;
          if (res.content == null) {
            this.desbilitarComprarAgora = true;
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "info",
              title: `Atenção`,
              message: res.message,
              trace: null,
            });
            return;
          }
          this.sentMehorEnvio = res.content.melhorEnvio;
          this.freight = res.content
          this.freight.forEach((x) => {
            if(x.melhorEnvio) {
              const [freightFirst] = x.answerShippingCalculationModel
              x.selectedShipping = freightFirst
            }
          })
          this.calculateShippingValue();
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Erro",
            message: "Não foi possível calcular o Frete",
            trace: null,
          });
        });
    },

    async clearCart() {
      this.apiService.delete("cart/clear").then(() => {
        this.$root.$emit("changeBadge", this.badge);
        this.getCart();
        this.removeFreightQuote();
      });
      this.componentKey += 1;
    },

    goToRoute(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
    async newAddress(model) {
      await this.$router.push({
        path: "/address?" + model,
        params: model,
      });
    },

    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
  },
};
</script>

<style scoped>
.calcular-frete-prazo {
  margin: 10px 0 5px -35px !important;
}
.card-price {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 27px;

  letter-spacing: 0.15px;

  color: #000000;
}
.text-selecione-frete {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.radio {
  margin-top: -9px;
  height: 60px;
  margin-left: 10px !important;
  border-bottom: 1px solid #9c9c9c;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #979797;
}

.radio:hover {
  background-color: #ddd;
}

.radio-align {
  left: 0px !important;
  right: auto !important;
  position: relative !important;
  flex-flow: column !important;
  align-items: start !important;
}

.card-subprice {
  font-family: Poppins;
  font-style: normal;
  font-size: 0.9rem;
  line-height: 24px;

  letter-spacing: 0.15px;

  color: #979797;
}

.price-total {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: black;
}

.label-cart {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1.45rem;
  line-height: 36px;
  letter-spacing: 0.15px;

  color: #000000;
}

.price-frete {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.55rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: #979797;
}

.frete {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: #979797;
}

.frete:hover {
  cursor: pointer;
}

.span-red {
  align-self: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: #bc0000;
  margin-right: 10px;
}

.span-red:hover {
  cursor: pointer;
}

.empty-message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 3.2rem;

  color: #97999d;
}

.empty-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;

  color: #97999d;
}

.main-title {
  font-size: 1.3rem;
}

.card-cart {
  width: 95%;
  height: fit-content !important;
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
  border-radius: 6px;
}

@media (min-width: 600px) {
  .card-cart {
    width: 80%;
    background: #ffffff;
    box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
    border-radius: 6px;
  }
}
.btn-cart {
  width: 190px;
}
@media (max-width: 600px) {
  .btn-cart {
    width: 100% !important;
  }
}
.v-card__text {
  padding: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
}

.containerList {
  justify-content: center !important;
  flex-direction: column;
}
.container-produto-img-name-cod-variacao {
  display: flex;
}

.container-produto-img {
  padding: 0 10px 0 0;
}
.atributos {
  background: #2d3038;
  margin: 10px 10px 0 0;
  border-radius: 3px;
}

.variacoes {
  display: inline-block;
  padding: 10px;
}
.span-btnCard {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: #dadada;
  padding: 3px;
}
</style>
