<template>
  <v-card class="card pa-6 mx-auto" elevation="2">
    <div class="py-6 d-flex align-center">
      <v-img height="45px" contain src="~@/assets/logo-imprimax.svg"></v-img>
    </div>
    <div
      class="py-6 my-6 mx-2 px-1 d-flex flex-column justify-center align-center"
    >
      <span class="label py-2">
        {{ textMessage }}
      </span>
      <span class="label observation py-6">
        {{ textObservation1 }}
      </span>
      <span class="label observation py-">
        {{ textObservation2 }}
      </span>
    </div>

    <div>
      <v-row class="d-flex ma-0 pa-0">
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            elevation="4"
            large
            style="max-width: fit-content"
            class="white--text"
            @click="actionCancel()"
            :loading="loading"
          >
            <span>{{ cancelBtn }}</span>
          </v-btn>
          <v-btn
            elevation="4"
            large
            style="max-width: fit-content"
            class="okBtn white--text"
            @click="actionOK()"
            :loading="loading"
          >
            <span>{{ okbtn }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<style scoped>
.card {
  background: #ffffff !important;
  height: 525px;
  border: 1px solid #dadada;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 7px !important;
}

.okBtn {
  font-weight: bold !important;
  box-sizing: border-box !important;
  background: #bc0000 !important;
  width: 363px;
  border-radius: 8px solid #2d3038 !important;
}

.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 29px;
  text-align: justify;
  letter-spacing: 0.15px;

  color: #bc0000;
}

.observation {
  font-family: Roboto;
  font-style: normal;
  color: grey;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  text-align: justify;
  letter-spacing: 0.15px;

  color: #232021;
}

@media (max-width: 600px) {
  .select-type {
    width: 50% !important;
  }
}

@media (max-width: 600px) {
  .form {
    flex-direction: column;
  }
}
</style>

<script>
import ApiService from "../../../services/ApiService";

import Vue from "vue";

export default {
  name: "ConfirmModalAdd",

  props: {
    textMessage: String,
    okbtn: String,
    cancelBtn: String,
    textObservation1: String,
    textObservation2: String,
  },

  data: () => ({
    loading: false,
    excelDialog: "",
    apiService: new ApiService(),
  }),

  
  methods: {

    
    close() {
      this.$emit("close");
    },

    
    actionCancel() {
      this.$emit("actionCancel");
    },

    actionOK() {
      this.$emit("actionOK");
    },

  
    goTo(rota) {
      this.$router.push("/"+rota);
    },
  
    




    },

  


};
</script>
