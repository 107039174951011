<template>
  <v-main class="mainProfile">
    <v-file-input
      style="display: none"
      id="imageInput"
      accept="image/png, image/jpeg, image/jpg"
      :label="'Nenhuma imagem selecionada'"
      filled
      prepend-icon="mdi-camera"
      @change="setImage"
      required
    ></v-file-input>
    <!-- DESKTOP -->
    <v-row v-if="!isMobile" style="width: 80%" class="" align="start">
      <!--Menu-->
      <v-col class="ma-0 pa-0 pr-4" cols="12" md="4" sm="4" lg="4">
        <v-item-group>
          <v-row cols="12" class="">
            <v-col cols="12" class="py-2 " v-for="(item, i) in menu" :key="i">
              <v-item class="" v-slot="{ active, toggle }">
                <v-row
                    cols="12"
                    class="card-profile"
                    @click="toggle(), stepChange(i)"
                >
                  <v-row cols="12" class="pa-0 ma-0 py-3" style="place-items: center">
                    <v-col class="ma-0 pa-1" cols="2" sm="2" md="2">
                      <v-icon
                          large
                          :style="item.step === step ? 'color : #E30011' : 'color : #000000'"
                          class="px-1"
                          v-text="item.icon"
                      >
                      </v-icon>
                    </v-col>

                    <v-col
                        style="text-align: left"
                        class="ma-0 pa-1"
                        cols="8"
                        sm="8"
                        md="8"
                        :style="item.step === step ? 'color : #E30011' : 'color : #000000'"
                    >
                      <v-card-title class="ma-0 pa-0">
                        {{ item.text }}
                      </v-card-title>
                      {{ item.subtitle }}
                    </v-col>

                    <v-col class="ma-0 pa-1" cols="2" sm="2" md="2">
                      <v-icon
                          :style="item.step === step ? 'color : #E30011' : 'color : #000000'"
                          class="px-2"
                      >
                        mdi-chevron-right
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-row>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-col>

        <v-col class="" style="text-align: left" cols="12" md="8" sm="8" lg="8">
          <div class="ma-0 px-0">
            <span v-if="step === 0" class="step-title">Informações pessoais</span>
            <span v-if="step === 1" class="step-title">Seus endereços salvos</span>
            <span v-if="step === 2" class="step-title">Senha</span>
            <span v-if="step === 3" class="step-title">Meus pedidos</span>
          </div>

          <v-card class="infos-card ma-0 pa-0">
            <!--Informação Pessoais -->
            <update-profile v-if="step === 0 && user.type === 3" />

            <v-form v-else-if="step === 0 && user.type !== 3" :key="clear" ref="firstForm">
              <v-row
                style="justify-content: space-between"
                class="pa-3 ma-0 px-4"
            >
              <span class="step-title"><b>Seus dados</b></span>
              <v-icon> mdi-pencil-outline</v-icon>
            </v-row>

            <v-row class="pa-0 ma-0 px-3 pb-7">
              <v-col cols="12" sm="12" md="12" lg="12">
                <div
                    :key="componentKey"
                    class="d-flex flex-column align-center justify-center"
                >
                  <v-hover
                      v-slot="{ hover }"
                      v-if="currentClient.image"
                  >
                    <v-img
                        :src="currentClient.image"
                        style="
                          border-radius: 50% !important;
                          opacity: 0.8;
                          cursor: pointer;
                        "
                        height="150"
                        width="150"
                        cover
                        :class="hover ? 'elevation-5 mx-2' : 'mx-2'"
                    >
                      <v-expand-transition>
                        <div
                            v-if="hover"
                            class="
                              d-flex
                              flex-column
                              justify-center
                              align-center
                            "
                            style="
                              height: 100%;
                              background: rgba(188, 0, 0, 0.4);
                            "
                            @click="openPicker"
                          >
                            <v-icon size="26" color="white">mdi-pen</v-icon>
                            <span class="edit-photo">Alterar foto</span>
                          </div>
                        </v-expand-transition>
                      </v-img>
                  </v-hover>

                  <div
                      class="d-flex flex-column align-center"
                      style="max-width: 150px"
                      v-else
                  >
                    <v-hover v-slot="{ hover }">
                      <div
                          class="image-container mx-2"
                          @click="openPicker"
                          :style="hover ? 'background: #C4C4C4' : ''"
                      >
                        <v-img
                            src="../../../assets/imp.svg"
                            contain
                            max-width="157"
                            v-if="!hover"
                        >
                        </v-img>
                        <div
                            v-if="hover"
                            class="d-flex flex-column align-center"
                        >
                          <v-img
                              src="../../../assets/add_photo.svg"
                              contain
                              max-width="46"
                          >
                          </v-img>
                          <span class="photo-field" v-if="hover"
                          >Adicionar Foto</span>
                        </div>
                      </div>
                    </v-hover>
                  </div>
                </div>
              </v-col>

              <v-col v-if="needTtoCompleteRegistration" cols="12" sm="12" md="12" lg="12">
                <p class="complete_cadastro">Complete seu cadastro</p>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6">
                  <span class="label">
                    Nome e Sobrenome
                    <strong style="color: #bc0000">*</strong>
                  </span>
                <v-text-field
                    dense
                    outlined
                    placeholder="Seu nome"
                    v-model="currentClient.name"
                    :rules="[rules.required]"
                    @keydown.enter="submit()"
                    required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6">
                <span class="label"> Data de Cadastro </span>
                <v-text-field
                    dense
                    outlined
                    placeholder="dd/mm/aaaa"
                    :v-mask="['##/##/####']"
                    v-model="currentClient.created_at"
                    disabled
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6">
                  <span class="label">
                    CPF/CNPJ
                    <strong style="color: #bc0000">*</strong>
                  </span>
                <v-text-field
                    dense
                    outlined
                    placeholder="000.000.000-00"
                    :rules="[
                      rules.required,
                      rules.validateCPF,
                      rules.validateCNPJ,
                    ]"
                    v-mask="
                      this.cnpjLength() <= 14
                        ? '###.###.###-##'
                        : '##.###.###/####-##'
                    "
                    v-model="currentClient.cnpj"
                    required
                    @keydown.enter="submit()"
                />
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6">
                  <span class="label">
                    Telefone
                    <strong style="color: #bc0000">*</strong>
                  </span>
                <v-text-field
                    dense
                    outlined
                    placeholder="(00) 00000-0000"
                    v-mask="['(##) #####-####']"
                    :rules="[rules.required, rules.min]"
                    v-model="currentClient.phoneNumber"
                    required
                    @keydown.enter="submit()"
                />
              </v-col>
            </v-row>

            <v-row class="pa-4" style="justify-content: right">
              <v-btn
                  :loading="loading"
                  class="secondary-button"
                  @click="submit()"
              >
                Salvar
              </v-btn>
            </v-row>
          </v-form>

          <!--Endereços Salvos -->
          <v-form v-if="step === 1" :key="clear" ref="secondForm">
            <v-row class="px-4" v-for="(itemx, i) in addresses" :key="i">
              <v-col class="py-0 ma-0 endereco" style="" :key="componentKey">
                <div class="address">
                    <span class="addressNickName">{{
                        itemx.addressNickName
                      }}</span>
                  <div class="addressName">
                    {{
                      itemx.addressName +
                      ", nº:" +
                      itemx.addressNumber +
                      ", " +
                      itemx.neighborhood +
                      " - " +
                      itemx.city +
                      " - " +
                      itemx.state +
                      " - " +
                      itemx.country +
                      " - CEP: " +
                      itemx.postalCode
                    }}
                  </div>
                </div>

                <div>
                  <v-btn
                      fab
                      tile
                      style="border-radius: 6px"
                      small
                      elevation="0"
                      class="table-button ma-1 edit-button"
                      @click="edit(itemx)"
                      color="#FFFFFF"
                  >
                    <v-icon color="#C4C4C4" dark>mdi-pencil-outline</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <p class="step-title texto-novo-endereco">
              Cadastrar novo endereço
            </p>
            <!-- Novo Endereço -->
            <v-row
                class="px-6 pt-0"
                style="place-item: center; justify-content: right"
            >
              <v-col
                  class="pa-0 ma-0"
                  style="text-align: left; padding-left: 18px !important"
                  cols="12"
                  md="12"
                  sm="12"
              >
                  <span class="label-cep"
                  >CEP <b style="color: #bc0000">*</b></span
                  >
                <a
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="_blank"
                    class="pa-2 link-cep"
                    style=""
                >
                  <b>
                    <u>Não sei meu CEP</u>
                  </b>
                </a>
              </v-col>
            </v-row>

            <v-row
                class="px-7 py-0"
                style="place-item: center; justify-content: right"
            >
              <v-col
                  class="d-flex"
                  style="align-items: center"
                  cols="6"
                  md="6"
                  sm="6"
              >
                <v-text-field
                    type="text"
                    height="50"
                    class="inputCep primary-input-b2c cepLandscape"
                    outlined
                    placeholder="00000-000"
                    v-mask="'#####-###'"
                    v-model="postalCode"
                    hide-details
                    required
                    @keydown.enter="newAddressCEP()"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6" sm="6">
                <v-btn
                    height="60"
                    class="secondary-button"
                    @click="newAddressCEP()"
                >Adicionar novo endereço</v-btn
                >
              </v-col>
            </v-row>
          </v-form>

          <!--Senha -->
          <v-form v-if="step === 2" :key="clear" ref="pass">
            <v-row class="pa-6 ma-0">
              <v-col cols="12" sm="12" md="4" lg="4">
                  <span class="label">
                    Senha atual
                    <strong style="color: #bc0000">*</strong>
                  </span>
                <v-text-field
                    color="normalred"
                    dense
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass ? 'text' : 'password'"
                    outlined
                    v-model="password"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    @click:append="showPass = !showPass"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="4" lg="4">
                  <span class="label">
                    Nova senha
                    <strong style="color: #bc0000">*</strong>
                  </span>
                <v-text-field
                    color="normalred"
                    dense
                    :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showNewPass ? 'text' : 'password'"
                    outlined
                    v-model="newPassword"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    @click:append="showNewPass = !showNewPass"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="4" lg="4">
                  <span class="label">
                    Confirma sua nova senha
                    <strong style="color: #bc0000">*</strong>
                  </span>
                <v-text-field
                    color="normalred"
                    dense
                    :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showConfirm ? 'text' : 'password'"
                    outlined
                    :rules="[(v) => v == newPassword || 'Senhas não batem']"
                    v-model="confirmPassword"
                    @click:append="showConfirm = !showConfirm"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pa-4" style="justify-content: right">
              <v-btn
                  :loading="loading"
                  class="secondary-button"
                  @click="submit()"
              >
                Salvar
              </v-btn>
            </v-row>
          </v-form>
        </v-card>

        <!-- Historico de Compras -->
        <v-row
            cols="12"
            :v-if="orders.length > 0"
            class=""
            v-for="(order, idx) in orders"
            :key="idx"
        >
          <v-col v-if="step === 3" class="" :key="componentKey">
            <PreviousOrders
                :order="order"
                :keyId="order.id"
                :list="true"
                @loadOrders="getOrderItems"
                @changeclearDialog="clearDialog = true"
            />
          </v-col>
        </v-row>

        <div
            style="text-align-last: center"
            v-if="orders.length <= 0"
            :key="componentKey"
            class="py-10 my-10"
        >
          <span class="empty-message pa-0 ma-0">Sem pedidos</span><br />
          <span class="empty-label pa-0 ma-0"
          >Você ainda não fez a sua primeira compra.</span
          >
        </div>
      </v-col>
    </v-row>


    <!-- MOBILE -->
    <v-container v-if="isMobile" class="pa-0 ma-0">
      <v-list v-if="showMenuMobile">
        <v-list-item
          v-for="(item, i) in menu"
          :key="item.title"
          style="place-items: center; border-bottom: outset"
        >
          <template v-slot="{ active, toggle }">
            <v-row
              dense
              style="place-items: center"
              :color="active ? 'primary' : ''"
              class="pa-0 ma-0 py-7"
              @click="toggle(), stepChangeMobile(i)"
            >
              <v-col class="ma-0" cols="2" sm="2" md="2">
                <v-icon large color="black" v-text="item.icon"></v-icon>
              </v-col>

              <v-col
                style="text-align: left"
                class="ma-0 pa-0"
                cols="8"
                sm="8"
                md="8"
              >
                <v-card-title class="texto-mobile ma-0 pa-0">{{
                  item.text
                }}</v-card-title>
              </v-col>
            </v-row>
          </template>
        </v-list-item>
      </v-list>

      <div style="text-align: left" class="ma-2 px-1 py-2">
        <span
          v-if="step === 0"
          class="step-title-mobile"
          @click="backMenuMobile()"
        >
          <v-icon color="#000000">mdi-arrow-left</v-icon>&ensp;&ensp;Informações
          pessoais
        </span>

        <span
          v-if="step === 1"
          class="step-title-mobile"
          @click="backMenuMobile()"
        >
          <v-icon color="#000000">mdi-arrow-left</v-icon>&ensp;&ensp;Seus
          endereços salvos
        </span>

        <span
          v-if="step === 2"
          class="step-title-mobile"
          @click="backMenuMobile()"
        >
          <v-icon color="#000000">mdi-arrow-left</v-icon>&ensp;&ensp;Senha</span
        >

        <span
          v-if="step === 3"
          class="step-title-mobile"
          @click="backMenuMobile()"
        >
          <v-icon color="#000000">mdi-arrow-left</v-icon>&ensp;&ensp;Histórico
          de compras
        </span>
      </div>

      <!-- <div
        v-if="step === 0"
        class="d-flex flex-column align-center justify-center"
      >
        <v-col cols="12" sm="12" md="12" lg="12">
          <div
            :key="componentKey"
            class="d-flex flex-column align-center justify-center"
          >
            <v-hover v-slot="{ hover }" v-if="currentClient.image != null">
              <v-img
                :src="showPhoto(currentClient.image)"
                style="
                  border-radius: 50% !important;
                  opacity: 0.6;
                  cursor: pointer;
                "
                max-height="170"
                max-width="170"
                :class="hover ? 'elevation-5 mx-2' : 'mx-2'"
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex flex-column justify-center align-center"
                    style="height: 100%; background: rgba(0, 0, 0, 0.2)"
                    @click="openPicker"
                  >
                    <v-icon size="32" color="white">mdi-pen</v-icon>
                    <span class="edit-photo">Alterar foto</span>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-hover>

            <div
              class="d-flex flex-column align-center"
              style="max-width: 120px"
              v-if="currentClient.image == null"
            >
              <v-hover v-slot="{ hover }">
                <div
                  class="image-container mx-2"
                  @click="openPicker"
                  :style="hover ? 'background: #C4C4C4' : ''"
                >
                  <v-img
                    src="../../../assets/user.svg"
                    contain
                    max-width="40%"
                    v-if="!hover"
                  >
                  </v-img>
                  <div v-if="hover" class="d-flex flex-column align-center">
                    <v-img
                      src="../../../assets/add_photo.svg"
                      contain
                      max-width="46"
                    >
                    </v-img>
                    <span class="photo-field" v-if="hover">Adicionar Foto</span>
                  </div>
                </div>
              </v-hover>
            </div>
          </div>
        </v-col>
      </div> -->

      <!--Informação Pessoais -->
      <update-profile v-if="step === 0 && user.type === 3" />
      <v-form v-else-if="step === 0 && user.type !== 3" class="form" :key="clear" ref="firstForm">
        <v-row style="justify-content: space-between" class="pa-3 ma-0 px-4">
        </v-row>
        <v-row class="pa-0 ma-0 px-3 pb-7">
          <v-col cols="12" sm="12" md="12" lg="12">
            <div
                :key="componentKey"
                class="d-flex flex-column align-center justify-center"
            >
              <v-hover
                  v-slot="{ hover }"
                  v-if="currentClient.image"
              >
                <v-img
                    :src="currentClient.image"
                    style="
                          border-radius: 50% !important;
                          opacity:0.8;
                          cursor: pointer;
                        "
                    height="150"
                    width="150"
                    :class="hover ? 'elevation-5 mx-2' : 'mx-2'"
                >
                  <v-expand-transition>
                    <div
                        v-if="hover"
                        class="
                              d-flex
                              flex-column
                              justify-center
                              align-center
                            "
                        style="
                              height: 100%;
                              background: rgba(188, 0, 0, 0.4);
                            "
                        @click="openPicker"
                    >
                      <v-icon size="26" color="white">mdi-pen</v-icon>
                      <span class="edit-photo">Alterar foto</span>
                    </div>
                  </v-expand-transition>
                </v-img>
              </v-hover>

              <div
                  class="d-flex flex-column align-center"
                  style="max-width: 150px"
                  v-else
              >
                <v-hover v-slot="{ hover }">
                  <div
                      class="image-container mx-2"
                      @click="openPicker"
                      :style="hover ? 'background: #C4C4C4' : ''"
                  >
                    <v-img
                        src="../../../assets/imp.svg"
                        contain
                        max-width="157"
                        v-if="!hover"
                    >
                    </v-img>
                    <div
                        v-if="hover"
                        class="d-flex flex-column align-center"
                    >
                      <v-img
                          src="../../../assets/add_photo.svg"
                          contain
                          max-width="46"
                      >
                      </v-img>
                      <span class="photo-field" v-if="hover"
                      >Adicionar Foto</span>
                    </div>
                  </div>
                </v-hover>
              </div>
            </div>
          </v-col>

          <v-col style="text-align: left" cols="12" sm="12" md="6" lg="6">
            <span class="label">
              Nome e sobrenome
              <strong style="color: #bc0000">*</strong>
            </span>
            <v-text-field
              dense
              hide-details
              outlined
              placeholder="Seu nome"
              v-model="currentClient.name"
              :rules="[rules.required]"
              required
              @keydown.enter="submit()"
            ></v-text-field>
          </v-col>

          <v-col style="text-align: left" cols="12" sm="12" md="6" lg="6">
            <span class="label"> Data de cadastro </span>
            <v-text-field
              dense
              hide-details
              outlined
              placeholder="dd/mm/aaaa"
              :v-mask="['##/##/####']"
              v-model="currentClient.created_at"
              disabled
            ></v-text-field>
          </v-col>

          <v-col style="text-align: left" cols="12" sm="12" md="6" lg="4">
            <span class="label">
              CPF
              <strong style="color: #bc0000">*</strong>
            </span>
            <v-text-field
              v-if="currentClient"
              dense
              hide-details
              outlined
              placeholder="000.000.000-00"
              :rules="[rules.validateCPF, rules.validateCNPJ]"
              v-mask="
                this.cnpjLength() <= 14
                  ? '###.###.###-##'
                  : '##.###.###/####-##'
              "
              v-model="currentClient.cnpj"
              required
              @keydown.enter="submit()"
            />
          </v-col>

          <v-col style="text-align: left" cols="12" sm="12" md="6" lg="4">
            <span class="label">
              Telefone
              <strong style="color: #bc0000">*</strong>
            </span>
            <v-text-field
              dense
              hide-details
              outlined
              placeholder="(00) 00000-0000"
              :rules="[rules.min]"
              v-mask="['(##) #####-####']"
              v-model="currentClient.phoneNumber"
              required
              @keydown.enter="submit()"
            />
          </v-col>
          <v-col style="text-align: left" cols="12" sm="12" md="6" lg="4">
            <v-btn
              block
              :loading="loading"
              class="secondary-button py-5 my-2"
              @click="submit()"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <!--Endereços Salvos -->
      <v-form v-if="step === 1" :key="clear" ref="secondForm">
        <v-row
          style="background: #ffffff"
          class="px-4"
          v-for="(itemx, i) in addresses"
          :key="i"
        >
          <v-col class="py-0 ma-0 endereco" style="" :key="componentKey">
            <div class="address">
              <span class="addressNickName">{{ itemx.addressNickName }}</span>
              <div class="addressName">
                {{
                  itemx.addressName +
                  ", nº:" +
                  itemx.addressNumber +
                  ", " +
                  itemx.neighborhood +
                  " - " +
                  itemx.city +
                  " - " +
                  itemx.state +
                  " - " +
                  itemx.country +
                  " - CEP: " +
                  itemx.postalCode
                }}
              </div>
            </div>

            <div>
              <v-btn
                fab
                tile
                style="border-radius: 6px"
                small
                elevation="0"
                class="table-button ma-1 edit-button"
                @click="edit(itemx)"
                color="#FFFFFF"
              >
                <v-icon color="#C4C4C4" dark>mdi-pencil-outline</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <p class="step-title texto-novo-endereco">Cadastrar novo endereço</p>
        <!-- Novo Endereço -->
        <v-row
          class="px-6 pt-0"
          style="place-item: center; justify-content: right"
        >
          <v-col
            class="pa-0 ma-0"
            style="text-align: left; padding-left: 18px !important"
            cols="12"
            md="12"
            sm="12"
          >
            <span class="label-cep">CEP <b style="color: #bc0000">*</b></span>
            <a
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
              class="pa-2 link-cep"
              style=""
            >
              <b>
                <u>Não sei meu CEP</u>
              </b>
            </a>
          </v-col>
        </v-row>

        <v-row
          class="px-7 py-0"
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
        >
          <v-col class="d-flex" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              type="text"
              height="50"
              class="inputCep primary-input-b2c cepLandscape"
              outlined
              placeholder="00000-000"
              v-mask="'#####-###'"
              v-model="postalCode"
              hide-details
              required
              @keydown.enter="newAddressCEP()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <v-btn
              width="100%"
              height="60"
              class="secondary-button"
              @click="newAddressCEP()"
              >Adicionar novo endereço</v-btn
            >
          </v-col>
        </v-row>
      </v-form>

      <!--Senha -->
      <v-form v-if="step === 2" :key="clear" ref="pass">
        <v-row
          style="text-align: left; background-color: white"
          class="pa-0 ma-0 px-3"
        >
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            style="margin-bottom: -30px !important"
          >
            <span class="label">
              Senha atual
              <strong style="color: #bc0000">*</strong>
            </span>
            <v-text-field
              color="normalred"
              dense
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              outlined
              v-model="password"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              @click:append="showPass = !showPass"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            style="margin-bottom: -30px !important"
          >
            <span class="label">
              Nova senha
              <strong style="color: #bc0000">*</strong>
            </span>
            <v-text-field
              color="normalred"
              dense
              :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPass ? 'text' : 'password'"
              outlined
              v-model="newPassword"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              @click:append="showNewPass = !showNewPass"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            style="margin-bottom: -30px !important"
          >
            <span class="label">
              Confirma sua nova senha
              <strong style="color: #bc0000">*</strong>
            </span>
            <v-text-field
              color="normalred"
              dense
              :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirm ? 'text' : 'password'"
              outlined
              :rules="[(v) => v == newPassword || 'Senhas não batem']"
              v-model="confirmPassword"
              @click:append="showConfirm = !showConfirm"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4">
            <v-btn
              block
              :loading="loading"
              class="secondary-button"
              @click="submit()"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <!-- Historico de Compras -->
      <div v-if="orders.length > 0 && step === 3">
        <v-row
          class="ma-0 pa-0 py-3"
          v-for="order in orders"
          :key="order.productId"
        >
          <v-col class="pa-0 ma-0">
            <v-hover v-slot="{ hover }">
              <PreviousOrders
                :class="{ 'on-hover': hover }"
                :order="order"
                :keyId="order.id"
                :list="true"
                @changeclearDialog="clearDialog = true"
              />
            </v-hover>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-main>
</template>

<style scoped>
.complete_cadastro{
  color: #bc0000;
  font-weight: 700;
  font-size: 17px;
}
.link-cep {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: #bc0000;
}
.label-cep {
  color: #979797;
  font-size: 14px;
  font-weight: bold;
}
.texto-novo-endereco {
  padding: 10px 30px 0;
}
.endereco {
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.address {
  text-align: start;
  padding: 20px;
}
.addressNickName {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #2d3038;
}
.addressName {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #979797;
}
/* MOBILE */
.edit-photo {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #ffffff;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 170px;
  background: #e2e2e2;
  cursor: pointer;
  transition: 0.1s;
  box-sizing: border-box;
  border-radius: 50% !important;
}

.delete-photo {
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #bc0000;
}

.form {
  background-color: white;
}

.texto-mobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;

  color: #2d3038;
}

/* DESKTOP */
.empty-message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 3.2rem;

  color: #97999d;
}

.empty-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;

  color: #97999d;
}

.infos-card {
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
  border-radius: 10px;
}

.radio {
  border-bottom: ridge;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #979797;
}

.radio:hover {
  background-color: #ddd;
}

.radio-pressed {
  background-color: #ddd;
}

.radio-align {
  left: 0px !important;
  right: auto !important;
  position: relative !important;
  flex-flow: column !important;
  align-items: start !important;
}

.step-title {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  color: #2d3038 !important;
}

.step-title-mobile {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 21px !important;
  color: #2d3038 !important;
  margin-left: 15px !important;
}

.subtitle {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 0.9rem !important;
  line-height: 18px !important;

  color: #2d3038 !important;
}

.card-profile {
  width: 100% !important;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
  border-radius: 10px !important;
  text-align: center;
  font-family: Roboto,sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  display: flex !important;
  flex-direction: column !important;
  color: #2d3038;
  cursor: pointer;
}

.card-profile:hover {
  box-shadow: 0px 10px 30px rgba(45, 48, 56, 0.1) !important;
}

@media (orientation: landscape) {
}

.mainProfile {
  display: flex !important;
  max-width: unset !important;
  padding-top: 0px !important;
  text-align: -webkit-center !important;
}

@media (min-width: 600px) {
  .mainProfile {
    display: flex !important;
    max-width: unset !important;
    padding-top: 0px !important;
  }
}
@media (max-width: 960px) {
  .texto-novo-endereco {
    text-align: start;
  }
}

.label {
  font-weight: bold;
  font-size: 0.95rem;
  line-height: 124.4%;
  color: #c4c4c4;
}
</style>

<script>
import ApiService from "../../../services/ApiService";
import PreviousOrders from "../components/PreviousOrders.vue";
import Vue from "vue";
import Utils from "../../../Utils/ValidateCPFCNPJ";
import UtilsDate from "../../../Utils/FormatDate";
import moment from "moment";
import UpdateProfile from "./components/update-profile.vue";

export default {
  name: "Profile",

  components: {
    PreviousOrders,
    UpdateProfile,
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  data: () => ({
    currentClient: {
      cnpj: "",
    },
    dropdown: false,
    orders: [],
    step: null,
    showMenu: true,
    showMenuMobile: true,
    orderDetail: "",
    showNewPass: false,
    showConfirm: false,
    password: "",
    newPassword: "",
    confirmPassword: "",
    showPass: false,
    radios: null,
    selected: [],
    addresses: [],
    postalCode: "",
    cpf: "",
    componentKey: 0,
    endereco: "",
    clear: false,
    active: false,
    loading: false,
    isMobile: false,
    user: null,
    apiService: new ApiService(),
    menu: [
      {
        text: "Informações pessoais",
        subtitle: "Ver e alterar sua informações",
        icon: "mdi-calendar-account-outline",
        step: 0,
      },
      {
        text: "Endereços salvos",
        subtitle: "Alterar e cadastrar endereços",
        icon: "mdi-home-outline",
        step: 1,
      },
      {
        text: "Senhas",
        subtitle: "Alterar sua senha",
        icon: "mdi-form-textbox-password",
        step: 2,
      },
      {
        text: "Histórico de compras",
        subtitle: "Ver suas compras ralizadas",
        icon: "mdi-history",
        step: 3,
      },
    ],
    rules: {
      min: (value) => (value ? value.length >= 14 : "Telefone invalido."),
      required: (value) => !!value || "Campo requerido.",
      validateCPF: (value) =>
        value
          ? Utils.CPF(value) || value.toString().replace(/\D/g, "").length > 11
          : "Cpf Inválido",
      validateCNPJ: (value) =>
        value
          ? Utils.CNPJ(value) ||
            value.toString().replace(/\D/g, "").length <= 11
          : "Cnpf Inválido",
    },
  }),

  computed: {
    needTtoCompleteRegistration(){
      const {name, cnpj, phoneNumber} = this.currentClient
      return !(name && name.toString().length > 0 && (Utils.CPF(cnpj) || Utils.CNPJ(cnpj)) && phoneNumber && phoneNumber.toString().length > 14);
    }
  },

  async created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if(this.user) {
      //this.step = 0;
      //Bring Client information to "Informações pessoais"
      await this.getClientById();

      //Bring addresses to "Endereços Salvos"
      await this.getAddresses(this.currentClient);

      // Bring products from localhost
      this.cart = JSON.parse(localStorage.getItem("cartUser") || "[]");

      // Bring products from API
      await this.getOrderItems();

      if (this.$route.query.profileAdd === true) {
        this.step = 1;
      }

      if (this.step !== 1) {
        this.showMenu = true;
        const steplocalStorage = localStorage.getItem("step");
        this.step = steplocalStorage ? Number(steplocalStorage) : 0;
      } else {
        this.showMenu = false;
      }
    } else {
      this.goToRoute("/login");
    }
  },

  methods: {
    cnpjLength() {
      if (!this.currentClient.cnpj) {
        return 0;
      }
      return this.currentClient.cnpj.length;
    },

    parseDate(date) {
      return UtilsDate.moment(date);
    },
    async getClientById() {
      const id = JSON.parse(localStorage.getItem("user")).id;

      await this.apiService
        .get(`user/get-one?id=${id}`)
        .then((response) => {
          this.currentClient = response;
          this.currentClient.created_at = this.parseDate(
            this.currentClient.created_at
          );
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Não foi possivel remover este item.",
            message: err.body.message,
            trace: null,
          });
        });
    },

    async getOrderItems() {
      await this.apiService
        .get("client/get-items/" + this.currentClient.id)
        .then((response) => {
          this.orders = response;
          this.componentKey = this.componentKey + 1;
          this.loading = false;
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Não foi possivel carrega o histórico de compras",
            message: "Não foi possivel carrega o histórico de compras",
            trace: null,
          });

          this.$refs.pass.reset();
          this.loading = false;
        });
    },

    backMenu() {
      this.showMenu = true;
      this.step = 99;
    },

    backMenuMobile() {
      this.showMenuMobile = true;
      this.step = 99;
    },

    setImage(image) {
      if (image.size < 2097152) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = () => {
          this.currentClient.image = reader.result;
          this.componentKey = this.componentKey + 1;
        };
      } else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "",
          message: "Arquivo excede o limite de 2 mb",
          trace: null,
        });
      }
    },

    openPicker() {
      document.querySelector("#imageInput").click();
    },

    showPhoto(image) {
      if (!!image && image.includes("Uploads/")) {
        const env_url = process.env.VUE_APP_BASE_URL;
        return env_url + image;
      } else {
        return image;
      }
    },

    async submit() {
      this.loading = true;
      if (this.step === 2) {
        if (this.$refs.pass.validate()) {
          const model = {
            id: this.currentClient.id,
            currentPassword: this.password,
            newPassword: this.newPassword,
          };
          await this.apiService
            .post("client/password/update", model)
            .then(() => {
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "success",
                title: "Senha alterada com sucesso",
                message: "Senha alterada com sucesso",
                trace: null,
              });

              this.$refs.pass.reset();
              this.logout();
              this.loading = false;
            })
            .catch((err) => {
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "error",
                title: "Erro",
                message: err.body.message,
                trace: null,
              });

              this.$refs.pass.reset();
              this.loading = false;
            });
        } else {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Formulário não validado",
            message: "Confira os campos preenchimentos!",
            trace: null,
          });

          this.loading = false;
        }
      } else {
        if (this.$refs.firstForm.validate()) {
          let model = {
            ...this.currentClient,
            created_at: moment(this.currentClient.created_at, "DD/MM/yyyy"),
          };
          let url = "client/editprofile";

          await this.apiService
            .put(url, model)
            .then((resp) => {
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "success",
                title: "Cadastro atualizado com sucesso",
                message: "Cadastro atualizado com sucesso",
                trace: null,
              });
              let userUpdate = this.currentClient
              const { user } = resp
              const { content } = user

              if (content && content.cnpj){
                userUpdate = content
              }
              this.$store.commit("SET_LOGGED_USER", JSON.stringify(userUpdate));

              this.loading = false;

              if (this.$route.query.cart) {
               this.$router.push('/cart')
              }
            })
            .catch((err) => {
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "error",
                title: "Erro ao atualizar cadastro",
                message: "Erro ao atualizar cadastro",
                trace: null,
              });

              this.loading = false;
            });
        } else {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Formulário não validado",
            message: "Preencha todos os campos corretamente",
            trace: null,
          });

          this.loading = false;
        }
      }
      this.loading = false;
      this.showMenu = false;
    },

    goToRoute(route) {
      if (this.$route.path != route) {
        this.$router.push(route);
      }
    },

    async logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("expires");
      localStorage.removeItem("user");
      localStorage.removeItem("step");
      localStorage.removeItem("cart");
      localStorage.removeItem("addressIdSelect");
      await this.$router.push("/home");
    },

    selectMainAddress(itemx) {
      this.selected = itemx;
    },

    async edit(itemx) {
      await this.$router.push({
        path: `/address?id=${itemx.id}`,
        params: [itemx.id, itemx.postalCode],
      });
    },

    async remove(itemx) {
      this.loading = true;
      await this.apiService
        .delete(`client/delete/` + itemx.id)
        .then((resp) => {
          this.getAddresses(this.currentClient);

          this.loading = false;
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Não foi possivel remover este item.",
            message: err.body.message,
            trace: null,
          });
          this.loading = false;
        });
    },

    async newAddressCEP() {
      await this.$router.push({
        path: "/address?cep=" + this.postalCode,
        params: this.postalCode,
      });
    },

    async getAddresses(user) {
      if (user) {
        await this.apiService
          .get(`client/get-addresses?id=` + user.id)
          .then((response) => {
            this.addresses = response;
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Não foi possível encontrar o endereço cadastrado",
              message: err.body.message,
              trace: null,
            });
          });
      } else {
      }
    },

    stepChange(arg) {
      // this.showMenu = false;
      this.step = arg;
      //Coloca o Step no localhost para quando o usuario voltar a page
      localStorage.setItem("step", JSON.stringify(this.step));
    },

    stepChangeMobile(arg) {
      this.showMenuMobile = false;
      this.step = arg;
      //Coloca o Step no localhost para quando o usuario voltar a page
      //localStorage.setItem("step", JSON.stringify(this.step));
    },

    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
  },
};
</script>
