import { render, staticRenderFns } from "./PlatformPolicies.vue?vue&type=template&id=77f63ece&scoped=true"
import script from "./PlatformPolicies.vue?vue&type=script&lang=js"
export * from "./PlatformPolicies.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f63ece",
  null
  
)

export default component.exports