<template>
  <div class="mainInstaller" style="text-align: -webkit-center">
    <v-row
      class="voltar-btn ma-0 pa-0 py-3"
      style="padding-left: 50px !important"
      @click="back()"
    >
      <v-icon class="voltar-btn">mdi-arrow-left</v-icon>voltar
    </v-row>
    <v-card v-if="step == 0" class="installers-card" :key="componentKey">
      <v-row style="text-align-last: left">
        <v-col cols="12" md="12" sm="12" class="px-10">
          <span class="main-title">Selecionar o tipo de aplicação que desejar </span
          ><br />
          <span>Você pode selecionar quantos tipos de especialidades preferir </span>
        </v-col>

        <v-col cols="12" md="12" sm="12" class="px-10">
          <v-icon class="d-flex-row justify-center" color="normalred">mdi-brain</v-icon>
          <span class="main-title" :key="componentKey"> Especialidades </span>
        </v-col>
        <v-col
          v-for="item in especialidades"
          :key="item.name"
          class="checkbox px-10"
          style="color: black !important; text-transform: uppercase"
        >
          <v-checkbox
            type="checkbox"
            class="ma-0 pa-0"
            v-model="item.checked"
            @change="updateBoxes"
            :value="item.checked"
            :checked="item.checked"
            :disabled="item.disabled"
          >
            >
            <template :for="item.name" v-slot:label>
              <span color="black">
                {{ item.name }}
              </span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row style="text-align-last: left">
        <v-col cols="12" md="12" sm="12" class="px-10">
          <span class="main-title"> Localização </span>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0 py-3 px-3">
        <v-col
          class="py-0 ma-0"
          style="border-top-style: groove; border-bottom-style: groove"
          :key="componentKey + 1"
        >
          <v-radio-group class="my-2" hide-details v-model="radios" mandatory>
            <v-radio
              v-for="(itemx, i) in addresses"
              active-class="radio-pressed"
              class="radio py-2"
              color="black"
              :value="itemx"
              @change="selectAddress(itemx)"
              :key="i"
            >
              <template v-slot:label>
                <v-row class="d-flex radio-align py-0">
                  <v-col class="py-0" cols="12" sm="6">
                    <div>
                      <strong class="secondary--text">{{
                        itemx.addressNickName.toUpperCase()
                      }}</strong>
                    </div>
                    <div>
                      {{
                        itemx.addressName +
                        ", nº:" +
                        itemx.addressNumber +
                        ", " +
                        itemx.neighborhood +
                        " - " +
                        itemx.city +
                        " - " +
                        itemx.state +
                        " - " +
                        itemx.country +
                        " - CEP: " +
                        itemx.postalCode
                      }}
                    </div>
                  </v-col>

                  <hr />
                </v-row>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row style="justify-content: center" class="pa-0 ma-0 py-3 px-3">
        <v-btn class="secondary-button" @click="$router.go(-1)">Voltar </v-btn>
        <v-btn class="primary-button" @click="next(1)">Continuar </v-btn>
      </v-row>
    </v-card>

    <v-card v-if="step" class="installers-card py-9" :key="componentKey + 1">
      <v-row style="text-align-last: left">
        <v-col class="px-10">
          <span class="main-title"
            >Aplicadores na sua área ({{ installers.length }})
          </span>
        </v-col>
      </v-row>

      <div v-if="installers.length > 0">
        <!-- Detalhe de um Pedido por OrderId-->
        <v-row class="pa-3 pb-0 ma-0 py-3 my-5">
          <v-col class="py-0 ma-0">
            <InstallersCard :installers="installers" />
          </v-col>
        </v-row>
        <p class="span-contato my-4">
          Entre em contato com um dos aplicadores da sua área para solicitar uma aplicação
        </p>
      </div>

      <div v-else>
        <div class="py-10 my-10">
          <span class="empty-message pa-0 ma-0">Não Localizado</span><br />
          <span class="empty-label pa-0 ma-0">Não encontramos nenhum aplicador com essa especilidade próximo a você.</span>
        </div>
      </div>

      <v-row class="">
        <div class="container-btn-actions">
          <v-btn class="secondary-button btn-action" @click="step = 0">Voltar </v-btn>
          <v-btn class="primary-button btn-action" @click="goToRoute('/home')">continuar comprando</v-btn>
        </div>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import ApiService from "../../../services/ApiService";

import InstallersCard from "../components/InstallersCard.vue";

import Vue from "vue";

export default {
  components: {
    InstallersCard,
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  data: () => ({
    selectedInstallers: [],
    ticks: [],
    radios: false,
    installers: [],
    step: 0,
    addresses: [],
    especialidades: [],
    dialogKey: false,
    componentKey: 0,
    loading: false,

    apiService: new ApiService(),

    filter: {
      page: 1,
      pageSize: 15,
      Specialties: [],
      Address: {}
    },
  }),

  computed: {},
  watch: {},

  async created() {
    this.cart = JSON.parse(localStorage.getItem("cartUser") || "[]");
    this.badge = JSON.parse(localStorage.getItem("cartBadge"));
    this.user = JSON.parse(localStorage.getItem("user"));
    this.addressIdSelect = localStorage.getItem("addressIdSelect");
    await this.getAddresses(this.user, this.addressIdSelect);
    await this.getSpecialties();
  },
  methods: {
    selectAddress(address){
      this.filter.Address = address;
    },
    back() {
      if (this.step == 0) {
        this.$router.go(-1);
      } else {
        this.step = 0;
        this.componentKey++;
      }
    },
    async getInstallers() {
      if(this.ticks.length > 0) {
        this.loading = true;
        this.filter.Specialties = [];
        this.ticks.forEach((x) => {
          this.filter.Specialties.push({
            "id": x.id,
            "code": x.code,
            "name": x.name
            })
        });
        await this.apiService.post(`installer/ListInstallerArea`, this.filter).then((response) => {
          this.installers = [];
          this.installers = response;
          this.step = 1;
          // (OU) CONDIÇÕES
          // this.ticks.forEach((t) => {
          //   this.installers.forEach((item) =>
          //     item.specialties.some((field) => {
          //       if (field.specialtiesId === t.id) {
          //         this.selectedInstallers.push(item);
          //       } else {
          //         return 0;
          //       }
          //     })
          //   );
          // });
          // (E) CONDIÇÕES
          // this.ticks.forEach((t) => {
          //   this.installers = this.installers.filter((item) =>
          //     item.specialties.some((field) => field.specialtiesId === t.id)
          //   );
          // });
          this.totalPages = this.installers.length;
          this.loading = false;
        });
      }else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Especialidades",
          message: 'Selecione ao menos uma especialidade!',
          trace: null,
        });
        return;
      }
    },

    async next() {
      this.componentKey++;
      await this.getInstallers();
    },
    async getAddresses(user) {
      this.loading = true;
      if (user) {
        await this.apiService
          .get(`client/get-addresses?id=` + user.id)
          .then((response) => {
            this.addresses = response;
            this.filter.Address = this.addresses[0]
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Não foi possível encontrar o endereço cadastrado",
              message: err.body.message,
              trace: null,
            });
          });
      } else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Sem usuário ou id",
          message: err.body.message,
          trace: null,
        });
      }

      this.loading = false;
    },

    async getSpecialties() {
      this.loading = true;
      await this.apiService
        .post(`specialties/list`, this.filter)
        .then((response) => {
          this.totalPages = response.pager.totalPages;
          this.especialidades = response.specialties;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.close();
        });
    },

    updateBoxes() {
      this.ticks = this.especialidades.filter((item) => item.checked);
    },

    dialogClear() {
      this.clearDialog = true;
    },

    goToRoute(route) {
      if (this.$route.path != route) {
        this.$router.push(route);
      }
    },

    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
  },
};
</script>

<style scoped>
.container-btn-actions{
  display: flex!important;
  width: 100%!important;
  margin: 0 30px!important;
}
.btn-action{
  width: 49%!important;
}
.span-contato {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 28px;
  margin-bottom: 10px;
  letter-spacing: 0.15px;

  color: #979797;
}
.voltar-btn {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  letter-spacing: 0.15px;

  color: #000000;

  text-align-last: left;
}
.voltar-btn:hover {
  cursor: pointer;
}

.empty-message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 3.2rem;

  color: #97999d;
}
.empty-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;

  color: #97999d;
}
.main-title {
  font-size: 1.3rem;
}

.installers-card {
  padding-top: 0 !important;
  margin-top: 0 !important;
  width: 90%;
  height: fit-content !important;
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
  border-radius: 6px;
}

@media (max-width: 768px) {
  .container-btn-actions{
    flex-direction: column!important;
  }
  .btn-action{
    width: 100%!important;
  }
}
</style>
