<template>
  <div>
    <desktop v-if="!$vuetify.breakpoint.mobile" />
    <mobile v-else />
  </div>
</template>

<script>
import desktop from "./components/order-status-desktop.vue";
import mobile from "./components/order-status-mobile.vue";

export default {
  name: "OrderStatusPage",

  components: {
    desktop,
    mobile,
  },
};
</script>

<style></style>
