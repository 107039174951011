<template>
  <v-main class="mainProfile" v-if="order">
    <!-- DESKTOP -->
      <v-row style="width: 80%!important;" cols="12" md="12" sm="12" lg="12" v-if="!isMobile" class="ma-0 pa-0" align="start">
        <v-col class="ma-0 pa-0 pr-4" cols="12" md="4" sm="4" lg="4">
          <v-item-group>
            <v-row cols="12">
              <v-col cols="12" class="py-2 ma-0" v-for="(item, i) in menu" :key="i">
                <v-item v-slot="{ active, toggle }">
                  <v-row
                    cols="12"
                    :color="active ? 'primary' : ''"
                    class="card-profile pa-0 ma-0"
                    @click="toggle(), stepChange(i)"
                  >
                    <v-row cols="12" class="pa-0 ma-0 py-3" style="place-items: center">
                      <v-col  class="ma-0 pa-1" cols="2" sm="2" md="2">
                        <v-icon
                          large
                          class="px-1"
                          v-text="item.icon"
                          :style="
                            item.step === step
                              ? 'color : #E30011'
                              : 'color : #000000'
                          "
                        ></v-icon>
                      </v-col>

                      <v-col
                        style="text-align: left"
                        class="ma-0 pa-1"
                        cols="8"
                        sm="8"
                        md="8"
                        :style="
                          item.step === step
                            ? 'color : #E30011'
                            : 'color : #000000'
                        "
                      >
                        <v-card-title class="ma-0 pa-0">{{
                          item.text
                        }}</v-card-title>
                        {{ item.subtitle }}
                      </v-col>

                      <v-col class="ma-0 pa-1" cols="2" sm="2" md="2">
                        <v-icon
                          :style="
                            item.step === step
                              ? 'color : #E30011'
                              : 'color : #000000'
                          "
                          class="px-2"
                          >mdi-chevron-right</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-row>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-col>

        <v-col
          class="ma-0 pa-0"
          style="text-align: left"
          cols="12"
          md="8"
          sm="8"
          lg="8"
        >
          <div class="ma-0 px-1">
            <span class="step-title" @click="$router.go(-1)"
              ><v-icon>mdi-chevron-left</v-icon>voltar</span
            >
          </div>
          <!-- Detalhe de um Pedido por OrderId-->
          <v-row class="" :key="product.productId">
            <v-col class="py-0 ma-0" :key="componentKey">
              <PreviousOrders
                :order="order"
                :item="product"
                :list:="false"
                @changeclearDialog="clearDialog = true"
                @loadOrder="getOrderById"
              />
            </v-col>
          </v-row>

          <v-row class="" cols="12" md="12" sm="12" lg="12">
            <!-- Total Pago -->
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
              <v-card class="cards ma-0 pa-0" height="auto">
                <v-col class="cabeca ma-0 pa-0 py-2 px-3" cols="12" md="12">
                  <div class="span-order">Total pago</div>
                  <div class="span-details">
                    <v-icon>mdi-chevron-up</v-icon>
                  </div>
                </v-col>
                <div class="px-1 pt-1 py-2">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="total-pago">
                            <span>Subtotal</span>
                            <br />
                            <span>Desconto</span>
                            <br />
                            <span>Frete</span>
                          </td>
                          <td class="total-pago-dados">
                            <span v-if="order.items">{{
                              calculateSubtotal().toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}</span>
                            <br />
                            <span>{{
                              order.discount.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}</span>
                            <br />
                            <span>{{
                              order.fretePrice.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}</span>
                          </td>
                        </tr>

                        <tr>
                          <td class="total-pago">Total</td>
                          <td class="total-pago-dados">
                            {{
                              (
                                order.totalPrice + order.fretePrice
                              ).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-card>
            </v-col>

            <!--Meio de Pagamento-->
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
              <v-card class="card ma-0 pa-0" height="auto" >
                <v-row class="pa-0 ma-0 py-0">
                  <v-col class="cabeca ma-0 pa-0 py-2 px-3" cols="12" md="12">
                    <div class="span-order">Pagamento</div>
                    <div class="span-details">
                      <v-icon>mdi-chevron-up</v-icon>
                    </div>
                  </v-col>
                </v-row>

                <div
                  v-if="order.paymentMethod === bankSlip"
                  class="ma-0 pa-0 py-5"
                >
                  <div style="display: inline-flex; text-align: end">
                    <v-img
                      class="mx-2"
                      src="../../../assets/boleto.svg"
                      width="70"
                      height="50"
                      alt="boleto"
                      title="boleto"
                    >
                    </v-img>
                    <h3>
                      {{
                        (order.totalPrice + order.fretePrice).toLocaleString(
                          "pt-br",
                          { style: "currency", currency: "BRL" }
                        )
                      }}
                    </h3>
                  </div>
                  <div
                    class="mx-2"
                    v-if="order.orderStatus !== pedidoCancelado"
                  >
                    <a class="text-left" :href="order.urlBoleto" target="_blank"
                      >Acessar boleto</a
                    >
                  </div>
                </div>

                <div v-if="order.paymentMethod === pix" class="ma-0 pa-0 py-5">
                  <div class="d-flex align-start">
                    <div class="d-flex">
                      <v-img
                          class="mx-2"
                          src="../../../assets/pix-icon.svg"
                          width="30" height="30" alt="Pix" title="Pix"
                      >
                      </v-img>
                      <h3 class="mt-2">{{ (order.totalPrice + order.fretePrice).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }} </h3>
                    </div>
                    <div class="ml-3 mt-2" v-if="showQrCodePix()">
                      <a :href="order.qrCodeUrlPix" target="_blank"><strong>Acessar Qr Code</strong></a>
                    </div>
                  </div>
                  <div class="content_copy_qrcode" v-if="showQrCodePix()">
                    <p><strong>Copie este código para pagar</strong></p>
                    <p>
                      <span><strong>1.</strong> Acesse seu Internet Banking ou app de pagamentos.</span> <br/>
                      <span><strong>2.</strong> Escolha pagar via Pix.</span> <br/>
                      <span><strong>3.</strong> Cole o seguinte código:</span> <br/>
                    </p>

                    <p id="qrCodePix">{{order.qrCodePix}}</p>
                    <div class="mr-3 mb-2">
                      <v-text-field
                          dense
                          hide-details
                          outlined
                          placeholder="Nome e sobrenome"
                          :disabled="true"
                          v-model="order.qrCodePix">
                      </v-text-field>
                      <p class="my-5"> <v-icon >mdi-clock-check-outline</v-icon> <strong class="ml-2">Pague e será creditado na hora</strong></p>
                      <v-btn
                          style="border-radius: 4px!important;"
                          align="start"
                          class="secondary-input"
                          elevation="0"
                          height="47"
                          large
                          width="100%"
                          @click="copyQrcode('qrCodePix')">
                        Copiar código
                      </v-btn>
                    </div>
                    <img alt="Qr Code Pix" title="Qr Code Pix" :src="order.qrCodeUrlPix"/>
                  </div>
                </div>

                <div
                  v-else
                  class="ma-0 pa-0 py-5"
                  style="display: inline-flex; text-align: start"
                >
                  <div>
                    <v-img
                      v-if="order.brandCard"
                      v-bind:src="
                        require('../../../assets/' + order.brandCard + '.svg')
                      "
                      width="100"
                      height="50"
                      :alt="order.brandCard"
                      :title="order.brandCard"
                    >
                    </v-img>
                    <p style="text-align: center; font-size: 12px">
                      {{ order.brandCard }}
                    </p>
                  </div>
                  <div>
                    {{ order.numberCardFlags }}
                    <div>
                      {{
                        (order.totalPrice + order.fretePrice).toLocaleString(
                          "pt-br",
                          { style: "currency", currency: "BRL" }
                        )
                      }}
                      em {{ order.creditInstallments }}x
                    </div>
                  </div>
                </div>

                <div class="mx-3">
                  <p>
                    Status: <b>{{ statusPagamento() }}</b>
                  </p>
                </div>
              </v-card>
            </v-col>

            <!--Endereço-->
            <v-col cols="12" md="12">
              <v-card class="card ma-0 pa-0" height="180">
                <v-row class="pa-0 ma-0 py-0">
                  <v-col class="cabeca ma-0 pa-0 py-3 px-3" cols="12" md="12">
                    <div class="span-order">
                      Endereço {{ order.addressName }}
                    </div>
                    <div class="span-details">
                      <v-icon>mdi-chevron-up</v-icon>
                    </div>
                  </v-col>
                  <div class="ma-0 pa-0 py-4 px-3" style="text-align: start">
                    <div>{{ order.address }}</div>
                  </div>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card>
                <div class="pa-4 grey lighten-4 span-order">Linha do tempo</div>
                <div
                  class="pa-4 d-flex justify-center align-center statusList"
                  style="overflow-x: auto"
                >
                  <div
                    class="d-flex"
                    style="min-width: 170px"
                    v-for="(status, i) in orderStatusList.filter((x) =>
                      [0, 3, 4, 7, 8].includes(x.status)
                    )"
                    :key="status.id"
                  >
                    <div class="d-flex flex-column justify-center align-center">
                      <v-btn
                        style="border: 2px solid #00ad40 !important"
                        fab
                        depressed
                        color="#30c858"
                        class="ma-0 mb-2"
                      >
                        <v-icon size="30">{{
                          getStatusIcon(status.status)
                        }}</v-icon>
                      </v-btn>
                      <span class="status-text">
                        {{ getStatusName(status.status) }}
                      </span>
                      <span class="status-text">
                        {{ formatDate(status.created_at) }}
                      </span>

                      <v-btn
                        v-if="order.trackingLink && status.status === 4"
                        :href="order.trackingLink"
                        outlined
                        color="#30c858"
                        small
                        tile
                        style="border-bottom: 3px solid #30c858"
                      >
                        Rastrear
                      </v-btn>
                    </div>
                    <v-divider
                      class="mt-7"
                      style="
                        min-width: 100px;
                        border: 2px solid #00ad40;
                        margin: auto -40px;
                      "
                      v-if="
                        i !=
                        order.orderStatusList.filter((x) =>
                          [0, 3, 4, 7, 8].includes(x.status)
                        ).length -
                          1
                      "
                    ></v-divider>
                  </div>
                </div>
              </v-card>
            </v-col>

            <!--Endereço da revenda-->
            <!--<v-col cols="12" md="6">
              <v-card class="card ma-0 pa-0" height="180">
                <v-row class="pa-0 ma-0 py-0">
                  <v-col class="cabeca ma-0 pa-0 py-3 px-3" cols="12" md="12">
                    <div class="span-order">Endereço da revenda</div>
                    <div class="span-details">
                      <v-icon>mdi-chevron-up</v-icon>
                    </div>
                  </v-col>

                  <div class="ma-0 pa-0 py-4 px-3" style="text-align: start">
                    <span class="pa-0 ma-0">{{ order.resale.neighborhood }}, {{ order.resale.city }}, {{ order.resale.state }}, {{ order.resale.country }} - CEP: {{ order.resale.postalCode }} </span>
                    <br />
                    <span class="pa-0 ma-0"> <strong> E-mail:</strong> {{ order.resale.email }} </span>
                    <br />
                    <span class="pa-0 ma-0">
                      <strong>Telefone:</strong>{{ order.resale.phoneNumber }}
                    </span>
                  </div>
                </v-row>
              </v-card>
            </v-col>-->

            <!--Informações do envio-->
            <v-col ccols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-card class="card">
                <v-col class="cabeca ma-0 pa-0 py-2 px-3" cols="12" md="12">
                  <div class="span-order">Informações do envio</div>
                  <div class="span-details">
                    <v-icon>mdi-chevron-up</v-icon>
                  </div>
                </v-col>
                <div class="">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="informacoes-cliente">
                            Código de rastreio
                          </td>
                          <td class="informacoes-cliente-dados">
                            <span v-if="order.trackingCode">{{
                              order.trackingCode
                            }}</span>
                            <span v-if="!order.trackingCode">Sem Registro</span>
                          </td>
                        </tr>

                        <tr>
                          <td class="informacoes-cliente">Transportadora</td>
                          <td class="informacoes-cliente-dados">
                            <span v-if="order.companyName">{{
                              order.companyName
                            }}</span>
                            <span v-if="!order.companyName">Sem Registro</span>
                          </td>
                        </tr>

                        <tr>
                          <td class="informacoes-cliente">
                            Link para acompanhar
                          </td>
                          <td class="informacoes-cliente-dados">
                            <a
                              v-if="order.trackingLink"
                              :href="order.trackingLink"
                              target="_blank"
                              >Rastrear</a
                            >
                            <span v-if="!order.trackingLink">Sem Registro</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <br />
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    <!-- MOBILE -->
    <v-container v-if="isMobile" class="pa-0 ma-0">
      <v-row class="ma-0 pa-0 py-3">
        <div class="ma-0 px-1 py-2">
          <span class="step-title py-10" @click="$router.go(-1)">
            <v-icon size="25" color="#000000">mdi-arrow-left</v-icon
            >&ensp;&ensp;Detalhes do pedido: {{ order.orderCode }}
          </span>
        </div>

        <v-col class="py-1 ma-0" :key="componentKey">
          <PreviousOrders
            :order="order"
            :item="product"
            :list="false"
            @changeclearDialog="clearDialog = true"
          />
        </v-col>

        <v-row class="ma-0 pa-0" cols="12" md="12" sm="12" lg="12" ca>
          <!-- Total Pago -->
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-card class="cards sombra ma-0 pa-0" height="160">
              <v-col class="cabeca ma-0 pa-0 py-2 px-3" cols="12" md="12">
                <div class="span-order">Total pago</div>
                <div class="span-details">
                  <v-icon>mdi-chevron-up</v-icon>
                </div>
              </v-col>
              <div class="px-1 pt-1 py-2">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="total-pago">
                          <span>Subtotal</span>
                          <br />
                          <span>Desconto</span>
                          <br />
                          <span>Frete</span>
                        </td>
                        <td class="total-pago-dados">
                          <span v-if="order.items">{{
                            calculateSubtotal().toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                          }}</span>
                          <br />
                          <span>{{
                            order.discount.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                          }}</span>
                          <br />
                          <span>{{
                            order.fretePrice.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                          }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="total-pago">Total</td>
                        <td class="total-pago-dados">
                          {{
                            (
                              order.totalPrice + order.fretePrice
                            ).toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-card>
          </v-col>

          <!--Meio de Pagamento-->
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-card class="card sombra ma-0 pa-0" >
              <v-row class="pa-0 ma-0 py-0">
                <v-col class="cabeca ma-0 pa-0 py-2 px-3" cols="12" md="12">
                  <div class="span-order">Pagamento</div>
                  <div class="span-details">
                    <v-icon>mdi-chevron-up</v-icon>
                  </div>
                </v-col>
              </v-row>

              <v-row
                v-if="order.paymentMethod === bankSlip"
                class="ma-0 pa-0 py-0 px-1"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <v-col
                  align="start"
                  cols="3"
                  xs="3"
                  sm="3"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <v-img
                    class="mx-2"
                    src="../../../assets/boleto.svg"
                    width="70"
                    height="50"
                    alt="boleto"
                    title="boleto"
                  >
                  </v-img>
                </v-col>

                <v-col cols="9" xs="9" sm="9" md="9" lg="9" xl="9">
                  <h3>
                    {{
                      (order.totalPrice + order.fretePrice).toLocaleString(
                        "pt-br",
                        { style: "currency", currency: "BRL" }
                      )
                    }}
                  </h3>
                </v-col>

                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="text-left"
                >
                  <a
                    v-if="order.orderStatus !== pedidoCancelado"
                    class="text-left"
                    :href="order.urlBoleto"
                    target="_blank"
                    >Acessar boleto</a
                  >
                </v-col>

                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="text-left"
                >
                  <p>
                    Status: <b>{{ statusPagamento() }}</b>
                  </p>
                </v-col>
              </v-row>

              <v-row
                  v-if="order.paymentMethod === pix"
                  class="ma-0 pa-0 py-0 px-1" cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
              >
                <v-col align="start" cols="6" xs="6" sm="6" md="6" lg="6" xl="6" >
                  <v-img
                      class="mx-4"
                      src="../../../assets/pix-icon.svg"
                      width="30" height="30" alt="Pix" title="Pix"
                  >
                  </v-img>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                  <h3>{{ (order.totalPrice + order.fretePrice).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }} </h3>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-left" v-if="showQrCodePix()">
                  <a class="ml-4" :href="order.qrCodeUrlPix" target="_blank"><strong>Acessar Qr Code</strong></a>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-left" v-if="showQrCodePix()">
                  <div class="content_copy_qrcode">
                    <p><strong>Copie este código para pagar</strong></p>
                    <ol style="margin-left: -8px">
                      <li>Acesse seu Internet Banking ou app de pagamentos.</li>
                      <li>Escolha pagar via Pix.</li>
                      <li>Cole o seguinte código:</li>
                    </ol>

                    <p id="qrCodePixMobile">{{order.qrCodePix}}</p>
                    <div class="mr-3 my-2">
                      <v-text-field
                          dense
                          hide-details
                          outlined
                          placeholder="Nome e sobrenome"
                          :disabled="true"
                          v-model="order.qrCodePix">
                      </v-text-field>
                      <p class="my-5"> <v-icon >mdi-clock-check-outline</v-icon> <strong class="ml-2">Pague e será creditado na hora</strong></p>
                      <v-btn
                          style="border-radius: 4px!important;"
                          align="start"
                          class="secondary-input"
                          elevation="0"
                          height="47"
                          large
                          width="100%"
                          @click="copyQrcode('qrCodePix')">
                        Copiar código
                      </v-btn>
                    </div>
                    <img alt="Qr Code Pix" title="Qr Code Pix" :src="order.qrCodeUrlPix"/>
                  </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-left">
                  <p >Status: <b>{{statusPagamento()}}</b></p>
                </v-col>
              </v-row>
              <v-row
                v-else
                class="ma-0 pa-0 py-0 px-1"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <v-col
                  align="start"
                  cols="3"
                  xs="3"
                  sm="3"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <v-img
                    v-if="order.brandCard"
                    v-bind:src="
                      require('../../../assets/' + order.brandCard + '.svg')
                    "
                    width="60"
                    height="30"
                    :alt="order.brandCard"
                    :title="order.brandCard"
                  >
                  </v-img>
                  <p style="font-size: 12px">{{ order.brandCard }}</p>
                </v-col>

                <v-col cols="9" xs="9" sm="9" md="9" lg="9" xl="9">
                  <p style="text-align: left">
                    {{ order.numberCardFlags }} <br />
                    {{
                      (order.totalPrice + order.fretePrice).toLocaleString(
                        "pt-br",
                        { style: "currency", currency: "BRL" }
                      )
                    }}
                    em {{ order.creditInstallments }}x
                  </p>
                </v-col>

                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="text-left"
                >
                  <p>
                    Status: <b>{{ statusPagamento() }}</b>
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!--Endereço-->
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-card class="card sombra ma-0 pa-0" height="180">
              <v-row class="pa-0 ma-0 py-0">
                <v-col class="cabeca ma-0 pa-0 py-3 px-3" cols="12" md="12">
                  <div class="span-order">Endereço {{ order.addressName }}</div>
                  <div class="span-details">
                    <v-icon>mdi-chevron-up</v-icon>
                  </div>
                </v-col>
                <div class="ma-0 pa-0 py-4 px-3" style="text-align: start">
                  <div>{{ order.address }}</div>
                </div>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div class="pa-4 grey lighten-4 span-order">Linha do tempo</div>
              <div class="pa-4 d-flex statusList" style="overflow-x: auto">
                <div
                  class="d-flex"
                  style="min-width: 170px"
                  v-for="(status, i) in orderStatusList.filter((x) =>
                    [0, 3, 4, 7, 8].includes(x.status)
                  )"
                  :key="status.id"
                >
                  <div class="d-flex flex-column justify-center align-center">
                    <v-btn
                      style="border: 2px solid #00875a !important"
                      fab
                      depressed
                      color="green"
                      class="ma-0 mb-2"
                    >
                      <v-icon size="30">{{
                        getStatusIcon(status.status)
                      }}</v-icon>
                    </v-btn>
                    <span class="status-text">
                      {{ getStatusName(status.status) }}
                    </span>
                    <span class="status-text">
                      {{ formatDate(status.created_at) }}
                    </span>
                  </div>
                  <v-divider
                    class="mt-7"
                    style="
                      min-width: 100px;
                      border: 2px solid #4caf50;
                      margin: auto -40px;
                    "
                    v-if="
                      i !=
                      order.orderStatusList.filter((x) =>
                        [0, 3, 4, 7, 8].includes(x.status)
                      ).length -
                        1
                    "
                  ></v-divider>
                </div>
              </div>
            </v-card>
          </v-col>

          <!--Endereço da revenda-->
          <!--<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-card class="card sombra ma-0 pa-0" height="180">
              <v-row class="pa-0 ma-0 py-0">
                <v-col class="cabeca ma-0 pa-0 py-3 px-3" cols="12" md="12">
                  <div class="span-order">Endereço da revenda</div>
                  <div class="span-details">
                    <v-icon>mdi-chevron-up</v-icon>
                  </div>
                </v-col>

                <div class="ma-0 pa-0 py-4 px-3" style="text-align: start">
                  <span class="pa-0 ma-0">{{ order.resale.neighborhood }}, {{ order.resale.city }}, {{ order.resale.state }}, {{ order.resale.country }} - CEP: {{ order.resale.postalCode }} </span>
                  <br />
                  <span class="pa-0 ma-0"> <strong> E-mail:</strong> {{ order.resale.email }} </span>
                  <br />
                  <span class="pa-0 ma-0">
                      <strong>Telefone:</strong>{{ order.resale.phoneNumber }}
                    </span>
                </div>
              </v-row>
            </v-card>
          </v-col>-->

          <!--Informações do envio-->
          <v-col ccols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-card class="card sombra">
              <v-col class="cabeca ma-0 pa-0 py-2 px-3" cols="12" md="12">
                <div class="span-order">Informações do envio</div>
                <div class="span-details">
                  <v-icon>mdi-chevron-up</v-icon>
                </div>
              </v-col>
              <div class="">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="informacoes-cliente">Código de rastreio</td>
                        <td class="informacoes-cliente-dados">
                          <span v-if="order.trackingCode">{{
                            order.trackingCode
                          }}</span>
                          <span v-if="!order.trackingCode">Sem Registro</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="informacoes-cliente">Transportadora</td>
                        <td class="informacoes-cliente-dados">
                          <span v-if="order.companyName">{{
                            order.companyName
                          }}</span>
                          <span v-if="!order.companyName">Sem Registro</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="informacoes-cliente">
                          Link para acompanhar
                        </td>
                        <td class="informacoes-cliente-dados">
                          <a
                            v-if="order.trackingLink"
                            :href="order.trackingLink"
                            target="_blank"
                            >Rastrear</a
                          >
                          <span v-if="!order.trackingLink">Sem Registro</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <br />
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </v-main>
</template>

<style scoped>
.content_copy_qrcode{
  margin-top: 10px;
  cursor: pointer;
  margin-left: 25px!important;
}
li::marker{
  font-weight: bold;
}
#qrCodePixMobile,
#qrCodePix{
  display: none;
}
.copy_qrcode{
  margin-left: 5px!important;
  margin-top: -3px;
}
.status-text {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #888;
}

.statusList {
  justify-content: flex-start;
  max-width: 90vw;
  overflow-x: auto;
}

.statusList::-webkit-scrollbar {
  background: #f5f5f5;
  height: 8px;
}

.statusList::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.sombra {
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
}

.informacoes-cliente-dados {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  text-align: right;
}

.informacoes-cliente {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #000000;
}
.total-pago {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #000000;
}

.total-pago-dados {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  text-align: right;
}
.image-placeholder {
  background: rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 46%,
    rgba(255, 255, 255, 0.8) 53%,
    rgba(255, 255, 255, 0) 65%,
    rgba(255, 255, 255, 0) 100%
  );
  background-position-x: -100vw;
  background-repeat: no-repeat;
  animation: shiny 1.5s infinite;
}

@keyframes shiny {
  0% {
    background-position-x: -100vw;
  }
  10% {
    background-position-x: -100vw;
  }
  75% {
    background-position-x: 100vw;
  }
  100% {
    background-position-x: 100vw;
  }
}

.image-placeholder:hover {
  color: #ffffff !important;
  background: #bc0000;
  box-sizing: border-box;
  border-radius: 10px;
}
.image-placeholder:hover .icon {
  color: #ffffff !important;
}

.cabeca {
  border-start-end-radius: inherit;
  border-start-start-radius: inherit;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
}
.cabeca:hover {
  cursor: pointer;
}
.span-details {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #2d3038;
}
.span-details:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.span-order {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #2d3038;
}
.card {
  border-radius: 5px !important;
}

.card:hover {
  background-color: #ddd;
}

.step-title {
  font-weight: bold;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 28px;
  margin-left: 5px;
  color: #2d3038;
  text-align: start;
}

.subtitle {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 0.9rem !important;
  line-height: 18px !important;

  color: #2d3038 !important;
}

.card-profile {
  width: 100% !important;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
  border-radius: 10px;
  text-align: center;
  font-family: Roboto,sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  display: flex !important;
  flex-direction: column !important;
  color: #2d3038;
  cursor: pointer;
}
.card-profile:hover {
  color: #bc0000 !important;
}

.mainProfile {
  display: flex !important;
  max-width: unset !important;
  padding-top: 0px !important;
  text-align: -webkit-center !important;
}

@media (min-width: 600px) {
  .mainProfile {
    display: flex !important;
    max-width: unset !important;
    padding-top: 0px !important;
  }
}
</style>

<script>
import ApiService from "../../../services/ApiService";
import PreviousOrders from "../components/PreviousOrders.vue";
import Vue from "vue";
import moment from "moment";

export default {
  name: "Order",

  components: {
    PreviousOrders,
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    const steplocalStorage = localStorage.getItem("step");
    if (steplocalStorage) {
      this.step = Number(steplocalStorage);
    }
  },

  data: () => ({
    statusList: [
      "Realizado", //Novos
      "Aguardando", //Aguardando confirmação de pagamento
      "Reprovado", //Recusou o pagamento e estoque
      "Aprovado", //Aprovou o pagamento e estoque
      "Enviado", //Em trânsito
      "Devolvido", // Cliente pediu extorno ou troca
      "Separacão",
      "Cancelado", //Cancelado
      "Entregue", //Entregue
      "Retirar", //Entregue
      "Gerar etiqueta", //Entregue
    ],
    pedidoAprovado: 3,
    pedidoReprovado: 2,
    pedidoAguardando: 1,
    pedidoCancelado: 7,
    orderStatusList: [],
    rating: 1,
    order: {
      discount: 0,
      fretePrice: 0,
      totalPrice: 0,
    },
    bankSlip: 1,
    pix: 2,
    creditCard: 0,
    ratingDialog: false,
    dialogKey: 1,
    dropdown: true,
    compra: [
      // {
      //   name: "Subtotal",
      //   price: 372,
      // },
      // {
      //   name: "Desconto",
      //   price: 10,
      // },
      // {
      //   name: "Frete",
      //   price: 17,
      // },
    ],
    product: [],
    currentClient: [],
    componentKey: 1,
    endereco: "",
    clear: false,
    step: 0,
    active: false,
    loading: false,
    isMobile: false,
    apiService: new ApiService(),
    menu: [
      {
        text: "Informações pessoais",
        subtitle: "Ver e alterar sua informações",
        icon: "mdi-calendar-account-outline",
        step: 0,
      },
      {
        text: "Endereços salvos",
        subtitle: "Alterar e cadastrar endereços",
        icon: "mdi-home-outline",
        step: 1,
      },
      {
        text: "Senhas",
        subtitle: "Alterar sua senha",
        icon: "mdi-form-textbox-password",
        step: 2,
      },
      {
        text: "Histórico de compras",
        subtitle: "Ver suas compras ralizadas",
        icon: "mdi-history",
        step: 3,
      },
    ],
  }),

  computed: {
    calculateTotal() {
      var total = 0;
      this.order.items.forEach((e) => {
        total += e.price * e.qty;
      });

      return total;
    },

    calculatePrice() {
      return (this.newValue * this.product.price).toFixed(2);
    },
  },

  async created() {
    //Bring Client information to "Informações pessoais"
    this.currentClient = JSON.parse(localStorage.getItem("user"));

    //Bring, if exists on $route, the OrderId ou PorductId to "PreviousOrders/Details"
    await this.getProductsById();
    await this.getOrderById();
  },

  methods: {
    showQrCodePix(){
      return moment(new Date(this.order.currentDate)).isBefore(new Date(this.order.expiresInQrCodeUrlPix)) && !this.orderStatusList.find((step) => step.status === this.pedidoAprovado);
    },
    async copyQrcode(id) {
      const content = document.getElementById(id).innerHTML;
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(content)
            .then(() => {
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "success",
                title: "",
                message: "Qr Code copiado.",
                trace: null,
              });
            })
            .catch((err) => {
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "error",
                title: "",
                message: "Não foi possível copiar o Qr Code",
                trace: null,
              });
            })
      }
    },
    getStatusName(status) {
      switch (status) {
        case 0:
          return "Pedido Realizado";
        case 3:
          return "Pagamento Confirmado";
        case 4:
          return "Pedido Enviado";
        case 7:
          return "Pedido Cancelado";
        case 8:
          return "Pedido Entregue";
        default:
          return "";
      }
    },

    getStatusIcon(status) {
      switch (status) {
        case 0:
          return "mdi-clipboard-check-outline";
        case 1:
          return "mdi-clock-outline";
        case 2:
          return "mdi-cancel";
        case 3:
          return "mdi-cash-check";
        case 4:
          return "mdi-truck-delivery-outline";
        case 5:
          return "mdi-transfer-left";
        case 6:
          return "mdi-archive-alert-outline";
        case 7:
          return "mdi-close-circle-outline";
        case 8:
          return "mdi-truck-check-outline";
        case 9:
          return "mdi-account-clock-outline";
        case 10:
          return "mdi-ticket-confirmation-outline";

        default:
          return "mdi-check";
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/yyyy");
    },

    statusPagamento() {
      if (
        this.orderStatusList.find((step) => step.status === this.pedidoAprovado)
      ) {
        return "Pago";
      } else if (
        this.orderStatusList.find(
          (step) => step.status === this.pedidoAguardando
        )
      ) {
        if (
          this.order.orderStatus === this.pedidoCancelado &&
          this.order.paymentMethod === this.bankSlip
        ) {
          return "Boleto expirado";
        } else if (moment(new Date(this.order.currentDate)).isAfter(new Date(this.order.expiresInQrCodeUrlPix)) && this.order.paymentMethod === this.pix) {
          return "Qr Code expirado";
        }
        return "Pendente";
      } else if (
        this.orderStatusList.find(
          (step) => step.status === this.pedidoReprovado
        )
      ) {
        return "Pagamento recusado";
      }
    },
    calculateSubtotal() {
      return this.order.items.reduce(
        (total, currentValue) => total + currentValue.price * currentValue.qty,
        0
      );
    },
    async getOrderById() {
      this.loading = true;
      await this.apiService
        .get(`order/get-one?id=` + this.$route.query.id)
        .then((response) => {
          this.order = response[0];
          this.orderStatusList = this.order.orderStatusList.sort((a, b) =>
            a.created_at > b.created_at
              ? 1
              : b.created_at > a.created_at
              ? -1
              : 0
          );
          this.componentKey++;
          this.loading = false;
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Erro ao buscar ordem",
            message: err.body.message,
            trace: null,
          });
          this.loading = false;
        });
    },

    async getProductsById() {
      this.loading = true;

      /*await this.apiService
        .get(`product/get-one?id=` + this.$route.query.id)
        .then((response) => {
          this.product = response;
          this.loading = false;
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Erro ao buscar produtos",
            message: err.body.message,
            trace: null,
          });
          this.loading = false;
        });*/
    },

    stepChange(arg) {
      //Coloca o Step no localhost para quando o usuario voltar a page
      localStorage.setItem("step", JSON.stringify(arg));
      this.goToRoute("/profile");
    },

    goToRoute(route) {
      if (this.$route.path != route) {
        this.$router.push(route);
      }
    },

    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
  },
};
</script>
