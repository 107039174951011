<template>
  <div class="mainListAddress" style="text-align: -webkit-center">
    <v-dialog v-model="confirmDeleteDialog" max-width="780">
      <ConfirmExit
          title="Atenção"
          message="Tem certeza de que deseja remover este endereço?"
          buttonText="Confirmar"
          :loading="loading"
          @cancel="confirmDeleteDialog = false"
          @action="remove(itemx)"
      />
    </v-dialog>
    <v-card class="card-cart">
      <v-row style="text-align-last: left">
        <v-col class="px-10 pb-0">
          <span class="selecionar-title">Selecione o endereço aonde quer receber sua encomenda
          </span>
        </v-col>
      </v-row>

      <v-row v-if="addresses.length > 0" style="text-align-last: left">
        <v-col class="px-10 py-0">
          <span class="endereco-salvo-text">Você pode selecionar um de seus endereços salvos ou cadastrar um novo</span>
        </v-col>
      </v-row>
      <br/>
      <v-row v-if="addresses.length !== 0">
        <v-col class="px-10 " style="text-align-last: left">
          <span class="seus-endereco-salvos-text">Seus endereços salvos</span>
        </v-col>
      </v-row>

      <div v-if="addresses.length === 0">
        <v-progress-circular
            v-if="addresses[0] ? true : false"
            :size="50"
            color="primary"
            indeterminate>
        </v-progress-circular>

        <div class="py-10 my-10">
          <span class="empty-message pa-0 ma-0">Você não tem endereços cadastrados.</span>
          <br/>
          <span class="empty-label pa-0 ma-0">Cadastre novos endereços abaixo.</span>
        </div>
      </div>

      <v-row class="px-4 py-0" style="margin-top: -20px!important;">
        <v-col class="py-0 ma-0" :key="componentKey + 1">
          <v-radio-group hide-details v-model="radios" mandatory>
            <v-radio
                v-for="(itemx, i) in addresses"
                :key="i"
                active-class="radio-pressed"
                class="radio py-0"
                color="black"
                :value="itemx"
                @change="selectAddress(itemx)"
            >
              <template v-slot:label>
                <v-row class="radio-align" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <v-col class="py-0" cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
                    <div>
                      <strong class="name-address">{{ itemx.addressNickName }}</strong>
                    </div>
                    <div class="addressName">
                      {{
                        itemx.addressName +
                        ", nº:" +
                        itemx.addressNumber +
                        ", " +
                        itemx.neighborhood +
                        " - " +
                        itemx.city +
                        " - " +
                        itemx.state +
                        " - " +
                        itemx.country +
                        " - CEP: " +
                        itemx.postalCode
                      }}
                    </div>
                  </v-col>

                  <v-col class="py-0" style="text-align: right" cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            outlined
                            fab
                            tile
                            style="border-radius: 4px"
                            width="35"
                            height="35"
                            small
                            class="table-button ma-1 edit-button"
                            color="black"
                            @click="edit(itemx)"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon dark>mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Alterar</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            outlined
                            fab
                            tile
                            style="border-radius: 4px"
                            width="35"
                            height="35"
                            small
                            class="table-button ma-1 edit-button"
                            color="black"
                            @click="deletar(itemx)"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon dark>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Excluir</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row
          v-if="addresses.length !== 0"
          class="pa-0 ma-0 py-3"
          cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
      >
        <v-col class="px-6 ma-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" align="start" >
          <span class="main-title"> Cadastrar novo endereço </span>
          <br/>
          <span class="label-cep">CEP <b style="color: #BC0000">*</b></span>
          <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank" class="pa-2 link-cep"  style="">
            <b>
              <u>Não sei meu CEP</u>
            </b>
          </a>
          <v-row class="cep-adicionar-endereco px-3" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
              <v-text-field
                  type="text"
                  class="px-0 mx-0 py-0 cep"
                  outlined
                  placeholder="00000-000"
                  v-mask="'#####-###'"
                  dense
                  height="48"
                  v-model="postalCode"
                  hide-details
                  required
                  v-on:keyup.enter="newAddressCEP()"
              ></v-text-field>

              <v-btn height="53" class="secondary-button px-0 mx-0 py-0" style="border-radius: 5px!important;" @click="newAddressCEP()">Adicionar novo endereço</v-btn>
          </v-row>
        </v-col>

        <v-col class="pa-0 ma-0 px-3" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" align="end">
          <v-btn width="190px" height="40" class="primary-button px-0 mx-0 py-0" @click="selectFinish()">
            Selecionar endereço
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0 py-5 px-3" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="voltar" @click="goToRoute('/cart')">
          <v-icon class="pa-0 ma-0 px-1" color="#BC0000"> mdi-arrow-left </v-icon>
          <span class="voltar-span"> Voltar ao carrinho </span>
        </span>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import ApiService from "../../../services/ApiService";
import ConfirmExit from "../../pages/components/ConfirmExit.vue";

import Vue from "vue";

export default {
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, {passive: true});
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, {passive: true});
  },

  components: {
    ConfirmExit,
  },

  data: () => ({
    itemx: false,
    confirmDeleteDialog: false,
    selected: '',
    addresses: [],
    postalCode: "",
    currentClient: {},
    radios: null,
    componentKey: 1,
    endereco: "",
    cart: [],
    user: [],
    frete: [],
    valor: "",
    isMobile: false,
    badge: "",
    clearDialog: false,
    dialogKey: false,
    loading: false,

    apiService: new ApiService(),

    rules: {
      postalCodeRules: (v) =>
          (v && v.length <= 10) || "CEP deve ter no máximo 10 caracteres",
      required: (value) => !!value || "Campo requerido.",
    },
  }),
  computed: {
    TotalPrice() {
      let result = 0;
      this.cart.forEach((e) => {
        result = result + e.price * e.qty;
      });
      if (this.valor) {
        return parseFloat(result + parseFloat(this.valor));
      }

      return parseFloat(result);
    },
  },
  watch: {
    cart() {
      this.$root.$on("changeCart", (data) => {
        this.cart = data;
        this.componentKey++;
      });
    },
    badge() {
      this.$root.$on("changeBadge", (data) => {
        this.badge = data;
      });
    },
  },

  async created() {
    this.cart = JSON.parse(localStorage.getItem("cartUser") || "[]");
    this.badge = JSON.parse(localStorage.getItem("cartBadge"));
    this.user = JSON.parse(localStorage.getItem("user"));
    await this.getAddresses(this.user);
  },
  methods: {
    selectAddress(itemx) {
      this.selected = itemx;
    },

    async selectFinish() {
      await this.apiService.post(`client/selectDeliveryAddress`, this.selected)
        .then( async (resp) => {
          await this.$router.push({path: "/cart",})
        }).catch((err) => {
           Vue.$globalEvent.$emit("onFlashMessage", {
             type: "error",
             title: 'titleError',
             message: 'titleError',
             trace: null,
          });
        });
    },

    deletar(itemx) {
      this.itemx = itemx;
      this.confirmDeleteDialog = true;
    },

    async remove(itemx) {
      this.loading = true;
      await this.apiService
          .delete(`client/delete/` + itemx.id)
          .then((resp) => {
            this.getAddresses(this.user);
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Não foi possivel remover este item.",
              message: err.body.message,
              trace: null,
            });
          });

      this.loading = false;
      this.confirmDeleteDialog = false;
    },

    async getAddresses(user) {
      this.loading = true;
      if (user) {
        await this.apiService
            .get(`client/get-addresses?id=` + user.id)
            .then((response) => {
              this.addresses = response;
            })
            .catch((err) => {
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "error",
                title: "Não foi possível encontrar o endereço cadastrado",
                message: err.body.message,
                trace: null,
              });
            });
      } else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Sem usuário ou id",
          message: err.body.message,
          trace: null,
        });
      }

      this.loading = false;
    },

    goToRoute(route) {
      if (this.$route.path != route) {
        this.$router.push(route);
      }
    },

    async edit(itemx) {
      await this.$router.push({
        path: "/address?id=" + itemx.id + "&edit=true",
        params: [itemx.id, itemx.postalCode],
      });
    },

    async newAddressCEP() {
      await this.$router.push({
        path: "/address?cep=" + this.postalCode,
        params: this.postalCode,
      });
    },

    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
  },
};
</script>

<style scoped>
.cep-adicionar-endereco{
  margin-top: 20px!important;
}
.link-cep{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: #BC0000;
}
.label-cep{
  color: #979797;
  font-size: 14px;
  font-weight: bold;
}
.name-address {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #2D3038;
}

.addressName {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #979797;
}

.seus-endereco-salvos-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #2D3038;
}

.endereco-salvo-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 8px;
  color: #979797;
}

.selecionar-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #2D3038;
}

.voltar {
  color: #bc0000 !important;
}

.voltar:hover {
  cursor: pointer;
}

.voltar-span:hover {
  text-decoration-line: underline;
}

.radio {
  border-bottom: 1px solid #C4C4C4 !important;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 25px 10px !important;
  color: #979797;
}

.radio:first-child {
  border-top: 1px solid #C4C4C4 !important;
}

.radio:hover {
  background-color: #f5f5f5;
}

.radio-pressed {
  /*background-color: #ddd;*/
  /* border-style: dashed; */
}

.radio-align {
  /*left: 0px !important;
  right: auto !important;
  position: relative !important;
  flex-flow: column !important;
  align-items: start !important;*/

}

.card-subprice {
  font-family: Poppins;
  font-style: normal;
  font-size: 0.9rem;
  line-height: 24px;

  letter-spacing: 0.15px;

  color: #979797;
}

.price-total {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: black;
}

.label-cart {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1.45rem;
  line-height: 36px;
  letter-spacing: 0.15px;

  color: #000000;
}

.price-frete {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.55rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: #979797;
}

.frete {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: #979797;
}

.frete:hover {
  cursor: pointer;
}

.span-red {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: #bc0000;
}

.span-red:hover {
  cursor: pointer;
}

.empty-message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 3.2rem;

  color: #97999d;
}

.empty-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;

  color: #97999d;
}

.main-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #2D3038;

}

.card-cart {
  width: 90%;
  height: fit-content !important;
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
  border-radius: 6px;
}

@media (min-width: 650px) {
  .card-cart {
    width: 70%;
    background: #ffffff;
    box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
    border-radius: 6px;
  }
}
.primary-button{
  border-radius: 5px!important;
}
.cep{
  max-width: 160px!important;
  border-radius: 5px!important;
  margin-right: 10px!important;
}
@media (max-width: 486px) {
  .cep{
    max-width: 100%!important;
  }
  .secondary-button{
    margin-top: 10px!important;
    width: 100%!important;
  }
  .primary-button{
    width: 100%!important;
  }
}
.v-card__text {
  padding: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
}

.containerList {
  justify-content: center !important;
  flex-direction: column;
}
</style>
