<template>
  <div>
    <desktop v-if="!$vuetify.breakpoint.mobile" />
    <mobile v-else />
  </div>
</template>

<script>
import desktop from "./components/checkout-desktop.vue";
import mobile from "./components/checkout-mobile.vue";

export default {
  name: "CheckoutPage",

  components: {
    desktop,
    mobile,
  },
};
</script>

<style scoped></style>
