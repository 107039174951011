<template>
  <v-card class="card pa-6 mx-auto" elevation="1">
    <div class="px-2 d-flex align-center">
      <v-avatar v-if="icone" class="subheading flex-center white--text mr-11">
        <v-icon large>{{ icone }}</v-icon>
      </v-avatar>
      <h2 class="justify-center">{{ title }}</h2>
    </div>

    <div class="my-4 mx-2 px-1 d-flex flex-column justify-center align-center">
      <span class="label py-2">
        {{ textMessage }}
      </span>
      <span v-if="quantidade > 0" class="label observation"> Produtos Cadastrados: </span>
      <span v-if="quantidade > 0" class="label observation py-2">
        {{ quantidade }} {{ textObservation }}
      </span>
    </div>

    <div>
      <v-row class="d-flex ma-0 pa-0">
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            elevation="0"
            large
            class="cancel-button black--text mr-4"
            @click="close"
            :loading="loading"
            outlined
          >
            <span>{{ cancelBtn }}</span>
          </v-btn>

          <v-btn
            elevation="0"
            large
            class="cadaster-button white--text"
            color="normalred"
            @click="action()"
            :loading="loading"
          >
            <span>{{ okbtn }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<style scoped>
.cadaster-button {
  border-radius: 7.5px !important;
  transition: 0.3s;
  background: #bc0000 !important;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
}

.cadaster-button:hover {
  color: white !important;
  background: #e8161e !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.cancel-button {
  border-radius: 7.5px !important;
  border: 2px solid #2d3038;
  box-sizing: border-box;
  font-weight: bold;
  transition: 300ms;
}

.cancel-button:hover {
  background: #2d3038;
  color: white !important;
}

.cancel-button:focus {
  background: #444854;
  color: white !important;
}

.card {
  background: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 12px !important;
}

.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  text-align: justify;
  letter-spacing: 0.15px;

  color: #232021;
}

.observation {
  font-family: Roboto;
  font-style: normal;
  color: grey;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  text-align: justify;
  letter-spacing: 0.15px;

  color: #232021;
}

@media (max-width: 600px) {
  .select-type {
    width: 50% !important;
  }
}

@media (max-width: 600px) {
  .form {
    flex-direction: column;
  }
}
</style>

<script>
import ApiService from "../../../services/ApiService";

export default {
  name: "DeleteModal",

  props: {
    filter: Object,
    title: String,
    textMessage: String,
    cancelBtn: String,
    okbtn: String,
    icone: String,
    textObservation: String,
    resale: Object,
    category: Object,
    quantidade: Number,
  },

  data: () => ({
    loading: false,
    excelDialog: "",
    apiService: new ApiService(),
  }),

  methods: {
    close() {
      this.$emit("close");
    },

    action() {
      this.$emit("action");
    },
  },
};
</script>
