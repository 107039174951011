<template>
  <div class="pa-4">
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="specialtyDialog"
      max-width="500px"
    >
      <NewSpecialties
        :specialties="{}"
        v-if="specialtyDialog"
        @action="specialtyDialog = true"
        @close="(specialtyDialog = false), listSpecialties()"
        @closeNoLoading="specialtyDialog = false"
      />
    </v-dialog>

    <v-form ref="form">
      <v-row class="ma-0">
        <v-col cols="12">
          <div class="d-flex align-center">
            <v-icon class="mr-2" large color="normalred">
              mdi-account-outline
            </v-icon>
            <h2>Informações:</h2>
          </div>

          <p>Informações pessoais para cadastro</p>
        </v-col>

        <v-col cols="12">
          <v-file-input
            id="installerImageInput"
            accept="image/png, image/jpeg, image/jpg"
            style="display: none"
            @change="setImage"
          ></v-file-input>

          <div class="d-flex flex-column justify-center align-center">
            <v-hover v-slot="{ hover }"
              v-if="currentInstaller.image"
            >
              <v-img
                  :src="currentInstaller.image"
                  style="
                  border-radius: 50% !important;
                  opacity: 0.8;
                  cursor: pointer;
                "
                  height="150"
                  width="150"
                  cover
                  :class="hover ? 'elevation-5 mx-2' : 'mx-2'"
              >
                <v-expand-transition>
                  <div
                      v-if="hover"
                      class="d-flex flex-column justify-center align-center"
                      style="height: 100%; background: rgba(188, 0, 0, 0.6)"
                      @click="openPicker"
                  >
                    <v-icon size="26" color="white">mdi-pen</v-icon>
                    <span class="edit-photo">Alterar foto</span>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-hover>

            <div
              class="d-flex flex-column align-center"
              style="width: 150px"
              v-else
            >
              <v-hover v-slot="{ hover }">
                <div class="image-container mx-2" @click="openPicker">
                  <v-img
                    src="../../../../assets/imp.svg"
                    v-if="!hover"
                    contain
                    max-width="100"
                  ></v-img>

                  <div class="d-flex flex-column align-center">
                    <v-img
                      src="../../../../assets/add_photo.svg"
                      contain
                      max-width="46"
                    ></v-img>
                    <span class="photo-field" v-if="hover">Adicionar Foto</span>
                  </div>
                </div>
              </v-hover>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="currentInstaller.name"
            class="primary-input"
            hide-details="auto"
            outlined
            :rules="[(v) => !!v || 'Digite o nome']"
            label="Nome"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="currentInstaller.nameFantasy"
            class="primary-input"
            hide-details="auto"
            outlined
            :rules="[(v) => !!v || 'Digite o Nome fantasia']"
            label="Nome fantasia"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="currentInstaller.email"
            class="primary-input"
            hide-details="auto"
            outlined
            :rules="[
              (v) => !!v || 'Digite o E-mail',
              (v) => /.+@.+\..+/.test(v) || 'E-mail inválido',
            ]"
            label="E-mail"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="currentInstaller.cnpj"
            class="primary-input"
            hide-details="auto"
            outlined
            :rules="[(v) => !!v || 'Digite o CNPJ/CPF']"
            v-mask="
              currentInstaller.cnpj.length <= 14
                ? '###.###.###-##'
                : '##.###.###/####-##'
            "
            label="CNPJ/CPF"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            class="primary-input"
            hide-details="auto"
            outlined
            v-model="currentInstaller.phoneNumber"
            :rules="[(v) => !!v || 'Digite o Telefone']"
            v-mask="
              currentInstaller.phoneNumber &&
              currentInstaller.phoneNumber.length <= 14
                ? '(##) ####-####'
                : '(##) #####-####'
            "
            label="Telefone"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            class="primary-input"
            hide-details="auto"
            outlined
            label="Celular"
            v-model="currentInstaller.phoneNumber2"
            v-mask="
              currentInstaller.phoneNumber2 &&
              currentInstaller.phoneNumber2.length <= 14
                ? '(##) ####-####'
                : '(##) #####-####'
            "
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            class="primary-input"
            hide-details="auto"
            outlined
            v-model="currentInstaller.whatsApp"
            label="WhatsApp"
            v-mask="
              currentInstaller.whatsApp &&
              currentInstaller.whatsApp.length <= 14
                ? '(##) ####-####'
                : '(##) #####-####'
            "
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="currentInstaller.instagram"
            class="primary-input"
            hide-details="auto"
            outlined
            label="Instagram"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <div class="d-flex align-center">
            <v-icon class="mr-2" large color="normalred">
              mdi-google-maps
            </v-icon>
            <h2>Endereço:</h2>
          </div>

          <p>Informações de localização para cadastro</p>
        </v-col>

        <v-col cols="12" sm="6" md="9">
          <div class="d-flex align-center">
            <v-text-field
              class="primary-input"
              hide-details="auto"
              v-model="currentInstaller.postalCode"
              v-on:keyup="searchCEP"
              outlined
              :rules="[(v) => !!v || 'Digite o CEP']"
              v-mask="'#####-###'"
              label="CEP"
            ></v-text-field>

            <a
              class="mx-4"
              target="_blank"
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
            >
              Não sei o meu CEP
            </a>
          </div>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="currentInstaller.state"
            :items="states"
            class="primary-input"
            hide-details="auto"
            outlined
            :rules="[(v) => !!v || 'Digite o Estado']"
            label="Estado"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="currentInstaller.city"
            class="primary-input"
            hide-details="auto"
            outlined
            :rules="[(v) => !!v || 'Digite o Cidade']"
            label="Cidade"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="currentInstaller.neighborhood"
            class="primary-input"
            hide-details="auto"
            outlined
            :rules="[(v) => !!v || 'Digite o Bairro']"
            label="Bairro"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="currentInstaller.addressName"
            class="primary-input"
            hide-details="auto"
            outlined
            :rules="[(v) => !!v || 'Digite o Logradouro']"
            label="Logradouro"
          ></v-text-field>
        </v-col>

        <!-- <v-col cols="12" sm="6" md="2">
          <v-text-field
            v-model="currentInstaller.addressNumber"
            class="primary-input"
            id="AddressNumber"
            hide-details="auto"
            outlined
            label="Número"
          ></v-text-field>
        </v-col> -->

        <v-col cols="12" sm="6" md="8">
          <v-text-field
            class="primary-input"
            hide-details="auto"
            outlined
            v-model="currentInstaller.addressComplement"
            label="Complemento"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <div class="d-flex align-center">
            <v-icon class="mr-2" large color="normalred"> mdi-brain </v-icon>
            <h2>Especialidades:</h2>
            <v-btn
              depressed
              small
              class="ml-2"
              color="normalred"
              @click="specialtyDialog = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>

          <p>
            Selecione a(s) especialidade(s) de aplicação dos adesivos, elas
            serão importantes para indicarmos aos clientes.
          </p>
        </v-col>

        <v-col>
          <v-row class="ma-0" align="center">
            <v-col
              v-for="item in specialties"
              :key="item.name"
              class="checkbox"
            >
              <v-checkbox
                type="checkbox"
                color="deep-purple"
                class="ma-0 pb-0 pt-0 black--text"
                v-model="item.checked"
                @change="updateBoxes"
                :value="item.checked"
                :checked="item.checked"
                :disabled="item.disabled"
              >
                >
                <template v-slot:label>
                  {{ item.name }}
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="d-flex justify-end">
          <v-btn class="primary-button" depressed @click="submit"
            >Salvar alterações</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ApiService from "../../../../services/ApiService";
import NewSpecialties from "../../components/newSpecialties.vue";

export default {
  name: "UpdateProfile",

  components: {
    NewSpecialties,
  },

  async created() {
    await this.listSpecialties();

    const user = JSON.parse(localStorage.getItem("user"));

    await this.apiService
      .get(`installer/get-one?id=${user.id}`)
      .then((response) => {
        if (response.body != "") {
          this.currentInstaller = response;
          this.specialties.forEach((item) => {
            for (let i = 0; i < this.currentInstaller.specialties.length; i++) {
              if (
                item.id === this.currentInstaller.specialties[i].specialtiesId
              ) {
                item.checked = true;
              }
            }
          });
        }
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  data() {
    return {
      states: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RO",
        "RS",
        "RR",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
      apiService: new ApiService(),
      loading: false,
      specialtyDialog: false,
      filter: {
        page: 1,
        pageSize: 15,
      },
      currentInstaller: {
        specialties: [],
        cnpj: "",
        phoneNumber: "",
        phoneNumber2: "",
        whatsApp: "",
        postalCode: "",
        image: "",
        neighborhood: "",
        addressName: "",
        city: "",
        state: "",
        country: "Brasil",
      },
      specialties: [],
      totalPages: 0,
      tickLimit: 0,
    };
  },

  methods: {
    setImage(image) {
      if (image.size < 2097152) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = () => {
          this.currentInstaller.image = reader.result;
        };
      } else {
        this.$toast.error("Arquivo excede o limite de 2mb");
      }
    },

    openPicker() {
      document.querySelector("#installerImageInput").click();
    },

    showPhoto(image) {
      if (!!image && image.includes("Uploads/")) {
        const env_url = process.env.VUE_APP_BASE_URL;
        return env_url + image;
      } else {
        return image;
      }
    },

    async searchCEP() {
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.currentInstaller.postalCode)) {
        await this.apiService
          .get(`user/postalcode?cep=${this.currentInstaller.postalCode}`)
          .then((response) => {
            const location = JSON.parse(response);
            this.currentInstaller.neighborhood = location.bairro;
            this.currentInstaller.addressName = location.logradouro;
            this.currentInstaller.city = location.localidade;
            this.currentInstaller.state = location.uf;
            this.currentInstaller.country = "Brasil";
            document.getElementById("AddressNumber").focus();
          });
      }
    },

    updateBoxes() {
      this.ticks = this.specialties.filter((item) => item.checked);
      this.currentInstaller.specialties = this.specialties.filter(
        (item) => item.checked != false
      );
    },

    async listSpecialties() {
      this.loading = true;

      await this.apiService
        .post(`specialties/list`, this.filter)
        .then((response) => {
          this.totalPages = response.pager.totalPages;
          this.specialties = response.specialties;
          this.tickLimit = response.specialties.length - 1;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    async submit() {
      const model = {
        Specialties: [],
        Id: this.currentInstaller.id,
        Name: this.currentInstaller.name,
        Cnpj: this.currentInstaller.cnpj,
        InscrEstadual: this.currentInstaller.inscrEstadual,
        NameFantasy: this.currentInstaller.nameFantasy,
        PhoneNumber: this.currentInstaller.phoneNumber,
        PhoneNumber2: this.currentInstaller.phoneNumber2,
        Email: this.currentInstaller.email,
        Country: this.currentInstaller.country,
        BirthDate: this.currentInstaller.birthDate
          ? this.currentInstaller.birthDate
          : null,
        AddressNumber: parseInt(this.currentInstaller.addressNumber),
        PostalCode: this.currentInstaller.postalCode,
        Neighborhood: this.currentInstaller.neighborhood,
        AddressName: this.currentInstaller.addressName,
        State: this.currentInstaller.state,
        AddressComplement: this.currentInstaller.addressComplement,
        City: this.currentInstaller.city,
        ReceiveSms: false,
        Region: this.currentInstaller.installerRegion,
        Category: this.currentInstaller.installerCategory,
        Observations: this.currentInstaller.textObservation,
        Latitude: this.currentInstaller.Latitude,
        Longitude: this.currentInstaller.Longitude,
        ReceiveEmail: true,
        AccountNumber: this.currentInstaller.accountNumber,
        AgencyNumber: this.currentInstaller.agencyNumber,
        AccountBank: this.currentInstaller.accountBank,
        AccountType: this.currentInstaller.accountType,
        Image: this.currentInstaller.image,
        Status: this.currentInstaller.status,
        whatsApp: this.currentInstaller.whatsApp,
        instagram: this.currentInstaller.instagram,
      };

      this.specialties.forEach((item) => {
        if (item.checked == true) {
          model.Specialties.push({
            SpecialtiesId: item.id,
          });
        }
      });

      await this.apiService
        .post("installer/edit", model)
        .then((resp) => {
          this.$toast.success("Cadastro atualizado com sucesso!");
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  background: #dadada;
  cursor: pointer;
  transition: 0.1s;
  box-sizing: border-box;
  border-radius: 50% !important;
}

.photo-field {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.edit-photo {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #ffffff;
}
</style>
