import Layout from "../../internal/Layout.vue";
import CategoryPage from "./Category.vue";

const CategoryRoutes = {
  component: Layout,
  children: [
    {
      path: "/categoria/:name",
      name: "Category",
      component: CategoryPage,
      meta: {},
    },
  ],
};

export default CategoryRoutes;
