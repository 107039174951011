<template>
  <v-main class="mainProductSearch">
    <div class="max_width_container">
      <breadcrumbs class="my-2" :items="items"></breadcrumbs>

      <v-row cols="12" class="d-flex">
        <v-col class="filtes-content" cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
          <filters
              @product="searchForProductsByName"
              @selected-sizes="setSelectedSizes"
              @selected-subcategories="setSubcategories"
              @filter-by="setFilterBy"
              title="Produtos"
          >
          </filters>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="12" lg="9" xl="9">
          <product-pagination
              :page="page"
              :totalPages="totalPages"
              :totalItems="totalItems"
              :quantityProducts="products.length"
              @selected-page="setPage"
              @filter-by="setFilterBy"
          >
          </product-pagination>

          <div class="list-product-content" v-if="products && products.length > 0">
            <div v-for="item in products" class="ma-1" :key="item.productId">
              <ImageCard
                  :key="item.productId"
                  :item="item"
                  :env_url="env_url"
                  @action="goTo(item)"
              />
            </div>
          </div>

          <div v-else class="my-3">
            <h4>Nenhum resultado para: {{this.filter.product}}</h4>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-main>
</template>

<script>
import ApiService from "../../../services/ApiService";
import ImageCard from "../components/ImageCard.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Filters from "../components/Filters.vue";
import ProductPagination from "../components/ProductPagination.vue";
import Vue from "vue";
import FormatParamsUrl from "../../../Utils/FormatParamsUrl";

export default {
  components: {
    ImageCard,
    Breadcrumbs,
    Filters,
    ProductPagination,
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, {passive: true});
    }
  },

  async mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, {passive: true});
    await this.advancedSearch();
    this.$root.$on("advancedSearch", () => {
      this.advancedSearch();
    });
  },

  data: () => ({
    env_url: "",
    loading: false,
    isEmpty: false,
    componentKey: 1,
    fecharFiltro: false,
    attributeName: "",
    categoriaName: "",
    close: [],
    isMobile: false,
    products: [],
    productCategories: [],
    apiService: new ApiService(),
    page: 1,
    totalPages: 1,
    totalItems: 0,
    pageSize: 20,
    items: [
      {
        text: "Página Inicial",
        disabled: true,
        href: "/home",
      },
      {
        text: "Produtos",
        disabled: false,
        href: "",
      },

    ],
    filter: {
      product: "",
      page: 1,
      pageSize: 15,
      selectedSizes: [],
      Subcategories: [],
      orderByPrice: 0,
    },
    categories: [],
  }),


  computed: {
    paginatedItems() {
      return this.products.slice(
          this.filter.page * this.filter.pageSize,
          (this.filter.page + 1) * this.filter.pageSize
      );
    },

    attributeNamed() {
      return this.attributeName;
    },
    categoriaNamed() {
      return this.categoriaName;
    },
  },
  async created() {
    this.env_url = process.env.VUE_APP_BASE_URL;
  },

  methods: {
    setPage(page) {
      this.page = page
      this.filter.page = this.page;
      this.updatePageView();
    },
    setFilterBy(filterBy) {
      this.filter.orderByPrice = filterBy;
      this.filter.page = this.page;
      this.updatePageView();
    },

    async searchForProductsByName(product) {
      this.filter.product = product
      this.filter.page = 1;
      this.page = 1
      await this.updatePageView();
    },

    async setSelectedSizes(selectedSizes) {
      this.filter.selectedSizes = selectedSizes
      this.filter.page = 1;
      this.page = 1
      await this.updatePageView();
    },
    async setSubcategories(selectedSubcategories) {
      this.filter.Subcategories = selectedSubcategories
      this.filter.page = 1;
      this.page = 1
      await this.updatePageView();
    },

    async advancedSearch() {
      this.filter.product = this.$route.query.produto ? this.$route.query.produto : "";
      await this.updatePageView();
      if (this.filter.product) {
        await this.saveSearch();
      }
    },

    async getCategories() {
      this.loading = true;
      await this.apiService
          .get(`category/getInclude`)
          .then((response) => {
            response.forEach((item) => {
              this.categories.push({
                name: item.categoryName,
                id: item.categoryId,
              });
            });
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Erro listar as categorias",
              message: "Erro listar as categorias",
              trace: null,
            });

            this.loading = false;
          });
    },

    attributeClick(name) {
      this.attributeName = name;
    },

    categoriaClick(name) {
      this.categoriaName = name;
    },

    closeArrow(arg) {
      this.close[arg] = !this.close[arg];
      this.fecharFiltro = !this.fecharFiltro;

      if (this.fecharFiltro === false) {
        this.filter = {
          page: 1,
          pageSize: 6,
        };
        this.updatePageView();
      }
    },

    onResize() {
      this.isMobile = window.innerWidth < 960;
    },

    async updatePageView() {
      this.loading = true;
      this.filter.pageSize = this.pageSize;
      this.filter.page = this.page;
      await this.apiService
          .post(`product/listActiveB2C`, this.filter)
          .then((response) => {
            this.totalPages = response.pager.totalPages;
            this.products = response.products;
            this.totalItems = response.pager.totalItems;
            this.isEmpty = this.products.length === 0;
          });
      this.loading = false;
    },

    async saveSearch() {
      await this.apiService.post("search", {searchedText: this.filter.product});
    },

    async goTo(item) {
      const param = FormatParamsUrl.format(item.productName);
      await this.$router.push({
        path: `/produtos/${param}`,
        params: item,
      });
    },
  },
  watch: {
    pageSize() {
      this.filter.pageSize = this.pageSize;
      this.updatePageView();
    },

    /*page() {
      this.filter.page = this.page;
      this.updatePageView();
    },*/
  },
};
</script>

<style scoped>
.max_width_container {
  width: 80% !important;
  margin: 0 10% 0 10%;
}

.list-product-content {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(200px, auto));
}

@media (orientation: landscape) {
  .landscape {
    display: flex;
  }
}

@media (max-width: 1264px) {
  .max_width_container {
    width: 100%!important;
    margin: 0;
  }
}

.empty {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  width: 720px;
  height: 180px;
  font-size: 1.7rem;
  line-height: 56px;
  text-align-last: center;
  background-color: transparent;
  color: #97999d;
}

.div-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #2d3038;
}

.list-item-intern {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1.02rem !important;
  line-height: 23px !important;
  letter-spacing: 0.15px !important;

  color: #97999d !important;
}

.list-item-intern:hover {
  font-size: 1.2rem !important;
  font-weight: bold !important;

  color: #bc0000 !important;
}

.list-item {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 1.07rem !important;
  line-height: 23px !important;
  letter-spacing: 0.15px !important;

  color: #2d3038 !important;
}

.title-expansion {
  font-family: Roboto !important;
  font-weight: bolder !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 1.3rem !important;
  line-height: 23px !important;
  letter-spacing: 0.15px !important;
  text-align-last: center !important;

  color: #bc0000;
}

.mainProductSearch {
  /*display: flex !important;
  max-width: unset !important;
  padding-top: 0px !important;*/
}

@media (min-width: 600px) {
  .mainProductSearch {
    display: flex !important;
    max-width: unset !important;
    padding-top: 0px !important;
  }
}
</style>
