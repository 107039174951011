<template>
  <div>
    <v-breadcrumbs :items="items" class="pa-0 ma-0">
      <template v-slot:divider>
        <v-icon style="margin: 0 -15px 0 -12px !important" class="ma-0 pa-0" color="#4F4F4F" size="23">
          mdi-chevron-right
        </v-icon>
      </template>

      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            <span :class="item.disabled ? 'inactive':'active'">
              {{ item.text }}
            </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<style scoped>
.active {
  color: #BC0000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.inactive {
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
</style>

<script>

export default {
  name: "Breadcrumbs",

  props: {
    items: {default: null, type: Array},
  },

  data: () => ({}),
  components: {},
  async created() {
  },

  computed: {},

  methods: {},
};
</script>
