<template>
  <div>
    <!-- Mobile Bar-->
    <v-app-bar
      v-if="isMobile"
      app
      height="115"
      flat
      color="#FFFFFF"
      style="position: relative"
    >
      <div class="d-flex flex-column">
        <div class="d-flex">
          <v-app-bar-nav-icon
            large
            color="#BC0000"
            @click="drawer = !drawer"
          ></v-app-bar-nav-icon>

          <v-spacer></v-spacer>

          <v-img
            max-width="150"
            src="../../assets/imprimax_logo.png"
            alt="IMPRIMAX"
            class="pointer"
            @click="goToRoute('/home')"
            contain
          ></v-img>

          <v-spacer></v-spacer>

          <v-divider vertical class="ma-2 my-4"></v-divider>

          <v-spacer></v-spacer>

          <Avatar :user="user" />

          <v-btn
            icon
            large
            class="hidden-md-and-up"
            color="normalred"
            @click="goToRoute('/cart')"
          >
            <v-badge color="black" :content="badge" :value="badge" overlap>
              <v-icon> mdi-cart-outline</v-icon>
            </v-badge>
          </v-btn>
        </div>

        <v-card
          class="pa-0 ma-0"
          style="border-radius: 0 !important"
          elevation="0"
          color="#bc0000"
          align="center"
        >
          <v-combobox
            height="40px"
            outlined
            dense
            ref="myComboBox"
            hide-details
            style="
              margin: 10px;
              border-radius: 6px !important;
              background: #ffffff;
              width: 88% !important;
            "
            v-model="filter.product"
            :items="items"
            placeholder="O que você procura?"
            return-object
            v-on:keyup.enter="advancedSearch"
            :search-input.sync="search"
          >
            <template v-slot:append>
              <v-icon
                icon
                class="pt-0"
                color="normalred"
                size="26"
                @click="advancedSearch"
                >mdi-magnify
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Resultados para "<strong>{{ search }}</strong
                    >". Pressione <kbd>enter</kbd> para continuar com a procura
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </v-card>
      </div>
    </v-app-bar>

    <!-- Mobile Lateral Bar-->
    <v-navigation-drawer
      v-if="isMobile && drawer"
      v-model="drawer"
      color="#FFFFFF"
      app
      temporary
      :mini-variant.sync="mini"
      width="80%"
      mini-variant-width="110"
      style="margin-top: 54px; border-radius: 0 0 20px 0;"
    >
      <br />
      <div
        class="mx-2 nav"
        v-if="
          category &&
          category.subCategoriesToCategory &&
          category.subCategoriesToCategory.length > 0 &&
          showOnlySubcategories
        "
      >
        <a
          class="d-flex align-center justify-start ml-4 mb-3 pointer"
          @click="toCleanSubCategories"
        >
          <v-img
            max-width="20"
            src="../../assets/voltar.svg"
            alt="IMPRIMAX"
            class="pointer"
            @click="goToRoute('/home')"
            contain
          ></v-img>

          <strong class="text_departamentos ml-3"> Voltar</strong>
        </a>

        <strong class="categoryName ml-4">{{
          category.categoryName.toUpperCase()
        }}</strong>

        <v-list-group
          v-for="(subcategory, idx) in category.subCategoriesToCategory"
          :key="idx"
          class="pa-0 ma-0 not_border_item"
          @click="goToSubCategory(subcategory.subCategory)"
        >
          <template v-slot:activator>
            <v-list-item-content
              class="nav-icon title-menu"
              :class="
                idx < category.subCategoriesToCategory.length - 1
                  ? 'border_item'
                  : ''
              "
            >
              <v-list-item-title
                class="mb-2"
                v-if="subcategory.subCategory.name"
              >
                {{ subcategory.subCategory.name.toUpperCase() }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-group>

        <a class="text-center" @click="ifHome(category.categoryName)">
          <p class="text-center ver_tudo pt-3">
            Ver tudo em {{ category.categoryName.toUpperCase() }}
          </p>
        </a>
      </div>

      <div v-else class="mx-2 nav">
        <strong class="text_departamentos ml-3" @click="goToRoute('/profile')">
          Meus Pedidos
        </strong>

        <v-divider class="my-3"></v-divider>

        <strong class="text_departamentos ml-3">Departamentos</strong>

        <v-list-group
          v-for="(category, idx) in categories"
          :key="idx"
          offset-y
          open-on-hover
          class="pa-0 ma-0"
          @click="setSubCategories(category)"
        >
          <template v-slot:activator>
            <v-list-item-content
              class="nav-icon title-menu"
              :class="idx < categories.length - 1 ? 'border_item' : ''"
              style="text-align-last: left"
            >
              <div class="d-flex align-center justify-space-between">
                <v-list-item-title v-text="category.categoryName.toUpperCase()">
                </v-list-item-title>

                <v-icon color="#4F4F4F" size="30">mdi-chevron-right</v-icon>
              </div>
            </v-list-item-content>
          </template>
        </v-list-group>

        <div class="container_contato mx-2 mb-5">
          <p class="text_departamentos mt-6">Canais de Atendimento</p>

          <div class="phone d-flex align-end mb-4">
            <v-img
              max-width="20"
              src="../../assets/phone.svg"
              alt="IMPRIMAX"
              class="pointer"
              @click="goToRoute('/home')"
              contain
            ></v-img>
            <span class="ml-3 " style="font-size: 16px">
              +55 11 2101-4244
            </span>
          </div>

          <div class="phone d-flex align-end mb-4">
            <v-img
                max-width="20"
                src="../../assets/phone.svg"
                alt="IMPRIMAX"
                class="pointer"
                @click="goToRoute('/home')"
                contain
            ></v-img>
            <a
                class="link_phone_menu ml-3"
                href="https://api.whatsapp.com/send?1=pt_BR&phone=5511945059235"
                target="_blank"
            >
              +55 11 94505-9235
            </a>
          </div>

          <div class="phone d-flex align-end mb-4">
            <v-img
              max-width="20"
              src="../../assets/email.svg"
              alt="IMPRIMAX"
              class="pointer"
              @click="goToRoute('/home')"
              contain
            ></v-img>
            <a class="link_phone_menu ml-3"> marketplace@imprimax.com.br </a>
          </div>

          <div class="phone d-flex align-end">
            <v-img
              max-width="20"
              src="../../assets/localizacao.svg"
              alt="Localização"
              class="pointer"
              @click="goToRoute('/home')"
              contain
            ></v-img>
            <a class="link_phone_menu ml-3">
              Rua Karam Simão Racy, 49 <br />São Paulo - SP - Brasil
            </a>
          </div>
        </div>

        <div class="container_contato mb-5">
          <p class="text_departamentos mx-2 mt-6">Outros</p>

          <v-list-group
            v-for="(link, idx) in linksOutros"
            :key="idx"
            offset-y
            open-on-hover
            class="pa-0 ma-0"
            style="margin-left: -10px !important"
          >
            <template v-slot:activator>
              <v-list-item-content
                class="title-menu"
                :class="idx < linksOutros.length - 1 ? 'border_item' : ''"
              >
                <div class="d-flex">
                  <v-list-item-title>
                    <a
                      v-if="link.name === 'Central do vendedor'"
                      class="link-central-vendedor-mobile link_phone_menu"
                      :href="`${link.link}`"
                      target="_blank"
                    >
                      {{ link.name }}
                    </a>

                    <a
                        v-else
                        class="link-central-vendedor-mobile link_phone_menu"
                        @click="toNavegation(link.link)"
                    >
                      {{ link.name }}
                    </a>
                  </v-list-item-title>
                  <v-icon color="#4F4F4F" size="30">mdi-chevron-right</v-icon>
                </div>
              </v-list-item-content>
            </template>
          </v-list-group>
        </div>
      </div>
    </v-navigation-drawer>

    <!-- Desktop Bar-->
    <v-app-bar
      class="d-flex flex-column align-center"
      v-if="!isMobile"
      app
      flat
      height="auto"
      width="100%"
      color="#FFFFFF"
      style="z-index: 11!important;"
    >
      <div
        class="d-flex header_desktop_logo_search_profile max_width_container_header"
      >
        <v-img
          max-width="140"
          src="../../assets/imprimax_logo.png"
          alt="IMPRIMAX"
          class="pointer mr-12"
          @click="goToRoute('/home')"
          contain
        ></v-img>

        <v-combobox
          outlined
          dense
          hide-details
          class="header_desktop_search"
          item-text="productName"
          v-model="filter.product"
          :items="items"
          height="44"
          ref="myComboBox"
          placeholder="Busque aqui seu produto"
          v-on:keyup.enter="advancedSearch"
          :search-input.sync="search"
        >
          <template v-slot:append>
            <v-icon icon color="normalred" size="27" @click="advancedSearch"
              >mdi-magnify
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Resultados para "<strong>{{ search }}</strong
                  >". Pressione <kbd>enter</kbd> para continuar com a procura
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>

        <div class="header_desktop_profile ml-12">
          <Avatar :user="user" />
          <a
            class="mx-5 d-flex align-center"
            @click="goToRoute('/profile')"
          >
            <v-img
              max-width="26"
              src="../../assets/order.svg"
              alt="IMPRIMAX"
              class="pointer mr-1"
              contain
            >
            </v-img>
            <span class="text_meus_pedidos">Meus pedidos</span>
          </a>

          <a @click="goToRoute('/cart')">
            <div class="d-flex flex-row align-center">
              <v-icon color="normalred" size="26"> mdi-cart-outline</v-icon>
              <span class="mx-1 text_meus_pedidos"> Carrinho </span>
              <span class="text_meus_pedidos">(</span>
              <span class="quantidade_carrinho">{{ badge }}</span>
              <span class="text_meus_pedidos">)</span>
            </div>
          </a>
        </div>
      </div>

      <div class="container_category d-flex justify-center">
        <v-card
          elevation="0"
          class="barra-superior pa-0 ma-0"
          v-if="this.$route.path !== '/login'"
        >
          <v-menu
            v-for="(category, idx) in categories"
            :key="idx"
            offset-y
            open-on-hover
            rounded="b-xl"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-content
                class="title-menu-desktop pr-3"
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title
                  class="list-item-title"
                  v-text="category.categoryName.toUpperCase()"
                  @click="ifHome(category.categoryName)"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list
              class=""
              width="340"
              v-if="category.categoryName.toUpperCase() !== 'HOME'"
            >
              <v-list-item
                class="menu-list"
                style="
                  background-color: #ffffff;
                  color: #4f4f4f !important;
                  border-radius: 0 0 20px 20px !important;
                "
                v-for="(subcategory, index) in category.subCategoriesToCategory"
                :key="index"
                link
                @click="goToSubCategory(subcategory.subCategory)"
              >
                <v-list-item-title
                  class="menu-list-item"
                  :class="
                    index < category.subCategoriesToCategory.length - 1
                      ? 'border_item'
                      : ''
                  "
                  v-if="subcategory.subCategory.name"
                >
                  {{ subcategory.subCategory.name.toUpperCase() }}
                </v-list-item-title>
              </v-list-item>

              <a class="text-center" @click="ifHome(category.categoryName)">
                <p class="text-center ver_tudo pt-2">
                  Ver tudo em {{ category.categoryName.toUpperCase() }}
                </p>
              </a>
            </v-list>
          </v-menu>
        </v-card>
      </div>
    </v-app-bar>
  </div>
</template>

<style>
.nav {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 90vh;
  box-sizing: border-box;
}
.max_width_container_header {
  width: 80% !important;
}

.header_desktop_logo_search_profile {
  align-items: center;
  height: 85px !important;
  justify-content: space-between;
}

.header_desktop_search {
  background: #f2f2f2;
  border-radius: 6px;
  /*width: 25%!important;*/
}

.header_desktop_profile {
  display: flex;
  align-items: center;
  justify-content: end;
}

.container_category {
  width: 100% !important;
  background: #bc0000 !important;
}

.text_meus_pedidos {
  color: #4f4f4f;
  font-size: 1.1rem;
  font-weight: 600;
}

.text_departamentos {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #bc0000;
}

.quantidade_carrinho {
  padding: 0 1px;
  color: #bc0000;
  font-size: 1.1rem;
  font-weight: 600;
}

.link_phone_menu {
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f !important;
  text-decoration: none;
}

.link-central-vendedor-mobile {
  text-decoration: none;
  padding-left: -10px !important;
}

.menu-list:hover {
  cursor: pointer;
}

.menu-list-item {
  padding: 10px 2px !important;
}

.ver_tudo {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #bc0000;
}

.pointer {
  cursor: pointer !important;
  transition: 0.2s !important;
}

.barra-superior {
  display: inline-flex !important;
  border-radius: 0 !important;
  justify-content: space-evenly !important;
  width: 80% !important;
  height: 42px !important;
  background: #bc0000 !important;
}

.categoryName {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0;
  color: #4f4f4f;
}

.border_item {
  border-bottom: 1px solid #dedede;
  padding-bottom: 6px !important;
}

.not_border_item:last-child {
  border: none;
}

.container_contato {
  border-top: 1px solid #4f4f4f;
}

.v-toolbar__content {
  display: contents !important;
}

.v-list-item__icon {
  display: none !important;
  text-decoration: none !important;
}

.v-navigation-drawer--mini-variant,
.v-navigation-drawer {
  overflow: visible !important;
}

i.v-icon.notranslate.mdi.mdi-teach.theme--light.white--text.AplicadoresIcon {
  font-size: larger;
}

.v-navigation-drawer__content {
  overflow: visible !important;
}

.v-navigation-drawer__border {
  overflow: visible !important;
}

.v-toolbar__title {
  /* font-family: Poppins; */
  font-size: 1.5rem !important;
}

.title-menu {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  padding: 0;
}

.v-list-group {
  -webkit-box-pack: center;
  justify-content: center;
}

.title-menu-desktop {
  text-align-last: left;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 28px !important;
  letter-spacing: 0.15px !important;
  color: #ffffff !important;
}

.list-item-title {
  padding-top: 3px !important;
  border-bottom: 3px solid #bc0000;
}

.list-item-title:hover {
  padding-top: 3px !important;
  border-bottom: 3px solid #ffffff;
}

@media screen and (max-width: 1300px) {
  .max_width_container_header,
  .barra-superior {
    width: 100% !important;
    padding: 0 10px;
  }
}
</style>

<script>
import Avatar from "../internal/Avatar";
import ApiService from "../../services/ApiService";
import FormatParamsUrl from "../../Utils/FormatParamsUrl";
import { parseString } from "xml2js";

export default {
  name: "Navigation",

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  async mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, {passive: true});
    await this.getCategories();
    this.subCategoriesToCategory = []; //Pick categories to mainMenu
    if (this.$route.query.produto) {
      this.search = this.$route.query.produto;
    }
  },

  data: () => ({
    filter: {
      product: "",
      categoria: "",
      page: 1,
      pageSize: 15,
      DocumentNumber: null,
      status: null,
      updated_at: null,
    },
    category: null,
    showOnlySubcategories: false,
    urlB2B: `${process.env.VUE_APP_BASE_URL_B2B_FRONT}/auth`,
    search: null,
    apiService: new ApiService(),
    items: [],
    badge: "",
    isMobile: false,
    drawer: false,
    user: {},
    mini: false,
    group: null,
    categories: [],
    linksOutros: [
      {
        name: "Dúvidas Frequentes",

        link: `/perguntas-frequentes`,
      },
      {
        name: "Política de Troca e Devoluções",
        link: "/politicas/troca-devolucoes",
      },
      {
        name: "Política de Privacidade",
        link: "/politicas/politica-privacidade",
      },
      {
        name: "Termos e Condições",
        link: "/politicas/termos-condicoes",
      },
      {
        name: "Aplicadores Parceiros",
        link: "/installers",
      },
      {
        name: "Ser Aplicador Parceiro",
        link: "/add-installer",
      },
      {
        name: "Central do vendedor",
        link: `${process.env.VUE_APP_BASE_URL_B2B_FRONT}/auth`,
      },
      {
        name: "Sobre a Loja Imprimax",
        link: `/politicas/quem-somos`,
      },
    ],
    links: [
      {
        icon: "mdi-facebook",
        href: "https://www.facebook.com/ImprimaxAdesivos",
        text: "Facebook",
      },
      {
        icon: "mdi-instagram",
        href: "https://www.instagram.com/imprimaxadesivos/",
        text: "Instagram",
      },
      {
        icon: "mdi-linkedin",
        href: "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGwStGx-AGmFwAAAX4lnAz4_GxE6YsCz6cw4UbIneuVdpZBlBVmdEonNUW6RGEykkxa_Zg5l77EL2OJlMjc0_bhY5ydk56AdIwyvAlbQz1kJu-rUtodQ06x4k4zDraZsMxJaHI=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fimprimaxadesivos%2F",
        text: "Linkedin",
      },
      {
        icon: "mdi-whatsapp",
        href: "https://api.whatsapp.com/send?1=pt_BR&phone=5511945059235",
        text: "WhatsApp",
      },
      {
        icon: "mdi-youtube",
        href: "https://www.youtube.com/user/ImprimaxVideos",
        text: "Youtube",
      },
      {
        icon: "mdi-pinterest",
        href: "https://br.pinterest.com/imprimaxadesivos/",
        text: "Pinterest",
      },
      {
        icon: "mdi-soundcloud",
        href: "https://soundcloud.com/podcast-do-adesivo",
        text: "SoundCloud",
      },
    ],
  }),

  async created() {
    this.user = JSON.parse(localStorage.getItem("user"));

    if (this.user) {
      this.getCart();
    } else {
      const cart = JSON.parse(localStorage.getItem("cart"));
      this.badge = 0;
      if (cart) {
        cart.items.forEach((item) => {
          this.badge += item.quantity;
        });
      }
    }


    this.$root.$on("changeBadge", (data) => {
      if (data) {
        const cart = JSON.parse(localStorage.getItem("cart"));
        this.badge = 0;
        cart.items.forEach((item) => {
          this.badge += item.quantity;
        });
      } else {
        if (this.user) {
          this.getCart();
        }
      }
    });
  },

  components: {
    Avatar,
  },

  methods: {
    toNavegation(to) {
      this.$router.push(
          {
            path: to
          }
      )
      this.$root.$emit("platform-policies");
    },
    getCart() {
      this.apiService.get("cart").then((response) => {
        this.badge = response.content.itemsQuantity;
      });
    },

    toCleanSubCategories() {
      this.showOnlySubcategories = false;
      this.category = [];
    },
    setSubCategories(category) {
      const rotaAtual = this.$route.name;
      if (
        category.categoryName.toString().toLowerCase() === "home" &&
        rotaAtual !== category.categoryName
      ) {
        this.$router.push({ name: "Home" });
        return;
      }
      this.category = category;
      this.showOnlySubcategories = true;
    },
    homeNav(category) {
      const rotaAtual = this.$route.name;
      if (
        category.toString().toLowerCase() === "home" &&
        rotaAtual !== category
      ) {
        this.$router.push({ name: "Home" });
      }
    },
    ifHome(category) {
      if (category.toString().toLowerCase() === "home") {
        this.$router.push({ name: "Home" });
      } else {
        let cat = category.replace(/ /g, "-").toLowerCase();
        this.$router.push({ path: `/categoria/${cat}` });
        this.$root.$emit("rechargeCategory");
      }
    },
    async getCategories() {
      await this.apiService
        .post(`category/menuActive`, this.filter)
        .then((response) => {
          response.categories.forEach((e) => {
            this.categories.push(e);
          });
          this.categories.unshift({
            categoryCode: "",
            categoryId: "",
            categoryInventory: "",
            categoryName: "Home",
            products: null,
            statusOnMenu: 1,
            subCategoriesToCategory: {},
          });
        })
        .catch((err) => {});
    },
    filtrar() {
      this.$root.$emit("filtroMudado", this.filter.product);
    },
    async advancedSearch() {
      this.$refs["myComboBox"].blur();
      await this.$router
        .push({
          path: "/produtos",
          query: {
            produto: this.search,
          },
        })
        .catch((error) => {});
      this.$root.$emit("advancedSearch");
    },
    async getAutocomplete() {
      await this.apiService
        .post(`product/listActiveB2C`, this.filter)
        .then((response) => {
          this.products = response.products;
        });
    },

    async goToRoute(route) {
      if (this.$route.path !== route) {
        await this.$router.push(route);
      }
    },
    async goToSubCategory(subcategory) {
      const name = FormatParamsUrl.format(subcategory.name);
      await this.$router.push({
        path: `/subcategorias/${name}`,
      });
      this.$root.$emit("rechargeSubcategory");
    },

    hasAccess(val) {
      const result = val.find((type) => this.user.type == type);

      return result != undefined;
    },

    onResize() {
      this.isMobile = window.innerWidth < 960;
    },

    async goTo(path, config) {
      if (path !== this.$route.path && path !== "" && config !== "true") {
        await this.$router.push(path);
        this.drawer = false;
      }

      if (path !== this.$route.path && path !== "" && config == "true") {
        await this.$router.push({
          path: `/resaleconfig?id=` + item.id + `&profile=` + item.name,
          params: item,
        });
      }
    },
  },
  watch: {
    isMobile() {
      if (!this.isMobile) {
        window.location.reload(false);
      }
    },
  },
};
</script>
