<template>
  <div id="mainLayout">
    <Navigation v-if="true" :key="update" class="margin-bottom" />

    <router-view @updateProfileImage="updateProfile" @show="showSuccessAlert" />

    <v-scale-transition>
      <v-alert v-show="showSuccess" elevation="9" type="success" class="successAlert">
        {{ message }}
      </v-alert>
    </v-scale-transition>

    <Footer />
  </div>
</template>

<style scoped>
#main {
  max-width: unset;

  width: 100%;
  height: 100%;
}

.successAlert {
  z-index: 100;
  position: absolute;
  right: 2%;
  top: 10%;
  white-space: nowrap;
}

.margin-bottom {
  margin-bottom: 140px
}

@media (max-width: 400px) {
  .successAlert {
    position: absolute;
    right: 7%;
    left: 7%;
  }
}
@media (max-width: 960px) {
  .margin-bottom {
    margin-bottom: 0;
  }
}
</style>

<script>
import Navigation from "../internal/Navigation.vue";
import Footer from "../internal/Footer.vue";

export default {
  name: "Authenticated",

  data: () => ({
    showSuccess: false,
    update: false,
    message: "",
  }),

  components: {
    Navigation,
    Footer,
  },

  methods: {
    updateProfile() {
      this.update = !this.update;
    },

    showSuccessAlert(message) {
      this.message = message;

      this.showSuccess = true;

      setTimeout(() => {
        this.showSuccess = false;
      }, 4500);
    },
  },
};
</script>
