import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
import "@/sass/overrides.sass";

Vue.use(Vuetify);

// const theme = {
//   primary: "#E91E63",
//   secondary: "#9C27b0",
//   accent: "#9C27b0",
//   info: "#00CAE3",
// };

const theme = {
  normalred: "#BC0000",
  hoverred: "#E8161E",
  pressred: "#921616",
  focusred: "#FF0009",
  desactivered: "#707070",
  loadingred: "#BC0000",

  normaldark: "#000000",
  hoverdark: "#313236",
  pressdark: "#2D3038",
  focusdark: "#C3C4C6",
  desactivedark: "#707070",
  loadingdark: "#000000",
  greyText: "#9C9C9C",

  normalfantasm: "#E5E5E5",
  hoverfantasm: "#000000",
  pressfantasm: "#2D3038",
  focusfantasm: "#E5E5E5",
  desactivefantasm: "#707070",
  loadingfantasm: "#E5E5E5",
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
