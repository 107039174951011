import Layout from "../../internal/checkout.vue";
import CheckoutPage from "./Checkout.vue";
import OrderStatusPage from "./OrderStatus.vue";
import DeniedPaymentPage from "./DeniedPayment.vue";

const CheckoutRoutes = {
  component: Layout,
  children: [
    {
      path: "/checkout",
      name: "Checkout",
      component: CheckoutPage,
      meta: {},
    },
    {
      path: "/checkout/orderstatus",
      name: "OrderStatus",
      component: OrderStatusPage,
      meta: {},
    },
    {
      path: "/denied-payment",
      name: "DeniedPayment",
      component: DeniedPaymentPage,
      meta: {},
    },
  ],
};

export default CheckoutRoutes;
