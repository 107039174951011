<template>
  <v-card elevation="0" class=" ma-0 pa-0" tile :key="keyId">
    <div>
      <v-row
          class="pa-0 pb-0 ma-0 py-1 my-1 installers"
          v-for="(item, idw) in installers"
          :key="idw"
      >
        <v-col class="ma-0 pa-0 py-1 px-2 ">
          <div class="line">
            <div class="img-aplicador">
              <v-img
                  v-if="item.image != null"
                  style="width: 100%!important; min-height: 100%; border-radius: 5px!important"
                  :src="item.image"
              >
              </v-img>
              <v-img
                  v-if="item.image == null"
                  contain
                  max-width="200"
                  max-height="200"
                  class="ma-0 pa-0"
                  src="../../../assets/imp.svg"
              >
              </v-img>
            </div>
            <div class="ma-0 pa-0 informacoes-aplicador" style="text-align: start">
              <span class="card-title pa-0 ma-0">{{ item.name }}</span>
              <br/>
              <span class="pa-0 ma-0">Especialista em {{ item.specialties.length }} aplicações:</span>
              <span v-for="(specialty, idx) in item.specialties" :key="idx"> {{
                  specialty.specialties.name
                }} |</span>
              <br/>
              <span class="pa-0 ma-0">
                {{ item.neighborhood }}, {{ item.city }}, {{ item.state }},
                {{ item.country }} - CEP:
                {{ item.postalCode }}
                </span>
              <br/>
              <span class="pa-0 ma-0"> <strong> E-mail:</strong> {{ item.email }} </span>
              <br/>
              <span class="pa-0 ma-0">
                <strong>Telefone:</strong>
                {{ item.phoneNumber }}
                </span>
            </div>
          </div>
        </v-col>

      </v-row>
    </div>
  </v-card>
</template>

<style scoped>
.line {
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row;
  align-items: start;
  width: 100%;
}

.img-aplicador {
  width: 15% !important;
}

.informacoes-aplicador {
  width: 84% !important;
  margin-left: 1% !important;
}

.installers{
  padding-bottom: 10px!important;
  border-bottom: 1px solid #9C9C9C !important;
}
.installers:hover {
  background-color: #ddd;
}

.card-title {
  font-size: 1.4rem;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: 0.15px;
  text-align: start;
  color: #000000;
}

@media (max-width: 768px) {
  .line {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column;
  }
  .img-aplicador {
    width: 100% !important;
    height: 200px!important;
    margin-bottom: 10px!important;
  }
}
</style>

<script>
import ApiService from "../../../services/ApiService";

export default {
  name: "InstallersCard",

  components: {},
  props: {
    keyId: Number,
    installers: Array,
  },

  data: () => ({
    env_url: process.env.VUE_APP_BASE_URL,
    apiService: new ApiService(),
  }),
  async created() {
  },

  computed: {},

  methods: {
    formatDate(date) {
      let d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
  },
};
</script>
