<template>
  <v-footer style="width: 100%" dark padless>
    <v-row xs="12" sm="12" md="12" lg="12" xl="12" class="ma-0 pa-0 d-flex justify-space-between">
      <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" class="ma-0 pa-0 d-flex justify-center mt-4">
        <div>
          <p class="text-title">Empresa</p>
          <span>
            Imprimax Ind. de Auto Adesivos Ltda.
        </span>

          <div class="d-flex align-center mt-2">
            <svg
                width="18"
                height="22"
                viewBox="0 0 18 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M9 21C9 21 16.5 15 16.5 8.5C16.5 4.358 13.142 1 9 1C4.858 1 1.5 4.358 1.5 8.5C1.5 15 9 21 9 21Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linejoin="round"
              />
              <path
                  d="M9 11.5C9.39397 11.5 9.78407 11.4224 10.1481 11.2716C10.512 11.1209 10.8427 10.8999 11.1213 10.6213C11.3999 10.3427 11.6209 10.012 11.7716 9.64805C11.9224 9.28407 12 8.89397 12 8.5C12 8.10603 11.9224 7.71593 11.7716 7.35195C11.6209 6.98797 11.3999 6.65726 11.1213 6.37868C10.8427 6.1001 10.512 5.87913 10.1481 5.72836C9.78407 5.5776 9.39397 5.5 9 5.5C8.20435 5.5 7.44129 5.81607 6.87868 6.37868C6.31607 6.94129 6 7.70435 6 8.5C6 9.29565 6.31607 10.0587 6.87868 10.6213C7.44129 11.1839 8.20435 11.5 9 11.5Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linejoin="round"
              />
            </svg>

            <span class="ml-3">
            Rua Karam Simão Racy, 49 <br/>São Paulo - SP - Brasil
          </span>
          </div>

          <div class="mt-4">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.188329582149!2d-46.59670060644736!3d-23.633425581679116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5b5f3e9ab615%3A0x1743d5a75a056b3d!2sRua%20Karam%20Sim%C3%A3o%20Racy%2C%2049%20-%20Sacom%C3%A3%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004257-110!5e0!3m2!1spt-BR!2sbr!4v1641310239484!5m2!1spt-BR!2sbr"
                class="google-maps"
                style=""
                allowfullscreen=""
                loading="lazy"
            ></iframe>
          </div>
        </div>
      </v-col>

      <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" class="ma-0 pa-0 d-flex justify-center mt-4">
        <div>
          <span class="text-title">Canais de Atendimento</span>

          <div class="d-flex align-center mt-3 mb-2">
            <svg
                width="16"
                height="16"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M19 13.5126L14.5853 11.5335L14.5684 11.526C14.2815 11.4023 13.9681 11.3525 13.6569 11.3812C13.3458 11.4098 13.0467 11.516 12.7872 11.6901C12.7508 11.7144 12.7158 11.7407 12.6822 11.7688L10.5906 13.5501C9.37188 12.8891 8.11282 11.6404 7.45094 10.4366L9.23782 8.31226C9.26654 8.27798 9.29316 8.24197 9.31751 8.20445C9.48699 7.94585 9.58989 7.64934 9.61704 7.34134C9.64419 7.03334 9.59476 6.72341 9.47313 6.43914C9.47024 6.43369 9.46774 6.42806 9.46563 6.42226L7.48751 2.00008C7.32513 1.63025 7.04801 1.32253 6.69716 1.12245C6.3463 0.922374 5.94035 0.840567 5.53938 0.889139C4.17848 1.06791 2.92918 1.73596 2.02482 2.7685C1.12046 3.80105 0.62288 5.12748 0.625007 6.50008C0.625007 14.151 6.84907 20.3751 14.5 20.3751C15.8726 20.3772 17.199 19.8796 18.2316 18.9753C19.2641 18.0709 19.9322 16.8216 20.1109 15.4607C20.1595 15.0597 20.0777 14.6538 19.8776 14.3029C19.6775 13.9521 19.3698 13.6749 19 13.5126ZM14.5 18.1251C11.418 18.1214 8.4633 16.8954 6.284 14.7161C4.1047 12.5368 2.87873 9.58208 2.87501 6.50008C2.87286 5.72335 3.13655 4.96928 3.62227 4.36316C4.10799 3.75703 4.78648 3.33536 5.54501 3.1682L7.30938 7.1057L5.51313 9.24508C5.4841 9.27968 5.45717 9.31599 5.43251 9.35383C5.25546 9.62435 5.15137 9.93605 5.13034 10.2587C5.10932 10.5813 5.17207 10.9039 5.31251 11.1951C6.19563 13.0026 8.01532 14.8101 9.84157 15.6951C10.1347 15.8341 10.4589 15.8946 10.7825 15.8708C11.106 15.8469 11.4179 15.7396 11.6875 15.5591C11.7238 15.5347 11.7585 15.5081 11.7916 15.4795L13.8944 13.6916L17.8319 15.4551C17.6647 16.2136 17.243 16.8921 16.6369 17.3778C16.0308 17.8635 15.2767 18.1272 14.5 18.1251Z"
                  fill="white"
              />
            </svg>

            <span class="ml-3 " style="font-size: 16px">
              +55 11 2101-4244
            </span>
          </div>

          <div class="d-flex align-center " style="margin-top: -13px">
            <svg
                width="16"
                height="16"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M19 13.5126L14.5853 11.5335L14.5684 11.526C14.2815 11.4023 13.9681 11.3525 13.6569 11.3812C13.3458 11.4098 13.0467 11.516 12.7872 11.6901C12.7508 11.7144 12.7158 11.7407 12.6822 11.7688L10.5906 13.5501C9.37188 12.8891 8.11282 11.6404 7.45094 10.4366L9.23782 8.31226C9.26654 8.27798 9.29316 8.24197 9.31751 8.20445C9.48699 7.94585 9.58989 7.64934 9.61704 7.34134C9.64419 7.03334 9.59476 6.72341 9.47313 6.43914C9.47024 6.43369 9.46774 6.42806 9.46563 6.42226L7.48751 2.00008C7.32513 1.63025 7.04801 1.32253 6.69716 1.12245C6.3463 0.922374 5.94035 0.840567 5.53938 0.889139C4.17848 1.06791 2.92918 1.73596 2.02482 2.7685C1.12046 3.80105 0.62288 5.12748 0.625007 6.50008C0.625007 14.151 6.84907 20.3751 14.5 20.3751C15.8726 20.3772 17.199 19.8796 18.2316 18.9753C19.2641 18.0709 19.9322 16.8216 20.1109 15.4607C20.1595 15.0597 20.0777 14.6538 19.8776 14.3029C19.6775 13.9521 19.3698 13.6749 19 13.5126ZM14.5 18.1251C11.418 18.1214 8.4633 16.8954 6.284 14.7161C4.1047 12.5368 2.87873 9.58208 2.87501 6.50008C2.87286 5.72335 3.13655 4.96928 3.62227 4.36316C4.10799 3.75703 4.78648 3.33536 5.54501 3.1682L7.30938 7.1057L5.51313 9.24508C5.4841 9.27968 5.45717 9.31599 5.43251 9.35383C5.25546 9.62435 5.15137 9.93605 5.13034 10.2587C5.10932 10.5813 5.17207 10.9039 5.31251 11.1951C6.19563 13.0026 8.01532 14.8101 9.84157 15.6951C10.1347 15.8341 10.4589 15.8946 10.7825 15.8708C11.106 15.8469 11.4179 15.7396 11.6875 15.5591C11.7238 15.5347 11.7585 15.5081 11.7916 15.4795L13.8944 13.6916L17.8319 15.4551C17.6647 16.2136 17.243 16.8921 16.6369 17.3778C16.0308 17.8635 15.2767 18.1272 14.5 18.1251Z"
                  fill="white"
              />
            </svg>

            <a
                style="color: #fff !important;"
                class="link_phone"
                href="https://api.whatsapp.com/send?1=pt_BR&phone=5511945059235"
                target="_blank"
            >
              <span class="link_phone">+55 11 94505-9235</span>
            </a>
          </div>

          <div class="phone d-flex align-center">
            <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M2 16C1.45 16 0.979002 15.804 0.587002 15.412C0.195002 15.02 -0.000664969 14.5493 1.69779e-06 14V2C1.69779e-06 1.45 0.196002 0.979002 0.588002 0.587002C0.980002 0.195002 1.45067 -0.000664969 2 1.69779e-06H18C18.55 1.69779e-06 19.021 0.196001 19.413 0.588001C19.805 0.980001 20.0007 1.45067 20 2V14C20 14.55 19.804 15.021 19.412 15.413C19.02 15.805 18.5493 16.0007 18 16H2ZM10 9L2 4V14H18V4L10 9ZM10 7L18 2H2L10 7ZM2 4V2V14V4Z"
                  fill="white"
              />
            </svg>

            <span class="link_phone ml-4"> marketplace@imprimax.com.br </span>
          </div>
        </div>
      </v-col>

      <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" class="ma-0 pa-0 d-flex justify-center mt-4">
        <div style="margin-top: -5px">
          <p class="text-title ml-2">Nossas redes sociais</p>
          <div class="text-left">
            <v-btn
                v-for="link in links"
                :key="link.text"
                class="white--text flex-nowrap"
                :href="link.href"
                target="_blank"
                icon
            >
              <v-icon class="icones" size="25">{{ link.icon }}</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>

      <v-col cols="12" xs="12" sm="6" md="3" lg="2" xl="2" class="ma-0 pa-0 d-flex justify-center mt-4">
        <div>
          <p class="text-title">Informações</p>
          <ul class="cntainer-link-informations">
            <li @click="toNavegation('/perguntas-frequentes')" class="link-informations">
              <span>Dúvidas Frequentes</span>
            </li>
            <li @click="toNavegation('/politicas/troca-devolucoes')" class="link-informations">
              <span>Política de Troca e Devoluções</span>
            </li>
            <li @click="toNavegation('/politicas/politica-privacidade')" class="link-informations">
              <span>Política de Privacidade </span>
            </li>
            <li @click="toNavegation('/politicas/termos-condicoes')" class="link-informations">
              <span>Termos e Condições</span>
            </li>
            <li @click="toNavegation('/installers')" class="link-informations">
              <span>Aplicadores Parceiros</span>
            </li>
            <li @click="toNavegation('/add-installer')" class="link-informations">
              <span>Ser Aplicador Parceiro</span>
            </li>
            <li class="link-informations">
              <a class="link-central-vendedor" :href="urlB2B" target="_blank">
                <span> Central do vendedor </span>
              </a>
            </li>
            <li @click="toNavegation('/politicas/quem-somos')" class="link-informations">
              <span>Sobre a Loja Imprimax</span>
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>

    <v-row cols="12" class="d-flex justify-center text-center ma-0 mt-1 max_width_container" >
      <p class="cnpj">
        Imprimax ®. {{ new Date().getFullYear() }}. CNPJ: 54.816.392/0001-90
        Todos os direitos reservados.
      </p>
    </v-row>

    <v-row cols="12" align="center" class="max_width_container mb-4">
      <div class="divider max_width_container">

      </div>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "externalFooter",

  data: () => ({
    urlB2B: `${process.env.VUE_APP_BASE_URL_B2B_FRONT}/auth`,
    icons: [, , "", ""],
    links: [
      {
        icon: "mdi-facebook",
        href: "https://www.facebook.com/ImprimaxAdesivos",
        text: "Facebook",
      },
      {
        icon: "mdi-instagram",
        href: "https://www.instagram.com/imprimaxadesivos/",
        text: "Instagram",
      },
      {
        icon: "mdi-linkedin",
        href: "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGwStGx-AGmFwAAAX4lnAz4_GxE6YsCz6cw4UbIneuVdpZBlBVmdEonNUW6RGEykkxa_Zg5l77EL2OJlMjc0_bhY5ydk56AdIwyvAlbQz1kJu-rUtodQ06x4k4zDraZsMxJaHI=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fimprimaxadesivos%2F",
        text: "Linkedin",
      },
      {
        icon: "mdi-whatsapp",
        href: "https://api.whatsapp.com/send?1=pt_BR&phone=5511945059235",
        text: "WhatsApp",
      },
      {
        icon: "mdi-youtube",
        href: "https://www.youtube.com/user/ImprimaxVideos",
        text: "Youtube",
      },
      {
        icon: "mdi-pinterest",
        href: "https://br.pinterest.com/imprimaxadesivos/",
        text: "Pinterest",
      },
      {
        icon: "mdi-soundcloud",
        href: "https://soundcloud.com/podcast-do-adesivo",
        text: "SoundCloud",
      },
    ],
  }),
  methods: {
    toNavegation(to) {
      this.$router.push(
          {
            path: to
          }
      )
      this.$root.$emit("platform-policies");
    }
  },
};
</script>

<style>
.cntainer-link-informations {
  padding-left: 0 !important;
  margin-top: -5px;
}

.link-informations {
  list-style: none;
  cursor: pointer;
  padding-top: 10px !important;
  font-size: 15px !important;
}

.link-informations:hover {
  text-decoration: underline;
}

.divider {
  height: 5px;
  background: #2D3038;
  box-shadow: 0px 32px 48px -2px rgba(14, 31, 53, 0.20);
}

.link-central-vendedor {
  color: #FFFFFF !important;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px !important;
  text-align: left !important;
  padding: 0px !important;
}

.link-central-vendedor > span {
  font-size: 14px !important;

}

.max_width_container {
  width: 100% !important;
}

.link-perguntas-frequentes {
  margin-left: -15px !important;
  color: #FF0000 !important;
  font-size: 17px !important;
}

.link_phone {
  font-size: 15px;
  font-weight: 400;
  text-align: left;

  color: #fff !important;
  text-decoration: none !important;
}

.text-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  color: #ffffff;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  color: #ffffff;
}

.icones:hover {
  transform: scale(1.45);
  color: red !important;
}

.cnpj {
  max-width: 60vw;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: center;
  color: #fff;
}

.link-whatsapp {
  color: #ffffff !important;
  text-decoration: none !important;
  margin-left: -12px !important;
}

.link-whatsapp:hover {
  text-decoration: underline !important;
}

.google-maps {
  width: 230px !important;
  height: 200px !important;
  border-radius: 10px !important;
  background-color: transparent !important;
  overflow: hidden !important; /* background-color: grey; */
}

.footer-mobile {
  display: none;
}

.footer-desktop {
  display: flex;
  align-items: start;
}
</style>
