<template>
  <v-card>
    <div class="py-4 px-4 d-flex justify-center align-center">
      <v-icon large> {{ icone }}</v-icon>
      <h2 class="justify-center">Cadastrar | Nova Especialidade</h2>

      <v-spacer />
      <v-btn color="primary" icon @click="close()">
        <v-icon large>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-card-text class="px-6">
      <v-form
        class="form"
        ref="thirdForm"
        v-model="thirdValid"
        lazy-validation
        @submit.prevent="submit()"
      >
        <v-col cols="12" sm="12" md="12" lg="12">
          <div>
            <p class="label-details pb-0">Nome da Especialidade</p>
            <v-text-field
              outlined
              :key="componentkey"
              v-model="currentSpecialties.name"
              :label="'Especialidade'"
              :rules="[(v) => !!v || 'Campo obrigatório!']"
              required
            ></v-text-field>
          </div>
        </v-col>
      </v-form>
      <span class="label">
        {{ textMessage }}
      </span>
      <br />
    </v-card-text>

    <v-card-actions>
      <v-row class="ma-0">
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-btn height="60" width="100%" @click="close" elevation="0" color="whtie">
            Cancelar
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-btn height="60" width="100%" elevation="0" dark @click="submit()">
            <span>Cadastrar</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #232021;
}
</style>

<script scoped>
import ApiService from "../../../services/ApiService";
import Vue from "vue";

export default {
  name: "newSpecialties",

  props: {
    textMessage: String,
    icone: String,
    title: String,
    route: String,
    typeRoute: String,
    specialties: Object,
  },

  created() {
    if (this.specialties.name != null) {
      this.currentSpecialties = this.specialties;
      this.isEditing = true;
    } else {
      this.isEditing = false;
    }
    this.componentkey++;
  },
  data: () => ({
    thirdValid: true,
    componentkey: 1,
    loading: false,
    isEditing: false,
    apiService: new ApiService(),
    currentSpecialties: {},
    newSpecialtiesDialog: false,
  }),

  computed: {},

  methods: {
    action() {
      this.$emit("action");
    },

    close() {
      this.$emit("close");
      this.isEditing = false;
    },

    async submit() {
      this.loading = true;

      if (this.$refs.thirdForm.validate()) {
        const specialties = this.currentSpecialties;

        let url = "specialties/add";
        if (this.isEditing) {
          url = "specialties/edit";
        }

        await this.apiService
          .post(url, specialties)
          .then((resp) => {
            let token = localStorage.getItem("token");
            if (token) {
              this.close();
            } else {
              this.$router.push("/auth");
            }
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "success",
              title: "Especialidade cadastrada com sucesso!",
              message: resp.message,
              trace: null,
            });

            this.loading = false;
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Erro ao cadastrar especialidade",
              message: err.body.message,
              trace: null,
            });
            this.close();

            this.loading = false;
          });
      } else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Formulário não validado",
          message: "Confira o preenchimento",
          trace: null,
        });

        this.loading = false;
      }
    },
  },
};
</script>
