<template>
  <div class="calculadora">
    <p>Informe as medidas da superfície de aplicação, em metros. Ex.:3x2,75m</p>

    <v-row class="">
      <v-col class="d-flex" cols="12" sm="3" md="4">
        <v-text-field
          type="number"
          class="inputs-calculadora"
          outlined
          dense
          placeholder="Altura"
          v-model="altura"
        >
        </v-text-field>
      </v-col>

      <p class="text-x my-2">x</p>

      <v-col cols="12" sm="3" md="4">
        <v-text-field
          type="number"
          class="inputs-calculadora"
          hide-details
          outlined
          dense
          placeholder="Largura"
          v-model="largura"
        >
        </v-text-field>
      </v-col>

      <v-col v-if="!IManagedToGetTheSelectedSkuWidth" cols="12" sm="3" md="4">
        <v-text-field
          type="number"
          class="inputs-calculadora"
          hide-details
          outlined
          dense
          placeholder="Largura do produto"
          v-model="larguraSelecionada"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="2" md="3">
        <v-btn
          :disabled="!largura || !altura"
          style=""
          align="start"
          class="secondary-input"
          elevation="0"
          height="40"
          width="100%"
          large
          @click="calcular()"
          >Calcular
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="total > 0" class="total">
      <v-col cols="12" sm="8" md="12">
        <p
          style="
            font-weight: 700;
            text-align: left !important;
            padding-left: 5px !important;
          "
        >
          Você precisa de {{ total }} metros lineares com a largura escolhida
          {{ Number(larguraSelecionada).toFixed(2) }} m
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "Subheading",
  props: {
    sku: {
      type: Object,
      default: null,
    },
    text: {
      type: String,
      default: "",
    },
  },
  computed: {},
  watch: {
    sku() {
      this.calcular();
    },
  },
  async created() {},
  data: () => ({
    altura: null,
    skuCal: {},
    largura: null,
    larguraProduto: null,
    larguraSelecionada: 0,
    IManagedToGetTheSelectedSkuWidth: true,
    total: 0,
  }),
  methods: {
    calcular() {
      if (!this.sku) {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Selecione os atributos do produto",
          message: "Selecione os atributos do produto",
          trace: null,
        });
        return;
      }
      this.larguraProduto = this.sku.attributes.find(
        (x) => x.attribute.type === "Texto"
      );
      if (this.larguraProduto) {
        const larguraProdutoAux =
          Number(this.larguraProduto.name.replace(/[^0-9]/g, "")) / 100;
        if (typeof larguraProdutoAux === "number") {
          this.IManagedToGetTheSelectedSkuWidth = true;
          const calculoAlturaLarguraProduto = this.altura * this.largura * 1.2;

          this.larguraSelecionada = larguraProdutoAux;
          this.total = Math.ceil(
            calculoAlturaLarguraProduto / larguraProdutoAux
          );
        } else {
          if (!this.larguraSelecionada) {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "warning",
              title: "",
              message: "Digite a largura do produto",
              trace: null,
            });
          }
          this.IManagedToGetTheSelectedSkuWidth = false;
          const calculoAlturaLarguraProduto = this.altura * this.largura * 1.2;
          this.total = Math.ceil(
            calculoAlturaLarguraProduto / this.larguraSelecionada
          );
        }
        this.$emit("total", this.total);
      }
    },
  },
};
</script>
<style scoped>
.inputs-calculadora {
  height: 30px !important;
  border-radius: 10px !important;
}

.total {
  background: #e0e0e0;
  margin: 5px;
  border-radius: 10px !important;
}

.calculadora {
  border: 1px dashed #979797;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px !important;
}

.text-x {
  margin-bottom: -10px !important;
  font-size: 20px;
  text-align-last: center !important;
}
@media (max-width: 600px) {
  .text-x {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
