<template>
  <v-app>
    <v-app-bar flat color="white" max-height="100" height="100">
      <div class="d-flex align-center fill-height px-6">
        <img
          src="../../assets/logo.svg"
          style="max-width: 132px; object-fit: contain"
        />
        <v-spacer></v-spacer>

        <v-icon color="black" class="mr-2" size="25">mdi-lock-outline</v-icon>
        <div>
          <strong>PAGAMENTO</strong>
          <br />
          <span>100% SEGURO</span>
        </div>
      </div>
    </v-app-bar>

    <v-main style="background: #f5f5f5">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "CheckoutLayout",

  data() {
    return {
      search: "",
    };
  },
};
</script>

<style scoped></style>
