import Layout from "../../internal/Layout.vue";
import ProductPage from "./Product.vue";
import Products from "./Products.vue";

const ProductRoutes = {
  component: Layout,
  children: [
    {
      path: "/produtos/:name",
      name: "Products",
      component: ProductPage,
      meta: {},
    },
    {
      path: "/produtos",
      name: "Products",
      component: Products,
      meta: {},
    },
  ],
};

export default ProductRoutes;
