<template>
  <v-card  class="ma-0 pa-0">
    <section class="d-flex align-center justify-space-between mt-3">
      <span class="title-payment pl-3 py-3"> Mais formas de pagamento</span>
      <v-icon
          @click="$emit('close')"
          size="30"
          color="#BC0000"
          class="mr-3"
      >
        mdi-close
      </v-icon>
    </section>
    <v-tabs
        grow
        class="mt-3 px-3"
        height="80"
        v-model="activeTab"
    >
      <v-tabs-slider color="#BC0000"></v-tabs-slider>
      <v-tab
          :value="0"
          active-class="active-tab "
          class="tab"
          @click="setActiveTab(0)"
      >
        <section>
          <svg width="40" height="40" viewBox="0 0 24 24" :fill="activeTab === 0 ? '#BC0000' : '#4F4F4F'"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 6V18C22 18.55 21.804 19.021 21.412 19.413C21.02 19.805 20.5493 20.0007 20 20H4C3.45 20 2.979 19.804 2.587 19.412C2.195 19.02 1.99934 18.5493 2 18V6C2 5.45 2.196 4.979 2.588 4.587C2.98 4.195 3.45067 3.99934 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.805 4.98 22.0007 5.45067 22 6ZM4 8H20V6H4V8ZM4 12V18H20V12H4Z"
                :fill="activeTab === 0 ? '#BC0000' : '#4F4F4F'"/>
          </svg>
          <p class="">Cartão de crédito</p>
        </section>
      </v-tab>

      <v-tab
          :value="1"
          active-class="active-tab"
          class="tab"
          @click="setActiveTab(1)"
      >
        <section>
          <svg width="40" height="40" viewBox="0 0 24 24" :fill="activeTab === 1 ? '#BC0000' : '#4F4F4F'"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.4502 16.52L12.4402 13.51C12.3302 13.4 12.2002 13.38 12.1302 13.38C12.0602 13.38 11.9302 13.4 11.8202 13.51L8.80016 16.53C8.46016 16.87 7.93016 17.42 6.16016 17.42L9.87016 21.12C10.4327 21.6818 11.1952 21.9974 11.9902 21.9974C12.7852 21.9974 13.5477 21.6818 14.1102 21.12L17.8302 17.41C16.9202 17.41 16.1602 17.23 15.4502 16.52ZM8.80016 7.47005L11.8202 10.49C11.9002 10.57 12.0202 10.62 12.1302 10.62C12.2402 10.62 12.3602 10.57 12.4402 10.49L15.4302 7.50005C16.1402 6.76005 16.9502 6.59005 17.8602 6.59005L14.1402 2.88005C13.5777 2.31824 12.8152 2.00269 12.0202 2.00269C11.2252 2.00269 10.4627 2.31824 9.90016 2.88005L6.19016 6.58005C7.95016 6.58005 8.49016 7.16005 8.80016 7.47005Z"
                :fill="activeTab === 1 ? '#BC0000' : '#4F4F4F'"/>
            <path
                d="M21.1098 9.84995L18.8598 7.58995H17.5998C17.0598 7.58995 16.5198 7.80995 16.1498 8.19995L13.1498 11.1999C12.8698 11.4799 12.4998 11.6199 12.1298 11.6199C11.7488 11.6146 11.3841 11.4644 11.1098 11.1999L8.0898 8.16995C7.7098 7.78995 7.1898 7.56995 6.6398 7.56995H5.1698L2.8798 9.86995C2.318 10.4324 2.00244 11.1949 2.00244 11.9899C2.00244 12.7849 2.318 13.5474 2.8798 14.1099L5.1698 16.4099H6.6498C7.1898 16.4099 7.7098 16.1899 8.0998 15.8099L11.1198 12.7899C11.3998 12.5099 11.7698 12.3699 12.1398 12.3699C12.5098 12.3699 12.8798 12.5099 13.1598 12.7899L16.1698 15.7999C16.5498 16.1799 17.0698 16.3999 17.6198 16.3999H18.8798L21.1298 14.1399C21.694 13.5673 22.0085 12.7947 22.0048 11.9908C22.001 11.187 21.6793 10.4173 21.1098 9.84995Z"
                :fill="activeTab === 1 ? '#BC0000' : '#4F4F4F'"/>
          </svg>

          <p class="">Pix</p>
        </section>
      </v-tab>

      <v-tab
          :value="2"
          active-class="active-tab"
          class="tab"
          @click="setActiveTab(1)"
      >
        <section>
          <svg width="40" height="40" viewBox="0 0 24 24" :fill="activeTab === 2 ? '#BC0000' : '#4F4F4F'"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 19V5H3V19H1ZM4 19V5H6V19H4ZM7 19V5H8V19H7ZM10 19V5H12V19H10ZM13 19V5H16V19H13ZM17 19V5H18V19H17ZM20 19V5H23V19H20Z"
                :fill="activeTab === 2 ? '#BC0000' : '#4F4F4F'"/>
          </svg>
          <p class="">Boleto Bancário</p>
        </section>
      </v-tab>
    </v-tabs>

    <v-tabs-items
        v-model="activeTab"
        class="px-3"
        style="border-radius: 6px"
    >
      <v-tab-item :value="0">
        <section class="parcelas my-5 pa-3">
          <table style="width: 100%!important;">
            <tbody>
            <tr v-for="installment in installments" :key="installment.value">
              <td  v-if="installment.value <= 3" class="d-flex justify-space-between px-2 py-3">
                <span class="parcela">{{installment.value}}x de {{Number((installment.totalAmountOrdered / installment.value).toFixed(2)).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
                  }} <span style="color: #666666; font-size: 12px">sem juros </span></span>
                <span>{{installment.totalAmountOrdered.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}}</span>
              </td>

              <td v-else class="d-flex justify-space-between px-2 py-3">
                <span class="parcela">{{installment.value}}x de {{Number((installment.totalAmountOrdered / installment.value).toFixed(2)).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}} <span style="color: #666666; font-size: 12px">com juros </span></span>
                <span>{{installment.totalAmountOrdered.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </section>
      </v-tab-item>

      <v-tab-item :value="1">
        <section class="parcelas my-5 pa-3">
          <span class="parcela">
            {{ installments[0].totalAmountOrdered.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
            }) }} no Pix.
            Aprovação imediata do pagamento</span>
        </section>
      </v-tab-item>

      <v-tab-item :value="2">
        <section class="parcelas my-5 pa-3">
          <span class="parcela">
            {{ installments[0].totalAmountOrdered.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }) }} 2 a 3 dias úteis para compensação do pagamento.</span>
        </section>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>


<script>
export default {
  name: "PaymentMethodsModal",

  props: {
    totalAmountOrdered: {type: Number, default: 0, required: true},
    installments: {type: Array, required: true},
  },

  data: () => ({
    activeTab: 0,
  }),

  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    close() {
      this.$emit("close");
    },

    action() {
      this.$emit("action");
    },
  },
};
</script>
<style scoped>
.title-payment {
  font-weight: 700;
  font-size: 20px;
  color: #666666
}

.tab {
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  color: #4F4F4F !important;
  border-bottom: 1px solid #DEDEDE;
  font-size: 13px;
}

.active-tab {
  font-size: 13px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  color: #BC0000 !important;
}

.parcelas {
  border: 1px solid #DEDEDE;
  border-radius: 5px;
}
.parcela{
  color: #333333;
  font-size: 16px;
}
tr:nth-child(even) {
  background-color: rgb(241, 241, 241);
}
@media (max-width: 600px) {

}

@media (max-width: 600px) {

}
</style>
