<template>
  <div>
    <v-dialog
        v-model="attention"
        max-width="620"
    >
      <template v-slot:default>
        <v-card>
          <v-card-text>
            <h1 class="text-center" style="color: #bc0000; font-weight: bold">ATENÇÃO</h1>
          </v-card-text>
          <v-row
              cols="12"
              sm="12"
              md="12"
              lg="12"
              class="ma-0 pa-0"
          >
            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center">
              <p class="text-info px-5">
                Os profissionais listados são parceiros independentes e credenciados através do nosso centro de treinamento (ImprimaxTec). A imprimax
                <strong>não se responsabiliza </strong>por problemas na aplicação ou contratação destes.
              </p>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center">
              <v-btn
                  :elevation="0"
                  :loading="loading"
                  class="primary-button"
                  @click="attention =  false"
              >
                Fechar
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>

    <v-container class="">
      <v-card elevation="0" class="pa-5 ma-0 " style="background: #efd8d8">
        <h4 class="card-t1">APLICADORES PARCEIROS</h4>

        <p class="mt-2">
          <strong style="color: #bc0000">ATENÇÃO: </strong>
          <span v-html="textAttention.substring(0, showTextAttention ? textAttention.length : 182)"></span>
          <span v-if="!showTextAttention"> ... </span>
          <a style="color: #bc0000;font-weight: bold" @click="showTextAttention = !showTextAttention">
            {{
              showTextAttention ? "Ver menos" : "Ver mais"
            }}

            <v-icon color="black" size="30">
              {{ showTextAttention ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
            </v-icon>
          </a>
        </p>
      </v-card>
      <div v-if="installers && installers.length == 0" class="py-10 my-10">
        <span class="empty-message pa-0 ma-0">Não localizado</span><br />
        <span class="empty-label pa-0 ma-0">
          Não encontramos nenhum aplicador com essa especilidade próximo a você.
        </span>
      </div>

      <v-card class="rounded-xl filter-card pa-6">
        <v-row class="ma-0" align="center" justify="space-between">
          <v-col cols="12" sm="4" md="4">
            <v-select
              class="rounded-lg"
              v-model="filter.specialtyName"
              placeholder="Especialidade"
              :items="typesInstallers"
              outlined
              hide-details
              color="black"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3" md="2" class="d-flex justify-center">
            <v-autocomplete
              class="rounded-lg"
              placeholder="Cidade"
              outlined
              hide-details
              v-model="filter.city"
              :items="citys"
            >
            </v-autocomplete
          ></v-col>
          <v-col
            style="min-width: 300px"
            cols="12"
            sm="5"
            md="5"
            class="d-flex justify-center"
          >
            <div style="min-width: none">
              <v-btn
                depressed
                large
                outlined
                class="rounded-lg mr-2"
                style="text-transform: none"
                @click="resetFilter"
              >
                Limpar Filtros
              </v-btn>

              <v-btn
                large
                depressed
                class="rounded-lg"
                color="black"
                style="text-transform: none"
                @click="getInstallers"
              >
                <div class="d-flex">
                  <v-icon class="mr-2">mdi-filter</v-icon>
                  <span> Aplicar Filtros </span>
                </div>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="ma-0" dense>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          v-for="(item, i) in installers"
          :key="i"
          class="d-flex justify-center align-center"
        >
          <ImageCardInstaller
            class="image-card-hover"
            :item="item"
            :env_url="env_url"
            @action="goTo(item)"
          />
        </v-col>
      </v-row>
      <div>
        <v-pagination
          class="my-4"
          v-model="page"
          :length="totalPages"
          :total-visible="15"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import ApiService from "../../../services/ApiService";
import ImageCardInstaller from "../components/ImageCardInstaller.vue";

import Vue from "vue";

export default {
  components: { ImageCardInstaller },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  data: () => ({
    page: 1,
    totalPages: null,
    typesInstallers: [
      "Envelopamento de veículos e frotas",
      "Envelopamento de eletrodomésticos",
      "Envelopamento de móveis",
      "Envelopamento de paredes",
      "Envelopamento de motos",
      "Envelopamento de chão e escadas",
      "Envelopamento de teto",
      "Decoração no geral",
    ],
    addressIdSelect: "",
    componentKey: 1,
    endereco: [],
    addresses: [],
    cart: [],
    user: [],
    frete: [],
    valor: "",
    isMobile: false,
    badge: "",
    attention: false,
    clearDialog: false,
    dialogKey: false,
    loading: false,
    installers: {},
    citys: [],
    filter: {
      page: 1,
      recommendation: 1,
      pageSize: 15,
      totalPages: null,
      specialtyName: null,
    },

    env_url: process.env.VUE_APP_BASE_URL,
    apiService: new ApiService(),
    showTextAttention: false,
    textAttention: ` Os profissionais que você encontra nessa lista são parceiros que oferecem servições de instalação dos nossos produtos. Eles são independentes e responsáveis
                    pela qualidade e garantia do seu trabalho. A imprimax <strong>não se responsabiliza </strong>por qualquer problema que possa ocorrer na aplicação ou na contratação do profissional. <br/>
                    Recomendamos que você verifique as referências e avaliações de cada um antes de contratar o serviço. <br/>
                    Os profissionais aqui listados passam por uma avaliação rigorosa em nosso centro de treinamento e por isso foram credenciados aptos a proporcionar um serviço de qualidade conforme recomendação da Imprimax.
                    Atestamos a competência e experiência destes profissionais para realizar uma instalação com segurança e eficiência`
  }),

  computed: {
    TotalPrice() {
      let result = 0;
      this.cart.forEach((e) => {
        result = result + e.finalPrice * e.qty;
      });
      if (this.valor) {
        return parseFloat(result + parseFloat(this.valor));
      }

      return parseFloat(result);
    },
  },
  watch: {
    async registerCart() {},
    endereco() {
      this.calcularFrete(this.endereco.postalCode);
    },

    cart() {
      this.$root.$on("changeCart", (data) => {
        this.cart = data;
        this.componentKey++;
      });
    },

    page() {
      this.filter.page = this.page;
      this.getInstallers();
    },

    badge() {
      this.$root.$on("changeBadge", (data) => {
        this.badge = data;
      });
    },
  },

  async created() {
    this.attention = true;
    this.cart = JSON.parse(localStorage.getItem("cartUser") || "[]");
    this.badge = JSON.parse(localStorage.getItem("cartBadge"));
    this.user = JSON.parse(localStorage.getItem("user"));
    this.addressIdSelect = JSON.parse(localStorage.getItem("addressIdSelect"));
    this.getInstallers();
    //this.getAdress(this.user, this.addressIdSelect);
  },
  methods: {
    async resetFilter() {
      (this.filter = {
        page: 1,
        status: 1,
        pageSize: 15,
        SpecialtyName: null,
        recommendation: 1,
      }),
        await this.getInstallers();
    },
    getInstallers() {
      this.apiService.post("installer/list", this.filter).then((resp) => {
        this.installers = resp.content.list.installers;
        this.installers.forEach((item) => {
          this.citys.includes(item.city) ? null : this.citys.push(item.city);
        });
        this.totalPages = resp.content.list.pager.totalPages;
      });
    },

    async buyCartNow(route) {
      if (this.addresses && this.addresses.length != 0) {
        localStorage.setItem("deliveryAddress", JSON.stringify(this.endereco));

        this.goToRoute(route);
      } else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Cadastre um endereço para entrega",
          message: "Cadastre um endereço para entrega",
          trace: null,
        });
      }
      page;
    },

    dialogClear() {
      this.clearDialog = true;
    },
    updateCart() {
      const cartFromLocalStorage = JSON.parse(
        localStorage.getItem("cartUser") || "[]"
      );
      if (cartFromLocalStorage) {
        this.cart = cartFromLocalStorage;
        this.badge = this.cart.reduce((total, item) => {
          return total + item.qty;
        }, 0);

        this.totalprice = this.cart.reduce((total, item) => {
          return total + item.qty * item.price;
        }, 0);
      }
      //Option1- Evento Global

      this.$root.$emit("changeBadge", this.badge);
      //Option2- Coloca o badge no localhost para o navigation pegar
      localStorage.setItem("cartBadge", JSON.stringify(this.badge));
    },

    async getAdress(user) {
      if (user) {
        this.loading = true;
        await this.apiService
          .get(`client/get-addresses?id=` + user.id)
          .then((response) => {
            this.addresses = response;
            if (this.addresses && this.addresses.length != 0) {
              if (this.addressIdSelect) {
                this.endereco = this.addresses.find(
                  (e) => e.id === this.addressIdSelect
                );
              } else {
                this.endereco = this.addresses.find((e) => e.addressType === 0);
              }
            } else {
              this.goToRoute("/address");
            }

            this.loading = false;
          })
          .catch((err) => {});

        this.loading = false;
      } else {
        this.goToRoute("/login");
      }
    },

    async calcularFrete(cep) {
      const dados = {
        CdServico: "04510",
        CepOrigem: cep,
        CepDestino: "04547000",
        VlPeso: "1",
        VlComprimento: "20",
        VlAltura: "2",
        VlLargura: "10",
        VlDiametro: "0",
      };
      const xml2js = require("xml2js");
      const util = require("util");
      const parser = new xml2js.Parser({
        explicityArray: false,
      });

      var client = new ApiService();
      await client
        .post(`client/frete`, dados)
        .then((response) => {
          parser.parseString(response, (err, result) => {
            this.frete = result.Servicos.cServico[0];
            this.valor = this.frete.Valor[0];
          });
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Não foi possível calcular o Frete",
            message: err.body.message,
            trace: null,
          });
        });
    },

    async clearCart() {
      localStorage.removeItem("cartUser");
      localStorage.setItem("cartBadge", 0);
      await this.updateCart();
      this.componentKey += 1;
    },
    goTo(item) {
      this.$router.push(`/detail-installer?id=${item.id}`);
    },
    goToRoute(route) {
      if (this.$route.path != route) {
        this.$router.push(route);
      }
    },

    async newAddress(model) {
      await this.$router.push({
        path: "/address?" + model,
        params: model,
      });
    },

    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
  },
};
</script>

<style scoped>
.text-info{
  font-size: 18px;
  text-align: center;
}
.filter-card {
  box-shadow: 0px 2px 4px rgba(14, 31, 53, 0.06),
    0px 6px 12px rgba(14, 31, 53, 0.08), 0px 12px 20px rgba(14, 31, 53, 0.06) !important;
}
.card-t1 {
  font-style: normal;
  font-weight: 700;
  color: black;
  font-size: 20px;
}

.card-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  color: #9c9c9c;
}

.list-product-content {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(280px, auto));
}
.divInstallers:hover {
  box-shadow: 10px 10px;
}
.image-card-hover:hover {
  box-shadow: 10px 11px 13px rgba(0, 0, 0, 0.25) !important;
}
.card-subprice {
  font-family: Poppins;
  font-style: normal;
  font-size: 0.9rem;
  line-height: 24px;

  letter-spacing: 0.15px;

  color: #979797;
}
.price-total {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: black;
}
.label-cart {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1.45rem;
  line-height: 36px;
  letter-spacing: 0.15px;

  color: #000000;
}
.price-frete {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.55rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: #979797;
}
.frete {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 37px;
  letter-spacing: 0.15px;

  color: #979797;
}
.frete:hover {
  cursor: pointer;
}
.span-red {
  align-self: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: #bc0000;
}
.span-red:hover {
  cursor: pointer;
}
.empty-message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 3.2rem;

  color: #97999d;
}
.empty-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;

  color: #97999d;
}

@media (min-width: 600px) {
  .card-cart {
    width: 70%;
    background: #ffffff;
    box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
    border-radius: 6px;
  }
}

.v-card__text {
  padding: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
}
.containerList {
  justify-content: center !important;
  flex-direction: column;
}
</style>
