<template>
  <div :key="dialogKey">
    <v-card class="card pa-6 mx-auto" elevation="2">
      <div class="d-flex flex-column">
        <span class="label py-2"> Obrigado por comprar conosco! </span>
        <span class="label observation"> Avalie sua experiência com os produtos </span>
      </div>
      <v-row
          cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
          class="order"
          v-for="(item, idw) in orderEvaluation.items"
          :key="idw"
      >
        <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
          <v-img class="img-product" :src="env_url + item.product.productPic"></v-img>
        </v-col>

        <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
               style="display: flex; justify-content: space-between; align-items: center">
          <div class="ma-0 pa-0" style="text-align: start">
            <div>
              <span class="card-title pa-0 ma-0">{{ item.product.productName }}</span>
              <br/>
              <span class="pa-0 ma-0"> Cód: {{ item.product.productCode }}</span>
            </div>

            <v-row v-for="(attribute, attributesId) in item.productSku.attributes" :key="attributesId"
                   class=" text--primary pa-0 ma-0 card-inside">
              <div class="atributos">
                <p class="span-btnCard">{{ attribute.name }}</p>
              </div>
            </v-row>

            <div class="py-3">
                <span v-if="item.product.calculator" style="color: #bc0000; font-weight: bolder">
                  {{ item.qty }} Metros(s)
                </span>

                <span v-else style="color: #bc0000; font-weight: bolder">
                  Quantidade: {{ item.qty }}
                </span>
            </div>
          </div>

          <div class="d-flex-column pa-0 ma-0 px-0 pt-1"
               style="display: grid; text-align-last: right; align-content: space-between; height: 100%">
            <p class="card-subprice"> {{ parseDate(currentOrder) }}</p>
            <p class="card-price ">{{
                (item.price * item.qty).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })
              }}</p>
          </div>
        </v-col>

        <v-row>
          <v-col cols="12">
            <div class="align-center mb-2">
              <label class="mt-2">Sua nota para o produto: <strong>{{ item.product.productName }}</strong></label>
              <v-rating
                  v-model="item.note"
                  background-color="yellow"
                  color="yellow"
                  size="20"
                  clearable
              ></v-rating>
            </div>
            <div>
              <label class="my-2">Insira seu comentário para o produto: <strong>{{ item.product.productName }}</strong></label>
              <v-textarea
                  name="input-7-1"
                  class="textArea "
                  outlined
                  :placeholder="`Digite seu comentário para o produto: ${ item.product.productName }`"
                  v-model="item.evaluation"
              ></v-textarea>
            </div>
          </v-col>
        </v-row>
      </v-row>

      <v-row class="mt-2">
        <span class="label observation"> Avalie sua experiência em nossa loja </span>
        <v-col class="" cols="12">
          <div class="align-center">
            <label class="mt-2">Sua nota para o pedido</label>
            <v-rating
                v-model="orderEvaluation.note"
                background-color="yellow"
                color="yellow"
                size="20"
                clearable
            ></v-rating>
          </div>
          <div class="mt-2">
            <label class="my-2">Seu comentário para o pedido</label>
            <v-textarea
                name="input-7-1"
                class="textArea "
                outlined
                placeholder="Digite seu comentário para o pedido"
                v-model="orderEvaluation.evaluation"
            ></v-textarea>
          </div>
        </v-col>
      </v-row>

      <v-row justify="center" class="py-4" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <v-btn width="100%" elevation="0" :loading="loading" class="secondary-button" @click="$emit('close')">
            Cancelar
          </v-btn>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <v-btn width="100%" elevation="0" :loading="loading" class="primary-button" @click="submit()">
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<style scoped>
.img-product {
  width: 100% !important;
  height: 120px !important;
  border-radius: 3px !important;
}

.order {
  border-bottom: 1px solid #b9b9b9;
  padding-bottom: 20px;
}

.card-title {
  font-size: 1.4rem;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: 0.15px;
  text-align: start;
  color: #000000;
}

.card-subprice {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;

  color: #000000;
}

.card-price {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 27px;

  letter-spacing: 0.15px;

  color: #000000;
}

.card-inside {
  display: inline-block;
  padding: 10px;
}

.atributos {
  background: #2D3038;
  margin-right: 10px;
  border-radius: 3px;
}

.span-btnCard {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: #dadada;
  padding: 5px;

}

.textArea {
  width: 100%;
  height: 130px;
  background: #f5f5f5;
  box-sizing: border-box;
  margin-top: 10px !important;
  border-radius: 5px;
}


.okBtn {
  transition: 0.3s;
  font-weight: bold !important;
  box-sizing: border-box !important;
  background: #bc0000 !important;
  width: 200px;

  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #ffffff;
  border-radius: 10px !important;
}

.okBtn:hover {
  color: #fff !important;
  background: #e8161e !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2) !important;
}

.cancelBtn {
  transition: 0.3s;
  font-weight: bold !important;
  background-color: #ffffff !important;
  border: 1.5px solid #2d3038 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

.cancelBtn:hover {
  color: #fff !important;
  background: #000000 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: justify;
  letter-spacing: 0.15px;

  color: #bc0000;
}

.observation {
  font-family: Roboto;
  font-style: normal;
  color: grey;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  text-align: justify;
  letter-spacing: 0.15px;

  color: #232021;
}

@media (max-width: 600px) {
  .select-type {
    width: 50% !important;
  }
}

@media (max-width: 600px) {
  .form {
    flex-direction: column;
  }
}
</style>

<script>
import ApiService from "../../../services/ApiService";
import Vue from "vue";
import Utils from "../../../Utils/FormatDate";

export default {
  name: "RatingModal",

  props: {
    currentOrder: Object,
  },

  data: () => ({
    step: 1,
    env_url: process.env.VUE_APP_BASE_URL,
    componentKey: 1,
    dialogKey: 1,
    indexProduct: 0,
    loading: false,
    rating: null,
    buttonDisabled: true,
    apiService: new ApiService(),
    user: {},
    orderEvaluation: {}
  }),

  async created() {
    if (this.currentOrder) {

    }
  },
  async mounted() {
    this.orderEvaluation = {
      oderId: this.currentOrder.id,
      clientId: this.currentOrder.items[0].clientId,
      items: this.currentOrder.items.map((item) => {
        return {
          ...item,
          evaluation: '',
          note: 3
        }
      }),
      evaluation: '',
      note: 3
    }
  },
  methods: {
    parseDate(order) {
      return Utils.moment(order.created_at);
    },
    close() {
      this.$emit("close");
    },

    async submit() {
      try {
        await this.apiService
            .post("order/evaluateOrder", this.orderEvaluation)
            .then((response) => {
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "success",
                title: response.message,
                message: response.message,
                trace: null,
              });
              this.loading = false;
              this.close();
            })
            .catch((err) => {
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "error",
                title: "Erro avaliar pedido",
                message: "Erro avaliar pedido",
                trace: null,
              });
              this.loading = false;
            });
      } catch (e) {

      }
    }
  },

  watch: {
    rating() {
      this.buttonDisabled = this.rating == null;
    }
  }
};
</script>
