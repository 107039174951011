import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,_vm._b({attrs:{"transition":_vm.transition,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":_vm.color,"default":"","min-width":"200","rounded":""}},'v-btn',attrs,false),on),[_vm._t("default"),_c(VIcon,[_vm._v(" mdi-"+_vm._s(_vm.value ? "menu-up" : "menu-down")+" ")])],2)]}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'v-menu',_vm.$attrs,false),[_c(VSheet,[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.$(("click:action-" + (item.id)))}}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }