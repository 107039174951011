<template>
  <v-main id="mainHome" class="mainHome">
    <div class="max_width_container">
      <!-- CARROSEL DE BANNERS -->
      <v-carousel
        style="height: 400px !important"
        :show-arrows="false"
        hide-delimiter-background
        show-arrows-on-hover
        eager
        :key="componentKey"
        cycle
        interval="4000"
      >
        <v-carousel-item
          :v-if="banners"
          v-for="(banner, i) in banners"
          target="_blank"
          :key="i"
        >
          <a :href="banner.link" target="_blank">
            <img
              class="carousel-item-img"
              :src="`${isMobile ? banner.imageMobile : banner.image}`"
              alt="Revestimentos adesivos laváveis"
            />
          </a>
        </v-carousel-item>

        <v-carousel-item
          :v-if="!banners"
          v-for="(banner, i) in noBanners"
          :href="banner.link"
          target="_blank"
          :key="i"
          :src="banner.image"
        ></v-carousel-item>
      </v-carousel>

      <v-row cols="12" class="ma-0" align="center">
        <v-col cols="4" class="container-desktop-itens">
          <div class="d-flex justify-center">
            <v-img
              class="img-width"
              src="../../../assets/revestimento.svg"
              alt="Revestimentos adesivos laváveis"
              contain
            ></v-img>
          </div>

          <div class="revendedor-aplicadores-parceiros">
            <p class="revendedor-aplicadores-parceiros-title">
              Revestimentos e <br />
              adesivos laváveis
            </p>

            <p class="revendedor-aplicadores-parceiros-text">
              Escolha ideal para <br />
              envelopamentos
            </p>
          </div>
        </v-col>

        <v-col cols="4" class="container-desktop-itens">
          <div class="d-flex justify-center">
            <v-img
              class="img-width"
              src="../../../assets/caixa.svg"
              alt="Sempre à disposição"
              contain
            ></v-img>
          </div>

          <div class="revendedor-aplicadores-parceiros">
            <p class="revendedor-aplicadores-parceiros-title">
              Sempre à <br />
              disposição
            </p>

            <p class="revendedor-aplicadores-parceiros-text">
              Produtos a pronta <br />
              entrega
            </p>
          </div>
        </v-col>

        <v-col cols="4" class="container-desktop-itens">
          <div class="d-flex justify-center">
            <v-img
              class="img-width"
              src="../../../assets/check-parceiro.svg"
              alt="Parceiros Confiáveis"
              contain
            ></v-img>
          </div>

          <div class="revendedor-aplicadores-parceiros">
            <p class="revendedor-aplicadores-parceiros-title">
              Parceiros <br />
              Confiáveis
            </p>

            <p
              class="revendedor-aplicadores-parceiros-text hidden-cards-mobile"
            >
              Revendedores <br />autorizados
            </p>
          </div>
        </v-col>
      </v-row>

      <v-card elevation="0" class="container-promocoes-lancamentos ma-0 pa-0">
        <v-row cols="12">
          <v-col cols="6" class="text-left">
            <p
              v-if="category && category.categoryName"
              class="promocoes-lancamentos"
            >
              {{ formatName(category.categoryName) }}
            </p>
          </v-col>
          <v-col cols="6" class="text-right">
            <a @click="to('produtos')" class="ver-produtos"> Ver todos os produtos </a>
          </v-col>
        </v-row>

        <v-row style="position: relative">
          <div v-if="arrowLeftListProductContent" class="arrow-left">
            <v-img
              max-width="80"
              @click="scrollClickListProductContent(-1, 608, $event)"
              src="../../../assets/arrow-right.svg"
              alt="Sempre à disposição"
              contain
            ></v-img>
          </div>

          <v-row
            cols="12"
            id="list-product-content"
            class="list-product-content ma-0 pa-0"
          >
            <v-col v-for="item in products" :key="item.productId">
              <ImageCard
                :key="item.productId + page"
                class="image-card-hover"
                :item="item"
                :env_url="env_url"
                @action="goTo(item)"
              />
            </v-col>
          </v-row>

          <div v-if="arrowRightListProductContent && products.length > 0" class="arrow-right">
            <v-img
              max-width="80"
              @click="scrollClickListProductContent(1, 608, $event)"
              src="../../../assets/arrow-right.svg"
              alt="Sempre à disposição"
              contain
            ></v-img>
          </div>
        </v-row>
      </v-card>

      <v-card elevation="0" class="container-promocoes-lancamentos">
        <v-row cols="12">
          <v-col cols="6" class="text-left">
            <p class="promocoes-lancamentos">As principais categorias</p>
          </v-col>
          <v-col cols="6" class="text-right"> </v-col>
        </v-row>

        <v-row style="position: relative">
          <div
            v-if="arrowLeftCategory"
            class="arrow-left"
            style="top: 35px; display: block"
          >
            <v-img
              max-width="80"
              @click="scrollClickListCategory(-1, 150, $event)"
              src="../../../assets/arrow-right.svg"
              alt="Sempre à disposição"
              contain
            ></v-img>
          </div>

          <v-row cols="12" class="flex-nowrap categories" id="list-categories">
            <v-col v-for="item in categories" :key="item.categoryId">
              <a @click="toCategory(item.categoryName)">
                <div class="category-clicle">
                  <v-img
                    v-if="item.pathImage"
                    style="
                      width: 120px;
                      height: 120px;
                      border-radius: 50%;
                      display: flex;
                      margin: 7px 0 0 0;
                    "
                    :src="item.pathImage"
                    :alt="item.categoryName"
                  ></v-img>

                  <v-img
                    v-else
                    style="
                      width: 120px;
                      height: 120px;
                      border-radius: 50%;
                      display: flex;
                      margin: 7px 0 0 0;
                    "
                    src="../../../assets/categoria.svg"
                    :alt="item.categoryName"
                  ></v-img>
                </div>
                <p class="mt-2 categoryName text-center">
                  {{ item.categoryName }}
                </p>
              </a>
            </v-col>
          </v-row>

          <div
            v-if="arrowRightCategory"
            class="arrow-right"
            style="bottom: 105px; display: block"
          >
            <v-img
              max-width="80"
              @click="scrollClickListCategory(1, 150, $event)"
              src="../../../assets/arrow-right.svg"
              alt="Sempre à disposição"
              contain
            ></v-img>
          </div>
        </v-row>
      </v-card>

      <v-row cols="12">
        <v-col cols="12" class="d-flex justify-center">
          <img
            v-if="bannersSecondary"
            class="carousel-item-img"
            :src="`${isMobile ? bannersSecondary.imageMobile : bannersSecondary.image}`"
            :alt="bannersSecondary.title"
          />
          <img
            v-else
            class="carousel-item-img"
            v-bind:src="
              require(`../../../assets/${
                isMobile ? 'bannerSecundaryMobile' : 'bannerSecundary'
              }.png`)
            "
            alt="Imprimax"
          />
        </v-col>
      </v-row>

      <v-card elevation="0" v-if="productsBestSellers && productsBestSellers.length > 0" class="container-promocoes-lancamentos">
        <v-row cols="12">
          <v-col cols="6" class="text-left">
            <p class="promocoes-lancamentos">Os mais vendidos</p>
          </v-col>
          <v-col cols="6" class="text-right">
            <a @click="to('produtos')" class="ver-produtos"> Ver todos os produtos </a>
          </v-col>
        </v-row>

        <v-row style="position: relative">
          <div v-if="arrowLeftMaisVendidos" class="arrow-left">
            <v-img
              max-width="80"
              @click="scrollClickMaisVendidos(-1, 608, $event)"
              src="../../../assets/arrow-right.svg"
              alt="Sempre à disposição"
              contain
            ></v-img>
          </div>

          <v-row
            cols="12"
            id="maisVendidos"
            class="list-product-content ma-0 pa-0"
          >
            <v-col
              v-for="item in productsBestSellers"
              :key="`maisVendidos${item.productId}`"
            >
              <ImageCard
                :key="`maisVendidos${item.productId}`"
                class="image-card-hover"
                :item="item"
                :env_url="env_url"
                @action="goTo(item)"
              />
            </v-col>
          </v-row>

          <div v-if="arrowRightMaisVendidos" class="arrow-right">
            <v-img
              max-width="80"
              @click="scrollClickMaisVendidos(1, 608, $event)"
              src="../../../assets/arrow-right.svg"
              alt="Sempre à disposição"
              contain
            ></v-img>
          </div>
        </v-row>
      </v-card>
    </div>
  </v-main>
</template>

<style scoped>
.carousel-item-img {
  border-radius: 8px;
  width: 100%;
  height: 400px !important;
}

.max_width_container {
  width: 80% !important;
  margin: 0 10% 0 10%;
}

.container-desktop {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.container-desktop-itens {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-width {
  max-width: 100px;
}

@media screen and (max-width: 885px) {
  .container-desktop {
    display: flex;
    flex-direction: row;
  }

  .container-desktop-itens {
    display: block;
    align-items: center;
  }

  .revendedor-aplicadores-parceiros-text {
    text-align: center !important;
    font-size: 12px !important;
  }

  .revendedor-aplicadores-parceiros-title {
    text-align: center !important;
    font-size: 13px !important;
  }

  .img-width {
    max-width: 60px;
  }

  .max_width_container {
    width: 100% !important;
    margin: 0 !important;
  }
}

@media screen and (max-width: 700px) {
  .arrow-left {
    display: none;
  }

  .arrow-right {
    display: none;
  }

  .list-product-content {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(160px, auto));
  }
}

.container-promocoes-lancamentos {
  box-shadow: 0 72px 132px 0 #051d3d0f !important;
  padding: 10px 20px !important;
}

.promocoes-lancamentos {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15000000596046448px;
  color: #2d3038;
  position: relative;
}

.ver-produtos {
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #bc0000;
  text-decoration: none;
}

.image-card-hover:hover {
  box-shadow: 10px 11px 13px rgba(0, 0, 0, 0.25) !important;
}

.list-product-content {
  flex-wrap: nowrap !important;
  overflow: hidden;
  scroll-behavior: smooth;
  z-index: 0;
}

.arrow-left {
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 125px;
  z-index: 1;
  transform: rotate(180deg);
}

.arrow-right {
  position: absolute;
  cursor: pointer;
  right: -20px;
  bottom: 180px;
  z-index: 1;
}

.revendedor-aplicadores-parceiros {
  text-align: left;
  padding-left: 15px;
}

.revendedor-aplicadores-parceiros-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  margin: 0;
  padding: 5px 0;
  color: #4f4f4f;
}

.revendedor-aplicadores-parceiros-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #6d7989;
}

.categories {
  flex-wrap: nowrap !important;
  overflow: hidden;
  scroll-behavior: smooth;
  z-index: 0;
}

.category-clicle {
  border: 8px solid transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(180deg, #ff0000 0%, #9b0707 48.96%, #3f0909 100%);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.categoryName {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
  color: #4f4f4f;
}

.v-image__image {
  max-height: 270px !important;
  height: 60% !important;
}

.v-item-group {
  height: 320px !important;
}

.transparent-background {
  background-color: transparent;
}

.v-card__title {
  padding: 0px;
  font-family: Poppins;
  width: max-content;
  color: #bc0000;
  font-style: normal;
  font-weight: bold;
  line-height: 27px;
}

.v-card__text {
  padding: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
}

.containerList {
  justify-content: center !important;
  flex-direction: column;
}

.mainHome {
  width: 100%;
  padding-top: 0px !important;
  max-width: unset !important;
  text-align: -webkit-center;
  background-color: #f5f5f5;
}

.banner-text {
  text-align: center;
  color: #ffffff;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.15px;
}

.banner-text-destac {
  color: #fede04;
}
</style>

<script>
import ApiService from "../../../services/ApiService";
import FormatParamsUrl from "../../../Utils/FormatParamsUrl";
import ImageCard from "../components/ImageCard.vue";

export default {
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    document.title = 'Loja imprimax'
  },

  data: () => ({
    page: 1,
    arrowRightListProductContent: true,
    arrowLeftListProductContent: false,
    arrowRightMaisVendidos: true,
    arrowLeftMaisVendidos: false,
    arrowRightCategory: true,
    arrowLeftCategory: false,
    totalPages: 15,
    banners: [],
    bannersSecondary: null,
    noBanners: [],
    componentKey: 1,
    isMobile: false,
    products: [],
    productsBestSellers: [],
    categories: [],
    env_url: "",
    boxNotification: "",
    category: "",
    apiService: new ApiService(),
    filter: {
      page: 1,
      pageSize: 20,
      bestSellers: true,
    },
    categoryFilter: {
      page: 1,
      pageSize: 20,
      categoryShowHomepage: 0,
    },
  }),

  components: {
    ImageCard,
  },

  computed: {},

  async created() {
    this.env_url = process.env.VUE_APP_BASE_URL;
    await Promise.all([
      await this.getCategories(),
      await this.productsByCategory(),
      await this.topSellingProducts(),
      await this.bringBanners(),
    ]);
  },
  methods: {
    toCategory(category) {
      let cat = category.replace(/ /g, "-").toLowerCase();
      this.$router.push(`/categoria/${cat}`)
    },
    formatName(productName) {
      return (
        productName[0].toUpperCase() + productName.substring(1).toLowerCase()
      );
    },

    async getCategories() {
      await this.apiService
        .post(`category/menuActive`, this.filter)
        .then((response) => {
          this.categories = response.categories;
        })
        .catch((err) => {});
    },

    scrollClickListProductContent(direcao, px, event) {
      const listProductContent = document.getElementById(
        "list-product-content"
      );
      const divWidth =
        listProductContent.scrollWidth - listProductContent.clientWidth;
      listProductContent.scrollLeft += px * direcao;
      event.preventDefault();

      this.arrowRightListProductContent = !(
        listProductContent.scrollLeft === divWidth
      );
      this.arrowLeftListProductContent = !(listProductContent.scrollLeft === 0);
    },

    scrollClickListCategory(direcao, px, event) {
      const listCategories = document.getElementById("list-categories");
      const divWidth = listCategories.scrollWidth - listCategories.clientWidth;
      listCategories.scrollLeft += px * direcao;
      event.preventDefault();

      this.arrowRightCategory = !(listCategories.scrollLeft === divWidth);
      this.arrowLeftCategory = !(listCategories.scrollLeft === 0);
    },

    scrollClickMaisVendidos(direcao, px, event) {
      const maisVendidos = document.getElementById("maisVendidos");
      const divWidth = maisVendidos.scrollWidth - maisVendidos.clientWidth;
      maisVendidos.scrollLeft += px * direcao;
      event.preventDefault();

      this.arrowRightMaisVendidos = !(maisVendidos.scrollLeft === divWidth);
      this.arrowLeftMaisVendidos = !(maisVendidos.scrollLeft === 0);
    },

    async bringBanners() {
      await this.apiService
        .post(`marketing/listActive-banners`, this.filter)
        .then((response) => {
          this.banners = response.banners.filter((banner) => !banner.secondary);
          this.bannersSecondary = response.banners.find(
            (banner) => banner.secondary
          );

          if (this.banners.length <= 0) {
            this.noBanners = [
              {
                image:
                  "https://lirp.cdn-website.com/193db0b2/dms3rep/multi/opt/gold-couros-1920w.jpg",
                link: "www.google.com",
              },
              {
                image:
                  "https://lirp.cdn-website.com/193db0b2/dms3rep/multi/opt/3d-opaco-1920w.jpg",
                link: "www.google.com",
              },
            ];
          }

          this.componentKey++;
        });
    },

    onResize() {
      this.isMobile = window.innerWidth < 600;
    },

    async productsByCategory() {
      try {
        this.category = this.categories.find((x) => x.showHomepage);
        if (!this.category) {
          this.category = this.categories[0];
        }
        this.categoryFilter.categoryShowHomepage = this.category.categoryId;
        await this.apiService
          .post(`product/listActiveB2C`, this.categoryFilter)
          .then((response) => {
            this.products = response.products;
          });
      } catch (e) {}
    },

    async topSellingProducts() {
      try {
        await this.apiService
          .post(`product/listActiveB2C`, this.filter, null, false, false)
          .then((response) => {
            this.productsBestSellers = response.products;
          }).catch((error) => {
          });
      } catch (e) {}
    },

    async saveSearch() {
      await this.apiService.post("search", {
        searchedText: this.filter.product,
      });
    },

    async goTo(item) {
      const param = FormatParamsUrl.format(item.productName);
      await this.$router.push({
        path: `/produtos/${param}`,
        params: item,
      });
    },

    async to(path) {
      await this.$router.push({ path: `/${path}`});
    },
  },
};
</script>
