<template>
  <v-main>
    <v-row id="ratings">
      <v-col v-if="reviews && reviews.length > 0">
        <h2>Avaliações</h2>
        <div v-for="(rating, i) in reviews" class="" :key="i">
          <div>
            <strong>{{ rating.user.name }}</strong>
            <div class="d-flex align-center">
              <v-rating
                  v-model="rating.note"
                  background-color="yellow"
                  color="yellow"
                  size="20"
                  readonly
                  half-increments
                  empty-icon="mdi-star-outline"
                  full-icon="mdi-star"
                  half-icon="mdi-star-half-full"
              ></v-rating>
              <strong class="ml-2">{{ parseDate(rating.created_at) }}</strong>
            </div>

            <div>
              <strong>Comentário</strong>
              <p>{{ rating.evaluation ? rating.evaluation : 'Sem comentário.' }}</p>
            </div>
          </div>
        </div>
      </v-col>

      <v-col v-else>
        O produto ainda não foi avaliado.
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import UtilsDate from "../../../Utils/FormatDate";

export default {
  name: "Reviews",
  props: {
    reviews: {type: Array, default: null},
  },
  components: {},
  data: () => ({}),

  async created() {
  },
  computed: {},

  methods: {
    parseDate(date) {
      return UtilsDate.momentDate(date);
    }
  },
};
</script>

<style scoped>

</style>
