<template>
  <v-main
    id="mainCheckout"
    class="mainCheckout"
    style="padding: 15px; text-align: -webkit-center"
  >
    <v-card class="cards ma-0 pa-0 py-9 my-9">
      <v-icon class="py-2" x-large color="normalred"> mdi-alert-circle</v-icon>
      <div class="d-flex justify-center py-2">
        <h2 class="main-title">Pagamento recusado</h2>
      </div>

      <div class="main-description py-3 px-5">
        Seu pagamento foi recusado, ajuste sua compra para alterar a forma de pagamento.
      </div>

      <v-row justify="space-around">
        <v-col class="d-flex justify-center">
          <v-btn class="primary-button" @click="goToRoute('/checkout')"
            >Alterar forma de pagamento</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-main>
</template>

<style scoped>
.main-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 107.9%;
  /* identical to box height, or 35px */

  color: #bc0000;
}
.main-description {
  width: 420px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;

  text-align: center;

  color: #202020;
}
.cards {
  width: fit-content;
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
  border-radius: 10px;
}
@media (max-width: 600px) {
}
</style>

<script>
import ApiService from "../../../services/ApiService";
import Vue from "vue";

export default {
  name: "DeniedPayment",

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  components: {},
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  data: () => ({}),

  computed: {},

  async created() {},

  methods: {
    async goToRoute(route) {
      if (this.$route.path != route) {
        await this.$router.push(route);
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
  },
};
</script>
